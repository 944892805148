import {useContext} from 'react'
import AdminDashBoardCss from './adminDashboard.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import swaLogo from '../../assets/logo.png';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import userContext from '../../Store'

function AdminDash() {
  const {userData, logout} = useContext(userContext);

  return (
    <>
    {/* Header Section */}
   <div className={`${AdminDashBoardCss.HeaderTop}`}>
       <div className={`${AdminDashBoardCss.logoCol}`}>
          <div className={`${AdminDashBoardCss.logoDiv}`}>
             <img className={`${AdminDashBoardCss.logoimgs}`} src={swaLogo}/>
          </div>
       </div>
       <div className={`${AdminDashBoardCss.UserCol}`}>
           <div className={`${AdminDashBoardCss.rightcolm}`}>
             <Dropdown>
              Welcome : {userData.firstName}
               <Dropdown.Toggle className={`${AdminDashBoardCss.candidateButton}`}>
               <AccountCircleIcon className={`${AdminDashBoardCss.user}`}/>
               </Dropdown.Toggle>
               <Dropdown.Menu variant="light">
               <Dropdown.Item href=""><PersonIcon/>My Profile</Dropdown.Item>
               <Dropdown.Item onClick={logout}><ExitToAppTwoToneIcon/>Logout</Dropdown.Item>
               </Dropdown.Menu>
               </Dropdown>
           </div>
       </div>
       <div className={`${AdminDashBoardCss.clear}`}></div>
   </div>

  </>
  );
}

export default AdminDash;
