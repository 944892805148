import AdminDashBoardCss from './adminDashboard.module.css';
import ReorderIcon from '@mui/icons-material/Reorder';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { AiFillDashboard } from "react-icons/ai";
import manageStu from '../../assets/managestu.png';
import Gene from '../../assets/genera.png';
import Accordion from 'react-bootstrap/Accordion'
import report from '../../assets/report.png';
import { useRef } from 'react';
import { Link , useLocation} from 'react-router-dom';
import { useState } from 'react';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';



function AdminLeftMenu({LeftMenuHide, LeftMenuShow}) {
  const pathName = useLocation().pathname;

  const leftMenu = useRef();
  
  const [isShow, setShow]=useState(false);
  // const LeftMenuHide = () => {
  //   leftMenu.current.style = "width:52px";
  // }
  function hideMenu(){
    LeftMenuHide(leftMenu.current);
    setShow(true);
  }

  function showMenu(){
  //   console.log("helo Ajay")
   LeftMenuShow(leftMenu.current)
   setShow(false);
  }
  
  return (
    <div className={`${AdminDashBoardCss.backDiv}`}>
    <div ref={leftMenu} className={`${AdminDashBoardCss.leftNavigation}`}>
     {/*  */}
     <Link  to="/admin">
        <div  className={`${AdminDashBoardCss.leftButton}`}>
        <DashboardSharpIcon className={`${AdminDashBoardCss.dashboardButton}`}/>
        <div className={`${AdminDashBoardCss.leftText}`}>ADMIN DASHBOARD</div>
      </div>
      </Link>
      <Link className={pathName=='/admin/manage-student'?AdminDashBoardCss.ActiveButton:null} to="/admin/manage-student">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={Gene}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Manage Student</div>
        </div>
      </Link>
      <Link className={pathName=='/exam/create'?AdminDashBoardCss.ActiveButton:null} to="/exam/create">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={Gene}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Generate Exam</div>
        </div>
      </Link>
      
      <Link className={pathName==''?AdminDashBoardCss.ActiveButton:null} to="">
        <div className={`${AdminDashBoardCss.leftButton}`}>
          <div className={`${AdminDashBoardCss.iconLeft}`}>
            <img src={Gene}/>
          </div>
          <div className={`${AdminDashBoardCss.leftText}`}>Report</div>
        </div>
      </Link>
       {/*  */}

    </div>
  </div>
  );
}

export default AdminLeftMenu;
