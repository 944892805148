import AdminDashBoardCss from './adminDashboard.module.css';
import AdminLeftMenu from './AdminLeftMenu';
import {Container, Row, Col, Table} from 'react-bootstrap';
import Header from './Header';
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import React, { useEffect, useState, useRef } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskIcon from '@mui/icons-material/AddTask';
import Pagination from "react-bootstrap/Pagination";
function ManageStudent_Admin() {
  const inputBox = useRef(null);
  const table = useRef(null);

  useEffect(()=>{
    getSubjectList();
  },[])


  const[showSubjectList, setSubjectList]= useState([]);

  // search data functions start
  function searchData() {
    let filter, tr, tds, i, txtValue;
    filter = inputBox.current.value.toUpperCase();
    tr = table.current.getElementsByTagName("tr");
    console.log(tr);
    for (i = 1; i < tr.length; i++) {
      tds = tr[i].getElementsByTagName("td");
      let isExist = false;
      for (let td of tds) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          isExist = true;
        }
      }
      if (isExist) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  // search data functions end

  // get subject function start
  function getSubjectList(){
      fetch(apiBaseURL+apiURL.getSubject,{
        method: "GET",
        headers : {'key' : apiKey}
      })
      
      .then((res)=>{
        res.json()
        .then((subjectData)=>{
          if(subjectData.status == "success"){
            setSubjectList(subjectData.data.subjects)
          }else{alert(subjectData.message)}
        })
      })
      .catch((err)=>{
        alert(err);
      })
   }
 
 // alert(options[1].value)


  return (
  <div>
    <div  className={`${AdminDashBoardCss.rightArea}`}>
   {/* header start */}
   <Header/>
   {/* header end */}
  
   {/* Left Menu start */}
   <AdminLeftMenu/>
   {/* Left Menu end */}
   
      <div className={`${AdminDashBoardCss.BkgenerateExam}`}>
        <Container fluid>
          <div className={`${AdminDashBoardCss.selectsubjectBg}`}>
          {/* <div className={`${AdminDashBoardCss.heading}`}>Student List</div> */}
            <Row>
              
              <Col>
                 
                    <select className='form-control'>
                      <option className='d-none'>Select Subject</option>
                      {showSubjectList.map((item)=>{
                        return(
                          <option>{item.subjectName}</option>
                        )
                      })}
                    </select>
              </Col>
              <Col>
                  <div className={`${AdminDashBoardCss.inputFiled}`}>
                      <PersonSearchIcon  className={`${AdminDashBoardCss.searchIcons}`}/>
                      <input type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search"/>
                  </div>
              </Col>
            </Row>

            <Row>
            <div className={`${AdminDashBoardCss.ManageStudent}`}>
               
                  <div className={`${AdminDashBoardCss.tableBgs}`}>
                    <Row>
                      
                      <Col>
                       
                      </Col>
                    </Row>
                    <Row>
                      
                    </Row>
                    <Row>
                      <div className={`${AdminDashBoardCss.searchOption}`}></div>
                      <Table className={`${AdminDashBoardCss.TableManageT}`} striped  bordered  hover responsive ref={table}>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Student Name</th>
                            <th>Father's Name</th>
                            <th>School Code</th>
                            <th>Registration No</th>
                            <th>Email ID</th>
                            <th>Allocate Subjects</th>
                            <th>Active/Inactive</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        
                          
                              <tr>
                                <td>1</td>
                                <td>Subhash</td>
                                <td>...</td>
                                <td>014256</td>
                                <td>000</td>
                                <td>00</td>
                                <td> <button className="btn btn-secondary"> <AddTaskIcon/> Allocate </button> </td>
                                <td>
                                  <label className={`${AdminDashBoardCss.switch}`}>
                                    <input type="checkbox" />
                                    <span className={`${AdminDashBoardCss.slider} ${AdminDashBoardCss.round}`}></span>
                                  </label>
                                </td>
                                <td title="Edit Student">
                                  <EditIcon
                                    className={`${AdminDashBoardCss.editButton}`}
                                  />
                                </td>
                                <td title="Delete Student">
                                  <DeleteForeverIcon
                                    className={`${AdminDashBoardCss.delete}`}
                                  />
                                </td>
                              </tr>

                         
                        </tbody>
                      </Table>

                    </Row>
                    <Row>
                      <Col>
                        <button  className="btn btn-primary">
                          <AddBoxIcon /> ADD STUDENT
                        </button>
                      </Col>
                      <Col>
                        <Pagination className={`${AdminDashBoardCss.PaginationBar}`}>
                          <Pagination.First />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Last />
                        </Pagination>
                      </Col>
                    </Row>
                  </div>
                
              </div>
            </Row>
          </div>
        </Container>
      </div>
   </div>

 </div>
  );
}

export default ManageStudent_Admin;
