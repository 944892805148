import React, { useState, useContext } from "react";
import CodeLoginCss from "./CodeLogin.module.css";
import LoginImg2 from "../../assets/loginImg2.png";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import {useNavigate} from 'react-router-dom';
import {apiBaseURL,apiURL,apiKey} from './../../constant';
import Alert from '@mui/material/Alert';
import userContext from '../../Store';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import { Link } from "react-router-dom";
function LoginPage() {

  
// login code  
    const[accessCode, setaccessCode]=useState("");
    const {login, error, loader} = useContext(userContext);
console.log(loader);

// login code



  return (

   
    <div className={`${CodeLoginCss.container}`}>

      {loader && 

       <div className="loader">
           <div className="box">
               <div className="circle"></div>
           </div>
           <div className="box">
               <div className="circle"></div>
           </div>
       </div>
      
      }


      <div className={`${CodeLoginCss.loginDiv}`}>
        <div className={`${CodeLoginCss.loginImg2}`}>
          <img src={LoginImg2} />
        </div>


        {/* form Section start */}
        <form className={`${CodeLoginCss.loginForm}`} onSubmit={(e)=>{e.preventDefault(); login({'eacode':accessCode})}}>
          <div className={`${CodeLoginCss.loginHead}`}>
            <h1>Login</h1>
            {error.status &&
                <Alert className={`${CodeLoginCss.erroBox}`}  severity="error" id="alertmsg">
                    {error.msg}
                </Alert>
            }
          </div>

          <label className={`${CodeLoginCss.lableHead}`}>
            Enter Your Access Code:
          </label>

          <div className={`${CodeLoginCss.inputRow}`}>
            <div className={`${CodeLoginCss.icon}`}>
              <ClosedCaptionIcon className={`${CodeLoginCss.icons}`} />
            </div>

            <div className={`${CodeLoginCss.inputBox}`}>
              <input
                autoComplete="off"
                required
                name="UserName"
                type="password"
                placeholder="Enter Access Code"
                onChange={(e)=>{setaccessCode(e.target.value)}}
              />
            </div>
          </div>

          <div className={`${CodeLoginCss.buttonRow}`}>
            <button type="submit" className={`${CodeLoginCss.logButton}`}><LockOpenIcon/> Login </button>
            <Link to="/signup">
            <button type="button" className={`${CodeLoginCss.logButton}`}><ManageAccountsSharpIcon/> Register Now </button>
            </Link>
          </div>
        </form>
        {/* form Section start */}


      </div>
      <div className={`${CodeLoginCss.area}`}>
        <ul className={`${CodeLoginCss.circles}`}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}
export default LoginPage;
