export const RouteURL = Object.freeze({
    'studentLoginPage' : "/",
    'loginPage' : "/login",
    'signupPage' : "/signup",
    'masterLoginPage' : "/masterlogin",
    'adminLogin' : "/admin-login",
    'userProfile' : "/student/profile",
    'userProfile' : "student/profile",
    'adminDashboard' : "/admin",
    'ManageStudent_Admin' : "/admin/manage-student",

    'adminGenerateExam' : "/exam/create",
    'dtpDashboardPage' : "/dtp",
    'dtpGeneratePracticeSet' : "/dtp/generate-practice-set",
    'studentDashboard' : "/student",
    'studentWelcomePage' : "/student/welcome",
    'studentQuickNotesPage' : "/student/quickNotes",
    'studentTopicWisePracticePage' : "/student/TopicWisePractice",
    'studentPracticeSetPage' : "/student/practice-sets",
    'studentAttemptScreenPage' : "/student/attempt",
    'studentSelfGenerteAssessmentPage' : "/student/Self-Generated-Assessment",
    'studentReport' : "/student/Report",
    'AddSubjectButton' : "/student/Add-subject",
    'addEditInst' : "/admin/addEditins",
    'addQuestion' : "/admin/manageQuestion",
    'manageStu' : "/admin/manageStu",
    'superAdmin' : "/superAdmin",
    'superAdminReport' : "/superAdmin/Report",

});

// export const apiBaseURL = "http://127.0.0.1:8000/api";
// export const apiBaseURL = "http://192.168.2.234/catExamAPI/api";
export const apiBaseURL = "https://cucet.swaadhyayan.com/connect/api";
export const apiKey = "swacatexam_web";
export const ckFinderURL = "https://cucet.swaadhyayan.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json";
export const wirisMathML = "https://cucet.swaadhyayan.com/wiris/integration/WIRISplugins.js?viewer=image";

export const apiURL = Object.freeze({
    'login' : "/login",
    'signup' : "/signup",
    'getSectionList' : "/getSections",
    'getStateList' : "/getStates",
    'getChapterList' : "/getChapters",
    'loginCheck':'/login-check',
    'getTopics':'/getTopicList',
    'getSubjectList':'/getSubjects',
    'getChaptersList':'/getChapters',
    'getTopics':'/getTopicList',
    'getSubTopics':'/getSubTopicList',
    'updateQuestion':'/questionUpdate',
    'updatePassage':'/passageUpdate',
    'deleteQuestion':'/questionDelete',
    'createQuestion':'/questionCreate',
    'createPassage':'/passageCreate',
    'deletePassage':'/passageDelete',
    'getQuickNotes': '/getQuickNotes',
    'getPassagesList': '/getPassages',
    'searchQuestions':'/searchQuestions',
    'getSchoolList':'/getSchoolList',
    'getUsersList':'/getUsersList',
    'schoolAdminDashboardStats':'/schoolAdminDashboardStats',
    'getQuestionBankTypes':'/getQuestionBankTypes',
    'updateQuestionTypeID':'/updateQuestionTypeID'

});