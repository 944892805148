import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import notes from '../../assets/notes.webp';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Row, Col } from 'react-bootstrap';
import {apiBaseURL,apiURL,apiKey} from './../../constant';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';



function StudentDash() {
  useEffect(()=>{
    getTopicList();
    getSubjectList();
    getSection()

  },[])

  const[showTopicList, setTopicList]=useState([]);
  const[showSubjectList, setSubjectList]=useState([]);
  const[showChapter, setChapterList]=useState([]);
  const[showSection, setSection]=useState([]);
  const[showSubTopic, setSubTopic]=useState([]);


  const[shoQuickData, setQuickData]=useState(false);
  

  // radio button
  const[sltSectionA, setSlSectionA]=useState(true);
  const[sltSectionB, setSlSectionB]=useState(true);

  const [checkBtn, setCheckedBtn] = useState("1");
  // radio button

  // Section state
  const[showSectionA, setSectionA] =useState(true);
  const[showSectionB, setSectionB] =useState(false);

  // Section state
  const[showErrPopup, setErrPopup] =useState({status:false, msg:null});



  
function checkBtnData(id){
  setCheckedBtn(id)
  if(id==1){
    setSectionA(true); 
    setSectionB(false)
    
  } else {
    setSectionB(true); 
    setSectionA(false)

  }
}

  // get topic list function
    function getTopicList(){
      fetch(apiBaseURL+apiURL.getTopics,
        {
          method: 'GET',
          headers:{ 'Key' : apiKey}
        })
        .then((res)=>{
          res.json()
          .then((data)=>{
            if(data.status=="success"){
              setTopicList(data.data)
            }else{setErrPopup({status:true, msg:data.message})}
          })
        })
        .catch((err)=>{
          console.log(err)
        })
    }
  // get topic list function

  // get subTopic List function
   function getSubToics(questionCatID){
     fetch(apiBaseURL+apiURL.getSubTopics,{
       method: 'POST',
       headers: {'Key' : apiKey},
       body: JSON.stringify({"topicID": questionCatID})

     })
     .then((res)=>{
       res.json()
       .then((data)=>{
         if(data.status=="success"){
          setSubTopic(data.data.subTopics)
         }else{setErrPopup({status:true, msg:data.message})}
       })
     })
     .catch((err)=>{
       alert(err);
     })
   }
  // get subTopic List function


  // get subject list function
    function getSubjectList(){
      fetch(apiBaseURL+apiURL.getSubject,{
        method: 'GET',
        headers:{'Key' : apiKey}
      })
      .then((res)=>{
        res.json()
        .then((data)=>{
          if(data.status=="success"){
            setSubjectList(data.data.subjects)
          }else{setErrPopup({status:true, msg:data.message})}
        })
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  // get subject list function

  // get chapters list  function
    function getChapters(subjectID){
      fetch(apiBaseURL+apiURL.getChaptersList,{
        method: 'POST',
        headers: {'Key' : apiKey},
        body: JSON.stringify({"classID":12, "subjectID": subjectID})
      })
      .then((res)=>{
        res.json()
        .then((data)=>{
          if(data.status=="success"){
            setChapterList(data.data.chapters)
          }else{setErrPopup({status:true, msg:data.message})}
        })
        
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  // get chapters list  function

  // get section function
    function getSection(){
        fetch(apiBaseURL+apiURL.getSectionList,{
          method: 'GET',
          headers: {'Key' : apiKey}
        })
        .then((res)=>{
          res.json()
          .then((data)=>{
            if(data.status=="success"){
            setSection(data.data.sections)
            }else{alert(data.message)}
          })
        })
        .catch((err)=>{
          alert(err);
        })
    }
  // get section function





  
  return (

   <div className={`${StuDashBoardCss.Backbody}`}>
      <Header/>
      <LeftMenu/>
      <div className={`${StuDashBoardCss.backdiv}`}>
        <div className={`${StuDashBoardCss.RowCOlm}`}>
             <div className={`${StuDashBoardCss.headingPractice}`}>Topic/Chapter Wise Practice</div>
        </div>
        
      {sltSectionA  && 
        <>
        {showSection.map((item)=>{
          return(
            <FormControlLabel checked={checkBtn==item.sectionID} name='section' value="sction" control={<Radio />} label={item.sectionName} onChange={()=>{checkBtnData(item.sectionID)}}/>
          )
        })}
         {/* <FormControlLabel onClick={()=>{setSectionA(true); setSectionB(false);}} checked={checkBtn=="sectionA"} name='section' value="sction" control={<Radio />} label="Section - A" onChange={()=>{setCheckedBtn("sectionA")}}/> */}
         {/* <FormControlLabel onClick={()=>{setSectionB(true); setSectionA(false);}}  checked={checkBtn=="sectionB"} value="sction" control={<Radio />} label="Section - B"  onChange={()=>{setCheckedBtn("sectionB")}}/> */}
        </>
      }
        <hr/>
        <Container fluid>

       {/* section A start*/}
        {showSectionA &&
          <form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
          <Row className='justify-content-start'>
           <Col className='col-md-6 col-12 col-sm-12'>
             <label>Topic</label>
              <select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>getSubToics(event.target.value)}>
               <option className={`d-none`}>Select Topic</option>
                {showTopicList.map((item)=>{
                  return(
                    <option value={item.questionCatID}>{item.questionCatDesc}</option>
                  )
                })
                }
              </select>
           </Col>

           <Col className='col-md-6 col-12 col-sm-12'>
          <label>Sub Topic</label>
          <select required className={`${StuDashBoardCss.selectSubject}`}>
               <option className={`d-none`}>Select</option>
                {showSubTopic.map((item)=>{
                  return(
                    <option>{item.subTopicDesc}</option>
                  )
                })}
              </select>
          </Col>
                  
           {/* <Col className='col-md-2 col-sm-12 col-12'>
             <button  className={`${StuDashBoardCss.buttonNext}`} type='button'>Next</button>
           </Col> */}
           
          </Row>
          </form>
           }
           {/* section A end*/}

        {/* section B start*/}
        {showSectionB &&
          <form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
          <Row className='justify-content-start'>
          <Col className='col-md-6 col-12 col-sm-12'>
             <label>Subject</label>
              <select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>getChapters(event.target.value)}>
               <option className={`d-none`}>Select Subject</option>
                {showSubjectList.map((item)=>{
                  return(
                    <option value={item.subjectID}>{item.subjectName}</option>
                  )
                })}
              </select>
           </Col>
           <Col className='col-md-6 col-12 col-sm-12'>
             <label>Chapter</label>
              <select required className={`${StuDashBoardCss.selectSubject}`}>
                <option className={`d-none`}>Select Chapter</option>
                {showChapter.map((item)=>{
                  return(
                    <option>{item.chapterName}</option>
                  )
                })}
              </select>
           </Col>
{/*          
           <Col className='col-md-2 col-sm-12 col-12'>
             <button className={`${StuDashBoardCss.buttonNext}`} type='button'>Next</button>
           </Col> */}
           
          </Row>
          </form>
           }
           {/* section B end*/}




          {/* Quick Notes */}

            {shoQuickData &&
            <img className={`${StuDashBoardCss.stuNotes}`} src={notes}/>
            }
           
          {/* Quick Notes */}

          

        </Container>
      </div>
       {/* err popup */}
    {showErrPopup.status && 
    <div className={`${StuDashBoardCss.popupBack}`}>
    <div className={`${StuDashBoardCss.errBox}`}>
         <CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
         <Alert severity="error">
         {showErrPopup.msg}
         </Alert>
          
     </div>       
    </div>
    }
    {/* err popup */}
    </div>
  
  );
}

export default StudentDash;
