import React, { useEffect, useState } from 'react'
import {apiBaseURL,apiURL,apiKey,RouteURL} from './constant';
import {useNavigate, useLocation} from 'react-router-dom';
const userContext = React.createContext();
export function UserProvider({children}) {
    const [userData, setUserData] = useState({});
    const [error, setError] = useState({status:false, msg:'', fun:null});
    const [loader, setLoader] = useState(false);
    let navigate = useNavigate();
    let location = useLocation();
  
   
    useEffect(()=>{
        // console.log(location);
        if(location.pathname!==RouteURL.loginPage && location.pathname!==RouteURL.signupPage  && location.pathname!==RouteURL.masterLoginPage){            
            checkLogin();
        } 
    }, [])
    async function login(data){
        setError({status:false, msg:'', fun:null});
        setLoader(true);

        try{
            let result= await fetch(`${apiBaseURL}${apiURL.login}`,{
            method: 'POST',
            headers: {
                "Contant-Type": "applaction/json",
                    "Accept":"applaction/json",
                    'key':apiKey
                    },
            body:JSON.stringify(data)
            });

            result = await result.json();
            setLoader(false);
            // console.log(result);
            if(result.status==='success'){
                // const {firstName, middleName, lastName, userName, userTypeID} = {...result.data.userDetail}
                // setUserData({
                //     name:`${firstName} ${middleName} ${lastName}`,
                //     userTypeId:userTypeID,
                //     token:result.data.loginToken
                // });
                setUserData(result.data.userDetail);
                localStorage.setItem('token', result.data.loginToken);

                if( result.data.userTypeID===1 ){
                    navigate(RouteURL.superAdmin);

                }else if( result.data.userTypeID===2 ){
                    navigate(RouteURL.adminDashboard);

                }else if(result.data.userTypeID===2){
                    navigate(RouteURL.studentWelcomePage);
                    
                }else if(result.data.userTypeID===4){
                    navigate(RouteURL.dtpDashboardPage);
                    
                }else{
                    navigate(RouteURL.studentWelcomePage);
                }
            }else{
                setError((prev)=>{
                    return {...prev, status:true, msg:result.message}
                });
            }
        } catch(err){
            console.log(err);
            setLoader(false);
            setError((prev)=>{
                return {...prev, status:true, msg:"Oops.. Please check your internet connection"}
            });
        }
      
    }

    function logout(){
        setError({status:false, msg:'', fun:null});
        setLoader(false);
        setUserData({});
        localStorage.removeItem('token');
        navigate(RouteURL.studentLoginPage);
    }   

    async function checkLogin(){
        if(userData.userID===undefined){
            let token = localStorage.getItem('token');
          
            if(token==null){
                logout();
            } else {
                setLoader(true)
                try{
                    let result= await fetch(`${apiBaseURL}${apiURL.loginCheck}`,{
                    method: 'POST',
                    headers: {
                        "Contant-Type": "applaction/json",
                            "Accept":"applaction/json",
                            'key':apiKey
                            },
                    body:JSON.stringify({loginToken:token})
                    });
                    result = await result.json();
                    setLoader(false)
                    if(result.status==='success'){
                        setUserData(result.data.userDetail);
                        localStorage.setItem('token', result.data.loginToken);
                        // console.log("skldjfklsdj fksdklfjskldjf klsdjfkl")
                    } else {
                        logout();
                    }
                } catch(err){
                    console.log(err);
                }
            }
        }
        // setLoader(true)
        // try{
        //     let result= await fetch(`${apiBaseURL}${apiURL.login}`,{
        //     method: 'POST',
        //     headers: {
        //         "Contant-Type": "applaction/json",
        //             "Accept":"applaction/json",
        //             'key':apiKey
        //             },
        //     body:JSON.stringify(data)
        //     });
        //     result = await result.json();
        //     setLoader(false);


           
        // } catch(err){
        //     console.log(err);
        //     setLoader(false);
        //     setError((prev)=>{
        //         return {...prev, status:true, msg:"Some Error Occured!"}
        //     });
        // }

        
    }


    return(
        <userContext.Provider value={{
            userData:userData,
            login:login,
            logout:logout,
            checkLogin:checkLogin,
            error:error,
            loader:loader
        }}>
            {children}
        </userContext.Provider>

    )




}


export default userContext;






