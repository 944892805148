import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import notes from '../../assets/notes.webp';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Row, Col } from 'react-bootstrap';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';

import { useState } from 'react';
import {apiBaseURL,apiURL,apiKey} from './../../constant';

function StudentDash() {
  const[shoQuickData, setQuickData]=useState(false);
  

  // radio button
  const[sltSectionA, setSlSectionA]=useState(true);
  const[sltSectionB, setSlSectionB]=useState(true);

  const [checkBtn, setCheckedBtn] = useState("1");
  // radio button

  // Section state
  const[showSectionA, setSectionA] =useState(true);
  const[subjectBData, setSubjectBData] =useState({
    status:false,
    subjectId:'',
    chapterId:''
  });
  // Section state
 
  const[showErrPopup, setErrPopup] =useState({status:false, msg:null});


  const[showTopicList, setTopicList] = useState([]);
  const[showSubjectList, setSubjectList] = useState([]);
  const[showChapterList, setChapterList] = useState([]);
  const[showSection, setSection]=useState([]);

  const[showPDFTopic, setPDFTopc]=useState([]);
  const[showPDFOonClickTopic, setPDFonClickTopic]=useState({status:false, pdf:null});

  const[showPDFChapters, setPDFChapters]=useState([]);
  const[showPDFChaptersOnclick, setPDFChaptersOnclick]=useState({status:false, pdf:null});


function checkBtnData(id){
  setCheckedBtn(id)
  if(id===1){
    setSectionA(true); 
    setSubjectBData(false)
    setPDFTopc([])
    setPDFonClickTopic([])
    setPDFChapters([])
    setPDFChaptersOnclick([])
  } else {
    setSubjectBData((prev)=>{
      return {...prev, status:true}
    });
    setSectionA(false);
    setPDFTopc([])
    setPDFonClickTopic([])
    setPDFChapters([])
    setPDFChaptersOnclick([])



  }
}

  useEffect(()=>{
    getTopicList();
    getSubjectList();
    getSection();
  },[])

// get topic list function
  function getTopicList(){
   fetch(apiBaseURL+apiURL.getTopics,
     {
        method:'GET',
        headers:{ 'key': apiKey }
     }
    )
    .then((res)=>{
        res.json()
        .then((data)=>{
            if(data.status==="success"){
                setTopicList(data.data)
            } else{setErrPopup({status:true, msg:data.message})}
        })
    })
    .catch((err)=>{
        alert(err);
    })
}
// get topic list function


// get subject function
 function getSubjectList(){
    fetch(apiBaseURL+apiURL.getSubject,{
        method: 'GET',
        headers: {'key' : apiKey}
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status==="success"){
          setSubjectList(data.data.subjects)
        } else{
          setErrPopup({status:true, msg:data.message})
         
        }
      })
    })
    .catch((err)=>{
      alert(err);
    })
 }
// get subject function

// get chapters functions
function getChapters(subjectId){

  setSubjectBData((prev)=>{
    return {...prev, subjectId:subjectId}
  });
   fetch(apiBaseURL+apiURL.getChaptersList,{
     method: 'POST',
     headers: {'key' : apiKey},
     body: JSON.stringify({"classID":12, "subjectID":subjectId})
   })
   .then((res)=>{
     res.json()
     .then((data)=>{
       if(data.status==="success"){
        setChapterList(data.data.chapters);
       }else{
        setErrPopup({status:true, msg:data.message})
        }
     })
   })
   .catch((err)=>{
     alert(err);
   })
}


// get chapters functions

// sections function
 function getSection(){
    fetch(apiBaseURL+apiURL.getSectionList,{
      method: 'GET',
      headers: {'Key' : apiKey}
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status==="success"){
          setSection(data.data.sections)
        }else{setErrPopup({status:true, msg:data.message})}
      })
    })
    .catch((err)=>{
      alert(err);
    })
 }
 
// sections function


// get PDF in Topics function start
function showPdfDataTopic(value){
  let data = {
    "classID" : 12,
    "sectionID" : 1,
    "topicID": value
  }
  fetch(apiBaseURL+apiURL.getQuickNotes,{
    method: 'POST',
    headers: {'Key' : apiKey},
    body:JSON.stringify(data)
  })
  .then((res)=>{
    res.json()
    .then((data)=>{
      if(data.status==="success"){
        let pdfName= data.data.quickNotes.pdfName.map((item)=>{
          return data.data.quickNotes.pdfPath+'/'+item
        });
        setPDFTopc(pdfName);
        setPDFonClickTopic({status:false, pdf:null});
      }else{setErrPopup({status:true, msg:data.message})}
    })
  })
  .catch((err)=>{
    alert(err);
  })
}
// get PDF in Topics function end

// get PDF function in Chapters  start
 function showPdfDataChapters(value){
  //  alert('');
  setSubjectBData((prev)=>{
    return {...prev, chapterId:value}
  });
   let data = {
    "classID" : 12,
    "sectionID" : 2,
    "chapterID" :value,
    "subjectID": subjectBData.subjectId  
   }
   fetch(apiBaseURL+apiURL.getQuickNotes,{
     method: 'POST',
     headers: {'Key' : apiKey},
     body:JSON.stringify(data)
   })
   .then((res)=>{
     res.json()
     .then((data)=>{
       if(data.status==="success"){
         let pdfName= data.data.quickNotes.pdfName.map((item)=>{
           return data.data.quickNotes.pdfPath+'/'+item
         });
         setPDFChapters(pdfName);
         setPDFChaptersOnclick({status:false, pdf:null})
       }else{setErrPopup({status:true, msg:data.message})}
     })
   })
   .catch((data)=>{
     alert(data.message)
   });
 }
// get PDF function in Chapters  end




  return (

   <div className={`${StuDashBoardCss.Backbody}`}>
      <Header/>
      <LeftMenu/>
     
      <div className={`${StuDashBoardCss.backdiv}`}>
        <div className={`${StuDashBoardCss.RowCOlm}`}>
             <div className={`${StuDashBoardCss.headingPractice}`}>Quick Notes</div>
        </div>
        
      {sltSectionA  && 
        <>
        {showSection.map((item)=>{
          return(
            <FormControlLabel checked={checkBtn===item.sectionID} name='section' value="sction" control={<Radio />} label={item.sectionName} onChange={()=>{checkBtnData(item.sectionID)}}/>
          )
        })}
        </>
      }
        <hr/>
        <Container fluid>

       {/* section A start*/}
        {showSectionA &&
          <form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
          <Row className='justify-content-start'>
           <Col className='col'>
             <label>Topic</label>
              <select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{showPdfDataTopic(event.target.value)}}>
               <option className={`d-none`}>Select Topic</option>
               
               {showTopicList.map((item)=>{
                 return(
                  <option value={item.questionCatID}>{item.questionCatDesc}</option> 
                  )
                   
               })}
              </select>
           </Col>
          </Row>
          </form>
           }
        {/* section A end*/}

        {/* section B start*/}
        {subjectBData.status &&
          <form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
          <Row className='justify-content-start'>
           <Col className='col-md-6 col-12 col-sm-12'>
             <label>Subject</label>
              <select required className={`${StuDashBoardCss.selectSubject}`} value={subjectBData.subjectId} onChange={(event)=>{getChapters(event.target.value)}}>
               <option className={`d-none`}>Select Subject</option>
                {showSubjectList.map((item)=>{
                  return(
                    <option value={item.subjectID}>{item.subjectName}</option>
                  )
                })}
              </select>
           </Col>
           <Col className='col-md-6 col-12 col-sm-12'>
             <label>Chapter </label>
              <select required className={`${StuDashBoardCss.selectSubject}`} value={subjectBData.chapterId}  onChange={(event)=>{showPdfDataChapters(event.target.value)}}>
                <option className={`d-none`} >Select Chapters</option>
                {showChapterList.map((item)=>{
                  return(
                    <option value={item.chapterID}>{item.chapterName}</option>
                  )
                })}
              </select>
           </Col>
         

           
          </Row>
          </form>
           }
           {/* section B end*/}

        </Container>


      {/* Show Quick Notes Topic_Wise start */}
      {showPDFTopic.map((item, index)=>{
        return(
         <>
            <button className='btn btn-primary m-3' onClick={(()=>{setPDFonClickTopic({status:true, pdf:item})})}>
              PDF - {index+1}
             </button>
          </>
        )
      })
      }
      {showPDFOonClickTopic.status &&
      <>
        <div className={`${StuDashBoardCss.pdfShowFrame}`}><iframe src={showPDFOonClickTopic.pdf}/></div>
      </>
      }
      {/*Show Quick Notes Topic_Wise end */}

      {/* Show Quick Notes Subject/Chapter start */}
      {showPDFChapters.map((item,index)=>{
        return(
          <>
          <button className='btn btn-primary m-3' onClick={(()=>{setPDFChaptersOnclick({status:true, pdf:item})})}>
            PDF - {index+1}</button>
          </>
        )
      })
      }
      {showPDFChaptersOnclick.status &&
      <>
       <div className={`${StuDashBoardCss.pdfShowFrame}`}><iframe src={showPDFChaptersOnclick.pdf}/></div>
      </>
      }
      
      {/* Show Quick Notes Subject/Chapter end */}




      </div>
      
    {/* err popup */}
    {showErrPopup.status && 
    <div className={`${StuDashBoardCss.popupBack}`}>
    <div className={`${StuDashBoardCss.errBox}`}>
         <CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
         <Alert severity="error">
         {showErrPopup.msg}
         </Alert>
          
     </div>       
    </div>
    }
    {/* err popup */}
    
    
    
    </div>

   

  
  );
}

export default StudentDash;
