import React, {useState,useEffect} from "react";


const GenerateFinalExam = () => {

    return(
        <>
            <h1>Generate : Final Exam</h1>
        </>
    );

}
export default GenerateFinalExam;