import React, { useReducer, useState, useEffect} from "react";
import SuperAdminCss from "./SuperAdmin.module.css";
import Header from "./Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import selfAssessment from "../../assets/self_asmt.png";
import FinalExam from "../../assets/final_exam.png";
import PracticeSet from "../../assets/practice_set.png";
import Table from "react-bootstrap/Table";
import PreviewIcon from "@mui/icons-material/Preview";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DangerousIcon from '@mui/icons-material/Dangerous';
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import Loader from "../../Loader";


 const reducer = (state, action)=>{
     switch (action.type) {
    // Practice Sets case start
      case "practiceSets":
        return {
          showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
      case "section_a_ass_list":
        return{
          showSection_AList: (state.showSection_AList = true),
          showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
      case "section_b_ass_list":
        return{
            showSection_BList: (state.showSection_BList = true),
            showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
      case "PsetSection_a_viewdetales":
        return{
          PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = true),
          showSection_AList: (state.showSection_AList = true),
          showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
      case "hideViewDe_sec_a_pop":
        return{
          PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = false),
          showSection_AList: (state.showSection_AList = true),
          showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
      case "review_answers_sec_a":
        return{
          showReview_ans_sec_a: (state.showReview_ans_sec_a = true),
          PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = true),
          showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
      case "hideReviewAns_sec_a":
        return{
          showReview_ans_sec_a: (state.showReview_ans_sec_a = false),
          PracticeSet_section_a_viewDetailes: (state.PracticeSet_section_a_viewDetailes = true),
          showPracticesSets_Section: (state.showPracticesSets_Section = true)
        };
    // Practice Sets case end
    }
   }




function SuperAdminReport() {

  useEffect(()=>{
    getSchool();
   },[])
   
  const [showSchoolDetailes, setSchoolDetailes] = useState ([]);
  const [showStudentDetailes, setStudentDetailes] = useState ([]);
  const [showLoader, setLoader]= useState(false);

    //  get School fuction start
    function getSchool(){
      const xPostData = {};
      fetch(apiBaseURL+apiURL.getSchoolList,{
        method: "POST",
        headers: {Key : apiKey},
        body:JSON.stringify(xPostData)
      })
      .then((res)=>{
        res.json()
        .then((schoolData)=>{
          if(schoolData.status == "success"){
            setSchoolDetailes(schoolData.data.schools)
          }else{alert(schoolData.message);}
        })
      })
      .catch((err)=>{
        alert(err);
      })
    }
    //  get School fuction end

    // get Student detailes function start
    function getStudent(schoolCode){
      setLoader(true)
      const xPostData = {
        "activeOnly":true,
        "orderBy":"loginID",
        "schoolCode":schoolCode
      };
      fetch(apiBaseURL+apiURL.getUsersList,{
        method: "POST",
        headers : {Key : apiKey},
        body:JSON.stringify(xPostData)
      })
      .then((res)=>{
        res.json()
        .then((studentData)=>{
          // console.log(studentData)
          if(studentData.status == "success"){
            setStudentDetailes(studentData.data.users)
            setLoader(false)
          }else{alert(studentData.message);}
        })
      })
      .catch((err)=>{
        alert(err);
      })
    }
    // get Student detailes function end


  const [state, dispatch] = useReducer(reducer, {showPracticesSets_Section: false});
  return (
    <div className={`${SuperAdminCss.backDiv}`}>
      {/* Header Section */}
      <div className={`${SuperAdminCss.rightArea}`}>
        {/* Header */}
        <Header />
        {/* Header */}
        <div className={`${SuperAdminCss.buttonBgs}`}>
          <Container fluid>
              <Row>
                  <Col>
                      <div className={`${SuperAdminCss.Heading}`}>Report</div>
                  </Col>
                  <Col>
                      <div className={`${SuperAdminCss.backTohome}`}>
                          <Link to="/superAdmin"> <button className="btn btn-secondary"><HomeIcon/> DASH BOARD </button> </Link>
                      </div>
                  </Col>
              </Row>
              <hr/>
              <div className={`${SuperAdminCss.backSelect}`}>
                <Row>
                    <Col>
                        <label>School Name</label>
                        <select className="form-control" onChange={(event)=>{getStudent(event.target.value)}}> 
                            <option className="d-none"> Select </option>
                             {showSchoolDetailes.map((item)=>{
                               return(
                                 <option value={item.schoolCode}>{item.schoolName}</option>
                               )
                             })}
                        </select>
                    </Col>
                    <Col>
                        <label>Student Name</label>
                        <select className="form-control">
                            <option className="d-none"> Select </option>
                            {showStudentDetailes.map((item)=>{
                              return(
                                <option value={item.userDetail.userID}>{item.userDetail.firstName+" "+item.userDetail.lastName}</option>
                              )
                            })}
                        </select>
                    </Col>
                </Row>
              </div>

            <Row>
                <Col className="col-md-4 col-sm-12 col-12">
                  <div onClick={(()=>{dispatch({type: "practiceSets"})})} className={`${SuperAdminCss.learnBtn}`}>
                    <div className={`${SuperAdminCss.logoCircle}`}>
                      <img src={PracticeSet} />
                    </div>
                    <div className={`${SuperAdminCss.headingDiv}`} style={{background:state.showPracticesSets_Section&& "#9966FF"}}>
                      PRACTICE SETS
                    </div>
                  </div>
                </Col>

                <Col className="col-md-4 col-sm-12 col-12">
                  <div className={`${SuperAdminCss.learnBtn}`}>
                    <div className={`${SuperAdminCss.logoCircle}`}>
                      <img src={selfAssessment} />
                    </div>
                    <div className={`${SuperAdminCss.headingDiv}`}>
                      SELF GENERATED ASSESSMENT
                    </div>
                  </div>
                </Col>

                

                <Col className="col-md-4 col-sm-12 col-12">
                  <div className={`${SuperAdminCss.learnBtn}`}>
                    <div className={`${SuperAdminCss.logoCircle}`}>
                      <img src={FinalExam} />
                    </div>
                    <div className={`${SuperAdminCss.headingDiv}`}>
                      FINAL EXAM
                    </div>
                  </div>
                </Col>
          </Row>


            {/* Practice sets Reports code start */}
              {state.showPracticesSets_Section &&
                <div className={`${SuperAdminCss.backSection}`}>
                    <div style={{background:state.showSection_AList && "#bdbdbd"}} className={`${SuperAdminCss.sectionbox}`} onClick={(()=>{dispatch({type: "section_a_ass_list"})})}> Section - A </div>
                    <div style={{background:state.showSection_BList && "#bdbdbd"}} className={`${SuperAdminCss.sectionbox}`} onClick={(()=>{dispatch({type: "section_b_ass_list"})})}> Section - B </div>
                </div>
              }

              {/* Report Section - A Start */}
              {state.showSection_AList &&
              <div className={`${SuperAdminCss.AssessmentList}`}>
              <Breadcrumb>
                  <Breadcrumb.Item href="#"> PRACTICE SETS</Breadcrumb.Item>
                  <Breadcrumb.Item active>SECTION - A</Breadcrumb.Item>
              </Breadcrumb>

              <Table striped bordered hover responsive>
                <thead>
                  <tr className={`${SuperAdminCss.headerTr}`}>
                    <th>S No.</th>
                    <th>Assessment Name</th>
                    <th>Marks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Mathematics - self-1</td>
                    <td>80/100</td>
                    <td>
                      <span className={`${SuperAdminCss.Attempted}`}>
                        <AssignmentTurnedInIcon className={`${SuperAdminCss.AttemptedIcon}`}/> Attempted
                      </span>
                    </td>
                    <td>
                      <div onClick={(()=>{dispatch({type: "PsetSection_a_viewdetales"})})} className={`${SuperAdminCss.viewDetailesButton}`}>
                        <PreviewIcon style={{ color: "#999" }} /> View Detailes
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
              }

              {/* View Detailes Popup start */}
              {state.PracticeSet_section_a_viewDetailes &&
              <div className={`${SuperAdminCss.viewDetailesPop}`}>
              <div className={`${SuperAdminCss.innerdivReport}`}>
                <div className={SuperAdminCss.headerTp}>
                  <Row>
                    <Col>
                      <div className={SuperAdminCss.headingCol}>
                        View Detailes
                      </div>
                    </Col>
                    <Col>
                      <CancelIcon onClick={(()=>{dispatch({type: "hideViewDe_sec_a_pop"})})} className={`${SuperAdminCss.closeViewDetailes}`}/>
                    </Col>
                  </Row>
                 
                  
                </div>

                <div className={`${SuperAdminCss.tableBackDeta}`}>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr className={`${SuperAdminCss.headerTr}`}>
                        <th>Q. No.</th>
                       
                        <th>Answer</th>
                        <th>Your Answer</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <CheckBoxIcon  className={`${SuperAdminCss.checkMark}`} />
                          </span>
                        </td>
                        <td>
                          <div onClick={(()=>{dispatch({type: "review_answers_sec_a"})})} className={`${SuperAdminCss.viewDetailesButton}`}>
                            <PreviewIcon style={{ color: "#999" }} /> Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>A</td>
                        <td>C</td>
                        <td>
                          <span>
                            <DangerousIcon className={`${SuperAdminCss.wrongSign}`}/>
                          </span>
                        </td>
                        <td>
                          <div className={`${SuperAdminCss.viewDetailesButton}`}>
                            <PreviewIcon style={{ color: "#999" }} /> Review Answer
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              </div>
              }
              {/* View Detailes Popup end */}

              {/* Review Answer popup start */}
              {state.showReview_ans_sec_a &&
               <div className={`${SuperAdminCss.ReviewAnswer_section}`}>
                <div className={`${SuperAdminCss.innerdivReport}`}> 
                <Row>
                    <Col>
                      <div className={SuperAdminCss.headingCol}>
                        Review Answer
                      </div>
                    </Col>
                    <Col>
                      <CancelIcon onClick={(()=>{dispatch({type: "hideReviewAns_sec_a"})})} className={`${SuperAdminCss.closeViewDetailes}`}/>
                    </Col>
                  </Row>
                <div className={`${SuperAdminCss.rowGrid}`}>
                    <div className={`${SuperAdminCss.colFixed}`}>
                      <div className={`${SuperAdminCss.QuestionNo}`}>Question - 1 </div>
                    </div>
                  <div className={`${SuperAdminCss.questionDiv}`}>
                      <b>
                      In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:
                      </b>
                   <div className={`${SuperAdminCss.SpaceVar}`}></div>
                    <div>Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.</div>
                        <div>Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube.</div>
                        <div className={`${SuperAdminCss.SpaceVar}`}></div>
                        <div className={`${SuperAdminCss.SpaceVar}`}></div>
                     <Row>
                          <Col className='col-md-6 col-sm-12 col-12'>
                                <label>
                                  <div className={`${SuperAdminCss.optionsRow}`}>
                                    <div className={`${SuperAdminCss.divOne}`}>
                                      <input type="radio" name="options" /> A.
                                    </div>
                                    <div>
                                    Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration
                                    </div>
                                  </div> 
                                </label>
                          </Col>
                          <Col className='col-md-6 col-sm-12 col-12'>
                              <label>
                                <div className={`${SuperAdminCss.optionsRow}`}>
                                  <div className={`${SuperAdminCss.divOne}`}>
                                    <input type="radio" name="options" /> B.
                                  </div>
                                  <div>
                                  The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion
                                  </div>
                                </div> 
                              </label>
                          </Col>
                          <Col className='col-md-6 col-sm-12 col-12'>
                                <label>
                                  <div className={`${SuperAdminCss.optionsRow}`}>
                                    <div className={`${SuperAdminCss.divOne}`}>
                                      <input type="radio" name="options" /> C.
                                    </div>
                                    <div>
                                    Assertion is true but the Reason is false
                                    </div>
                                  </div> 
                                </label>
                          </Col> 
                          <Col className='col-md-6 col-sm-12 col-12'>
                                <label>
                                  <div className={`${SuperAdminCss.optionsRow}`}>
                                    <div className={`${SuperAdminCss.divOne}`}>
                                      <input type="radio" name="options" /> D.
                                    </div>
                                    <div>
                                    The statement of the Assertion is false but the Reason is true.
                                    </div>
                                  </div> 
                                </label>
                          </Col>
                    </Row>
                </div>
                </div>
              <hr/>
                <div className={`${SuperAdminCss.explanationBox}`}>
                              <div className={`${SuperAdminCss.headingExpl}`}>Explanation</div>
                              <p className={`${SuperAdminCss.expContent}`}>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                </div>
              </div>
              </div>
              }
              {/* Review Answer popup start */}



              {/* Report Section - A Start */}

            {/* Report Section - B Start */}
            {state.showSection_BList &&
              <div className={`${SuperAdminCss.AssessmentList}`}>
              <Breadcrumb>
                  <Breadcrumb.Item href="#"> PRACTICE SETS</Breadcrumb.Item>
                  <Breadcrumb.Item active>SECTION - B</Breadcrumb.Item>
              </Breadcrumb>

              <Table striped bordered hover responsive>
                <thead>
                  <tr className={`${SuperAdminCss.headerTr}`}>
                    <th>S No.</th>
                    <th>Assessment Name</th>
                    <th>Marks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Mathematics - self-1</td>
                    <td>80/100</td>
                    <td>
                      <span className={`${SuperAdminCss.Attempted}`}>
                        <AssignmentTurnedInIcon className={`${SuperAdminCss.AttemptedIcon}`}/> Attempted
                      </span>
                    </td>
                    <td>
                      <div className={`${SuperAdminCss.viewDetailesButton}`}>
                        <PreviewIcon style={{ color: "#999" }} /> View Detailes
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
              }
              {/* Report Section - B Start */}


            {/* Practice sets Reports code end */}

          </Container>                       
        </div>
              {showLoader &&
              <Loader/>
              }
      </div>
      {/* Right Area */}
    </div>
  );
}

export default SuperAdminReport;
