import React, { useReducer, useEffect, useState, useRef } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { apiBaseURL, apiURL, apiKey } from "./../../constant";
import SuperAdminCss from "./SuperAdmin.module.css";
import schoolReister from "./../../assets/schoolRegi.png";
import ManageStudent from "./../../assets/managestu.png";
import SubjectAllocate from "./../../assets/allocate_subject.png";
import Report from "./../../assets/report.png";
import Loader from "../../Loader";
import { Col, Container, Row, Table } from "react-bootstrap";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskIcon from '@mui/icons-material/AddTask';
import Header from "./Header";
import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
const reducer = (state, action) => {
  switch (action.type) {

    // Manage School case start 
    case "showSchoolList":
      return {
         showSchoolForm: (state.showSchoolForm = true)};
    case "SchoolRegisterPopup":
      return {
          ShowSchoolRegisterForm: (state.ShowSchoolRegisterForm = true),
          showSchoolForm: (state.showSchoolForm = true),
        };
    case "subjectListPopup":
       return {
          showsubjectListPopup: (state.subjectListPopup = true),
          ShowSchoolRegisterForm: (state.ShowSchoolRegisterForm = true),
          showSchoolForm: (state.showSchoolForm = true)
     };
     case "closeSchoolRegiform":
      return {
          showSchoolForm: (state.showSchoolForm = false),
          showSchoolForm: (state.showSchoolForm = true),
      };  
     case "closeSubjectPop":
      return {
          showSchoolForm: (state.showSchoolForm = true),
          ShowSchoolRegisterForm: (state.ShowSchoolRegisterForm = true)
      };
      case "closePopupSchoolList":
        return { showSchoolForm: (state.showSchoolForm = false),
     };
    // Manage School case end 

    // Manage Student case start 
    case "clickManageStudent":
      return { 
        showManageStudent: (state.showManageStudent = true) };
    case "closeStudentListPop":
      return {
         showManageStudent: (state.showManageStudent = false) };
    case "AddStudentForm":
      return {
        ShowAddStudentForm: (state.AddStudentForm = true),
        showManageStudent: (state.showManageStudent = true),
      };
    case "closeStudentAddForm":
      return {
        ShowAddStudentForm: (state.AddStudentForm = false),
        showManageStudent: (state.showManageStudent = true),
      };
      case "AlloSubject_F_Stu":
        return{
          ShowSubForAllstu: (state.ShowSubForAllstu = true),
          showManageStudent: (state.showManageStudent = true)
        };
      case "HindePopupStuSubject":
         return{
          ShowSubForAllstu: (state.ShowSubForAllstu = false),
          showManageStudent: (state.showManageStudent = true)
         };
     // Manage Student case end 

    //  Allocate Subject case start
    case "allocateSubjectPop":
      return {
        AlocateSubjectPop: (state.AlocateSubjectPop = true)
      };
    case "closeSubjectAllocatePop":
      return{
        AlocateSubjectPop: (state.AlocateSubjectPop = false)
      };
   
    //  Allocate Subject case end

      
      
  }
};
function SuperAdmin() {
  const [state, dispatch] = useReducer(reducer, { showSchoolForm: false });

  const [showSubjectList, setSubjectList] = useState([]);
  const [showStateList, setwStateList] = useState([]);
  const [showSchoolDetailes, setSchoolDetailes] = useState([]);
  const [showUserDetailes, setUserDetailes] = useState([])
  const[loader,setLoader] = useState(false)

  const inputBox = useRef(null);
  const table = useRef(null);

  useEffect(() => {
    getSubject();
    getState();
    getSchool();
   
  }, []);


    // search data functions start
    function searchData() {
      let filter, tr, tds, i, txtValue;
      filter = inputBox.current.value.toUpperCase();
      tr = table.current.getElementsByTagName("tr");
      console.log(tr);
      for (i = 1; i < tr.length; i++) {
        tds = tr[i].getElementsByTagName("td");
        let isExist = false;
        for (let td of tds) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            isExist = true;
          }
        }
        if (isExist) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
    // search data functions end
  // get Subject Function start
  function getSubject() {
    fetch(apiBaseURL + apiURL.getSubject, {
      method: "GET",
      headers: { key: apiKey },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.status == "success") {
            setSubjectList(data.data.subjects);
          } else {
            alert(data.message);
          }
        });
      })
      .catch((err) => {
        alert(err);
      });
  }
  // get Subject Function end

  // get state functions start
  function getState() {
    fetch(apiBaseURL + apiURL.getStateList, {
      method: "GET",
      headers: { key: apiKey },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.status == "success") {
            setwStateList(data.data);
          } else {
            alert(data.message);
          }
        });
      })
      .catch((err) => {
        alert(err);
      });
  }
  // get state functions end

    // get school functions star
    function getSchool(){
        const xPostData = {}
        fetch(apiBaseURL + apiURL.getSchoolList,{
            method: "POST",
            headers: {Key: apiKey},
            body:JSON.stringify(xPostData)
        })
        .then( res => res.json() )
        .then((Schooldata)=>{
            if(Schooldata.status == "success"){
                setSchoolDetailes(Schooldata.data.schools)
            }else{
                setSchoolDetailes([]);
                alert(Schooldata.message);
            }
        })
        .catch((err)=> {
            setSchoolDetailes([]);
            alert(err);
        })
   }
  // get school functions end

  // get Student functions start
    function getStudent(schoolCode){
        setLoader(true)
        const xPostData ={
          "userType":3,
          "schoolCode":schoolCode
        }
        fetch(apiBaseURL+apiURL.getUsersList,{
            method: "POST",
            headers: {Key: apiKey},
            body:JSON.stringify(xPostData)
        })
        .then( response => response.json() )
        .then((data)=>{
            if(data.status == "success"){
                setUserDetailes(data.data.users)
            }else{
                setUserDetailes([])
                alert(data.message);
            }
        })
        .catch((err)=>{
            setUserDetailes([])
            alert(err);
        }).finally( ()=>{
            setLoader(false)
        } )
  }
  // get Student functions end






  return (
    <div className={`${SuperAdminCss.backDiv}`}>
      {/* Header Section */}
      <div className={`${SuperAdminCss.rightArea}`}>
        {/* Header */}
        <Header />
        {/* Header */}

        <div className={`${SuperAdminCss.buttonBgs}`}>
          <Container fluid className="schoolRegister">
            <Row className="d-flex justify-content-center ">
              <Col className="col-md-3 col-sm-4 col-12">
                <div
                  className={`${SuperAdminCss.cricleBg}`}
                  onClick={() => {
                    dispatch({ type: "showSchoolList" });
                  }}
                >
                  <div className={`${SuperAdminCss.boxWithIcon}`}>
                    <img src={schoolReister} />
                  </div>
                  <p className={`${SuperAdminCss.buttonBottomTxt}`}>
                    Manage School
                  </p>
                </div>
              </Col>
              <Col className="col-md-3 col-sm-4 col-12">
                <div
                  className={`${SuperAdminCss.cricleBg}`}
                  onClick={() => {
                    dispatch({ type: "clickManageStudent" });
                  }}
                >
                  <div className={`${SuperAdminCss.boxWithIcon}`}>
                    <img src={ManageStudent} />
                  </div>
                  <p className={`${SuperAdminCss.buttonBottomTxt}`}>
                    Manage Student
                  </p>
                </div>
              </Col>
              <Col className="col-md-3 col-sm-4 col-12">
                <div
                  className={`${SuperAdminCss.cricleBg}`}
                  onClick={() => {
                    dispatch({ type: "allocateSubjectPop" });
                  }}
                >
                  <div className={`${SuperAdminCss.boxWithIcon}`}>
                    <img src={SubjectAllocate} />
                  </div>
                  <p className={`${SuperAdminCss.buttonBottomTxt}`}>
                  Allocate Subjects 
                  </p>
                </div>
              </Col>
              <Col className="col-md-3 col-sm-4 col-12">
                <Link to="/superAdmin/Report"> 
                <div className={`${SuperAdminCss.cricleBg}`}>
                    <div className={`${SuperAdminCss.boxWithIcon}`}>
                      <img src={Report} />
                    </div>
                    <p className={`${SuperAdminCss.buttonBottomTxt}`}>
                    Report
                    </p>
                </div>
                </Link>
              </Col>
            </Row>
            {/* Register Form start*/}

            {state.showSchoolForm && (
              <div className={`${SuperAdminCss.SchoolpopupForm}`}>
                <Container fluid>
                  <div className={`${SuperAdminCss.tableBgs}`}>
                    <Row>
                      <Col>
                        <div className={`${SuperAdminCss.stulist}`}>
                          School List
                        </div>
                      </Col>
                      <Col className="text-right">
                        <div className={`${SuperAdminCss.inputFiled}`}>
                          <PersonSearchIcon className={`${SuperAdminCss.searchIcons}`}/>
                          <input  type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className={`${SuperAdminCss.searchOption}`}></div>
                      <Table  className={`${SuperAdminCss.TableManageT}`} striped bordered hover responsive ref={table}>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>School Type</th>
                            <th>School Code</th>
                            <th>School/Institute Name</th>
                            <th>Address</th>
                            <th>District</th>
                            <th>State</th>
                            <th>Pin Code</th>
                            <th>Contact</th>
                            <th>E-mail ID</th>
                            <th>Total Student</th>
                            <th>Active Inactive</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showSchoolDetailes.map((item, index)=>{
                            return(
                              <tr key={"school"+item.schoolID}>
                                <td>{index+1}</td>
                                <td>{item.schoolType}</td> 
                                <td>{item.schoolCode}</td> 
                                <td>{item.schoolName}</td>
                                <td>{item.schoolAddress}</td>
                                <td>{item.schoolDistrict}</td>
                                <td>{item.stateName}</td>
                                <td>{item.zipCode}</td>
                                <td>{item.conntactNo}</td>
                                <td>{item.email}</td>
                                <td>{item.studentStrength}</td>
                                <td>
                                  <label className={`${SuperAdminCss.switch}`}>
                                    <input type="checkbox" />
                                    <span
                                      className={`${SuperAdminCss.slider} ${SuperAdminCss.round}`}
                                    ></span>
                                  </label>
                                </td>
                                <td title="Edit Student">
                                  <EditIcon
                                    className={`${SuperAdminCss.editButton}`}
                                  />
                                </td>
                                <td title="Delete Student">
                                  <DeleteForeverIcon
                                    className={`${SuperAdminCss.delete}`}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                      
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <Col>
                          <button onClick={() => {dispatch({ type: "closePopupSchoolList" });}} className="btn btn-secondary" >
                            <CloseIcon /> CLOSE
                          </button>{" "}
                          <button onClick={() => {dispatch({ type: "SchoolRegisterPopup" }); }} className="btn btn-primary">
                            <AddBoxIcon /> ADD SCHOOL
                          </button>
                      </Col>
                      <Col>
                        <Pagination  className={`${SuperAdminCss.PaginationBar}`} >
                          <Pagination.First />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Last />
                        </Pagination>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            )}
            {/* Register Form end*/}

            {/* subject  popup start */}
            {state.showsubjectListPopup && (
              <div className={`${SuperAdminCss.subjectListPopupBg}`}>
                <div className={`${SuperAdminCss.popupInnerDiv}`}>
                  <form>
                    <div className={`${SuperAdminCss.checkboxBgs}`}>
                      {showSubjectList.map((item, index) => {
                        return (
                          <>
                            <label>
                              <input type="checkbox" /> {item.subjectName} 
                            </label>
                            <input className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" placeholder="" />
                            <div className={`${SuperAdminCss.clear}`}></div>
                          </>
                        );
                      })}
                    </div>
                    <div className={`'mt-3 ${SuperAdminCss.buttoninFooter}`}>
                      <button type="button" className="btn btn-success">
                        SAVE
                      </button>
                      <button type="reset" className="btn btn-secondary">
                        RESET
                      </button>
                      <button onClick={() => {dispatch({ type: "closeSubjectPop" });}} className="btn btn-danger">
                        CLOSE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {/* subject  popup end */}

            {/* Manage Student Popup show */}
            {state.showManageStudent && (
              <div className={`${SuperAdminCss.ManageStudent}`}>
                <Container fluid>
                  <div className={`${SuperAdminCss.tableBgs}`}>
                    <Row>
                      <Col>
                        <div className={`${SuperAdminCss.stulist}`}>
                          Student List
                        </div>
                      </Col>
                      <Col className="text-right">
                        <div className={`${SuperAdminCss.inputFiled}`}>
                            <PersonSearchIcon  className={`${SuperAdminCss.searchIcons}`}/>
                            <input type="text" className="form-control" ref={inputBox} onKeyUp={searchData} placeholder="Search"/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                          <div className={`${SuperAdminCss.selectSchoolbk}`}>
                            <label>Select School/Institute</label>
                              <select className="form-control" onChange={(event)=>{getStudent(event.target.value)}}>
                                  <option className="d-none">Select</option>
                                  {showSchoolDetailes.map((item)=>{
                                    return(
                                      <option value={item.schoolCode} >{item.schoolName}</option>
                                    )
                                  })}
                              </select>
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className={`${SuperAdminCss.searchOption}`}></div>
                      <Table className={`${SuperAdminCss.TableManageT}`} striped  bordered  hover responsive ref={table}>
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Student Name</th>
                            <th>Father's Name</th>
                            <th>School Code</th>
                            <th>Registration No</th>
                            <th>Email ID</th>
                            <th>Allocate Subjects</th>
                            <th>Active/Inactive</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {showUserDetailes.map((item,index)=>{
                           return(
                              <>
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.userDetail.firstName+" "+item.userDetail.lastName}</td>
                                <td>{item.userDetail.guardianName}</td>
                                <td>{item.userDetail.schoolCode}</td>
                                <td>{item.userDetail.userCode}</td>
                                <td>{item.userDetail.userEmail}</td>
                                <td> <button className="btn btn-secondary" onClick={(()=>{dispatch({type: "AlloSubject_F_Stu"})})}> <AddTaskIcon/> Allocate </button> </td>
                                <td>
                                  <label className={`${SuperAdminCss.switch}`}>
                                    <input type="checkbox" />
                                    <span
                                      className={`${SuperAdminCss.slider} ${SuperAdminCss.round}`}></span>
                                  </label>
                                </td>
                                <td title="Edit Student">
                                  <EditIcon
                                    className={`${SuperAdminCss.editButton}`}
                                  />
                                </td>
                                <td title="Delete Student">
                                  <DeleteForeverIcon
                                    className={`${SuperAdminCss.delete}`}
                                  />
                                </td>
                              </tr>

                              
                              </>
                            )
                          })}

                          {/* <tr>
                            <td>1</td>
                            <td>Mohan Singh </td>
                            <td>Sohan Singh</td>
                            <td>Delhi Public School</td>
                            <td>SWA@123456</td>
                            <td>sohan.singh@gmail.com</td>
                            <td> <button className="btn btn-secondary" onClick={(()=>{dispatch({type: "AlloSubject_F_Stu"})})}> <AddTaskIcon/> Allocate </button> </td>
                            <td>
                              <label className={`${SuperAdminCss.switch}`}>
                                <input type="checkbox" />
                                <span
                                  className={`${SuperAdminCss.slider} ${SuperAdminCss.round}`}></span>
                              </label>
                            </td>
                            <td title="Edit Student">
                              <EditIcon
                                className={`${SuperAdminCss.editButton}`}
                              />
                            </td>
                            <td title="Delete Student">
                              <DeleteForeverIcon
                                className={`${SuperAdminCss.delete}`}
                              />
                            </td>
                          </tr> */}
                         
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <Col>
                        <button  onClick={() => {dispatch({ type: "closeStudentListPop" }); }} className="btn btn-secondary">
                             <CloseIcon /> CLOSE
                        </button>{" "}

                        <button onClick={() => {dispatch({ type: "AddStudentForm" });}} className="btn btn-primary">
                          <AddBoxIcon /> ADD STUDENT
                        </button>
                      </Col>
                      <Col>
                        <Pagination className={`${SuperAdminCss.PaginationBar}`}>
                          <Pagination.First />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Last />
                        </Pagination>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            )}
            
            
            {/* Manage Student Popup show */}

            {/* Add New Students Popup start */}
            {state.ShowAddStudentForm && (
              <div className={`${SuperAdminCss.AddStudentBk}`}>
                <div className={`${SuperAdminCss.innerBox}`}>
                  <div className={`${SuperAdminCss.addstuHeading}`}>
                    Add Student
                  </div>
                  <form className={`${SuperAdminCss.addstuForm}`}>
                    <div className={`${SuperAdminCss.addStuForm}`}>
                      <div className="mt-2">
                        <label>Select School</label>
                        <select className="form-control">
                          <option className="d-none">Select</option>
                          <option>School - A</option>
                          <option>School - B</option>
                          <option>School - C</option>
                        </select>
                      </div>
                      <div className="mt-3">
                        <label>Student Name</label>
                        <input
                          required
                          type="text"
                          placeholder="Student Name"
                          className="form-control"
                        />
                      </div>
                      <div className="mt-3">
                        <label>Father's Name</label>
                        <input
                          required
                          type="text"
                          placeholder="Father Name"
                          className="form-control"
                        />
                      </div>
                      <div className="mt-3">
                        <label>Registration No</label>
                        <input
                          required
                          type="text"
                          placeholder="Father Name"
                          className="form-control"
                        />
                      </div>
                      <div className="mt-3">
                        <label>E-mail ID</label>
                        <input
                          required
                          type="email"
                          placeholder="E-mail ID"
                          className="form-control"
                        />
                      </div>
                      <div className={`mt-3 ${SuperAdminCss.footerbuttonRow}`}>
                        <button type="butt0n" className="btn btn-success">
                          SAVE
                        </button>
                        <button type="reset" className="btn btn-danger">
                          RESET
                        </button>
                        <button
                          onClick={() => {
                            dispatch({ type: "closeStudentAddForm" });
                          }}
                          className="btn btn-secondary"
                        >
                          CLOSE
                        </button>{" "}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Add New school popup start */}
            {state.ShowSchoolRegisterForm && (
                <div className={`${SuperAdminCss.SchoolRegisterBk}`}>
              <Container>
                <form className={`${SuperAdminCss.schoolRegisterf}`}>
                  <div className={`${SuperAdminCss.formBgs}`}>
                    <CancelIcon
                      onClick={() => {
                        dispatch({ type: "closeSchoolRegiform" });
                      }}
                      className={`${SuperAdminCss.crossIcon}`}
                    />
                    <p className={`${SuperAdminCss.heading}`}>
                      School Register
                    </p>
                    <hr />
                    <Row>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          School/Institute
                        </label>
                        <select
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                        >
                          <option className="d-none">Select</option>
                          <option>School</option>
                          <option>Institute</option>
                        </select>
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          School/Institute Name
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="text"
                          placeholder="School/Institute Name"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Address
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="text"
                          placeholder="Address"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          District
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="text"
                          placeholder="District"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          State
                        </label>
                        <select
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                        >
                          <option className="d-none">Select</option>
                          {showStateList.map((item) => {
                            return <option>{item.stateNameLang1}</option>;
                          })}
                        </select>
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Pin Code
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="number"
                          placeholder="Pin Code"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Contact
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="number"
                          placeholder="Contact"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Email ID
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="email"
                          placeholder="E-mail ID"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Total Student
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="number"
                          placeholder="Total Student"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Subject
                        </label>
                        <input
                          onClick={() => {
                            dispatch({ type: "subjectListPopup" });
                          }}
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="text"
                          placeholder="Select"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Total Amount
                        </label>
                        <input
                          required
                          className={`form-control ${SuperAdminCss.inputsStyle}`}
                          type="number"
                          placeholder="Rs: 0"
                        />
                      </Col>
                      <Col className="col-md-4 col-sm-4 col-12">
                        <label className={`${SuperAdminCss.labelText}`}>
                          Paid
                        </label>
                        <select
                          className={`form-control ${SuperAdminCss.inputsStyle}`}>
                          <option className="d-none">Select</option>
                          <option>Online</option>
                          <option>Offline</option>
                        </select>
                      </Col>
                    </Row>
                    <button type="submit" className="btn btn-success lg mt-3">
                      SAVE
                    </button>{' '}
                    <button type="reset" className="btn btn-info lg mt-3">
                      RESET
                    </button>
                  </div>
                </form>
              </Container>
              </div>
            )}
            {/* Add New school popup end */}


            {/* Allocate subjects popup start */}
            {state.AlocateSubjectPop &&
            <div className={`${SuperAdminCss.AllocateSubjectList}`}>
                <div className={`${SuperAdminCss.allo_subjact}`}>
                <div className={`${SuperAdminCss.addstuHeading}`}>
                    Allocate Subjects
                  </div>
                  <form className={`${SuperAdminCss.AlocateSubjectFormBk}`}>
                    <div className={`${SuperAdminCss.checkboxBgs}`}>
                      <label>Select School/Institute</label>
                      <select className="form-control mb-3">
                        <option className="d-none">Select</option>
                        {showSchoolDetailes.map((item)=>{
                          return(
                            <option>{item.schoolName}</option>
                          )
                        })}
                       </select>
                      {showSubjectList.map((item, index) => {
                        return (
                          <>
                            <label>
                              <input type="checkbox" /> {item.subjectName}
                            </label>
                            <input className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" placeholder="" />
                            <div className={`${SuperAdminCss.clear}`}></div>
                          </>
                        );
                      })}
                    </div>
                    <div className={`'mt-3 ${SuperAdminCss.buttoninFooter}`}>
                      <button type="button" className="btn btn-success">
                        SAVE
                      </button>
                      <button type="reset" className="btn btn-secondary">
                        RESET
                      </button>
                      <button
                        onClick={() => {
                          dispatch({ type: "closeSubjectAllocatePop" });
                        }}
                        className="btn btn-danger"
                      >
                        CLOSE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              }
            {/* Allocate subjects popup end */}




          {/* Allocate Subect For Student Only start */}
            {state.ShowSubForAllstu &&
            <div className={`${SuperAdminCss.AllocateSubForStu}`}>
                 <div className={`${SuperAdminCss.allo_subjact_ForStu}`}>
                <div className={`${SuperAdminCss.addstuHeading}`}>
                    Allocate Subjects For Students
                  </div>
                  <form className={`${SuperAdminCss.AlocateSubjectFormBk}`}>
                    <div className={`${SuperAdminCss.checkboxBgs}`}>
                      {showSubjectList.map((item, index) => {
                        return (
                          <>
                            <label>
                              <input type="checkbox" /> {item.subjectName}
                            </label>
                            {/* <input className={`form-control ${SuperAdminCss.inputWithSubject}`} type="number" placeholder="" /> */}
                            <div className={`${SuperAdminCss.clear}`}></div>

                          </>
                        );
                      })}
                    </div>
                    <div className={`'mt-3 ${SuperAdminCss.buttoninFooter}`}>
                      <button type="button" className="btn btn-success">
                        SAVE
                      </button>
                      <button type="reset" className="btn btn-secondary">
                        RESET
                      </button>
                      <button
                        onClick={() => {
                          dispatch({ type: "HindePopupStuSubject" });
                        }}
                        className="btn btn-danger"
                      >
                        CLOSE
                      </button>
                    </div>
                  </form>
                </div>
            </div>
              
            }
            {loader &&
            
                 <Loader/>                
            }
          {/* Allocate Subect For Student Only end*/}

          </Container>
        </div>

      </div>
      {/* Right Area */}
    </div>
  );
}

export default SuperAdmin;
