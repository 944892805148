import AdminDashBoardCss from './adminDashboard.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminLeftMenu from './AdminLeftMenu';
import DashBoardButton from './dashBoardButton';
import swaLogo from '../../assets/logo.png';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { useRef } from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import GenaratePracticeIcon from '../../assets/topic_wise_practice.png';
import AutoGenerate from '../../assets/autoGenerate.png';
import examGnerate from '../../assets/examGnerate.png';
import GenExam from '../../assets/genera.png';
import { useState } from 'react';

function GenerateExam() {
 
  const[showGenPra, getParForm]= useState(false);
  return (
    <div className={`${AdminDashBoardCss.backDiv}`}>
    {/* Header Section */}
  <div  className={`${AdminDashBoardCss.rightArea}`}>
   <div className={`${AdminDashBoardCss.HeaderTop}`}>
       <div className={`${AdminDashBoardCss.logoCol}`}>
          <div className={`${AdminDashBoardCss.logoDiv}`}>
             <img className={`${AdminDashBoardCss.logoimgs}`} src={swaLogo}/>
          </div>
       </div>
      
       <div className={`${AdminDashBoardCss.clear}`}></div>
   </div>

   {/* Left Menu */}
   <AdminLeftMenu/>
    {/* Left Menu */}


    {/* Select section */}
  <Container fluid>
  <div className={`${AdminDashBoardCss.BkgenerateExam}`}>
      <div className={`${AdminDashBoardCss.HeadingG}`}><img src={GenExam}/>Generate Exam</div>
  
      <Row className='justify-content-around'>
        <Col  className='col-md-4 col-sm-6 col-12'>
            <div onClick={()=>{getParForm(true)}} className={`${AdminDashBoardCss.boxesIcon} ${AdminDashBoardCss.active}`}>
              <img src={GenaratePracticeIcon}/>
              <p>Generate Practice Set</p>
            </div>
        </Col>
       
        <Col className='col-md-4 col-sm-6 col-12'>
            <div className={`${AdminDashBoardCss.boxesIcon}`}>
              <img src={examGnerate}/>
              <p>Final Exam</p>
            </div>
        </Col>
      </Row>
      <Row>
        <form>
          <Col>
              <div className={`${AdminDashBoardCss.labelTExt}`}></div>
          </Col>
        </form>
      </Row>

       {showGenPra && 
        <div className={`${AdminDashBoardCss.generatePrcticeSection}`}>
          <label className={`${AdminDashBoardCss.SectionLabel}`}><input checked  name="radio" type="radio"/> Section A</label>
          <label className={`${AdminDashBoardCss.SectionLabel}`}><input  name="radio" type="radio"/> Section B</label>

          <form className={`${AdminDashBoardCss.formBgA}`}>
            <Row>
                  <Col className='col-md-4 col-sm-4 col-12'>
                  <div className={`${AdminDashBoardCss.inputHolder}`}>
                  <label>Select Topic</label>
                  <select required autoComplete='off' className={`${AdminDashBoardCss.slectTag}`}>
                    <option></option>
                    <option>Topic - A</option>
                    <option>Topic - B</option>
                    <option>Topic - C</option>
                  </select>
                  </div>
                  </Col>

                  <Col className='col-md-4 col-sm-4 col-12'>
                  <div className={`${AdminDashBoardCss.inputHolder}`}>
                  <label>Select Sub Topic</label>
                  <select required autoComplete='off' className={`${AdminDashBoardCss.slectTag}`}>
                    <option></option>
                    <option>Topic - A</option>
                    <option>Topic - B</option>
                    <option>Topic - C</option>
                  </select>
                  </div>
                  </Col>
                  {/* <Col className='col-md-4 col-sm-4 col-12'>
                  <div className={`${AdminDashBoardCss.inputHolder}`}>
                  <label>No of Question</label>
                  <input type="text" required autoComplete='off' className={`${AdminDashBoardCss.slectTag}`}/>
                   </div>
                  </Col> */}

                  {/* <Col className='col-md-4 col-sm-4 col-12'>
                  <div className={`${AdminDashBoardCss.inputHolder}`}>
                  <label>Exam start date</label>
                  <input type="date" required autoComplete='off' className={`${AdminDashBoardCss.slectTag}`}/>
                  </div>
                  </Col>
                  <Col className='col-md-4 col-sm-4 col-12'>
                  <div className={`${AdminDashBoardCss.inputHolder}`}>
                  <label>Exam start time</label>
                  <input type="time"  className={`${AdminDashBoardCss.slectTag}`}/>
                  </div>
                  </Col>
                  <Col className='col-md-4 col-sm-4 col-12'>
                  <div className={`${AdminDashBoardCss.inputHolder}`}>
                  <label>Exam end date</label>
                  <input type="date" required autoComplete='off' className={`${AdminDashBoardCss.slectTag}`}/>
                  </div>
                  </Col> */}
                  
            </Row>
            <Button type='submit' variant="outline-success">Generate</Button>
          </form>
        </div>
      }
  </div>


  
  </Container>
  {/* Select section */}



  </div>
 


 </div>
  );
}

export default GenerateExam;
