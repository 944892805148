// import React {useState} from 'react'
import ManagQueCss from './ManageQue.module.css';
import Header from '../adminDashboard/Header';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { Container, Row, Col } from 'react-bootstrap';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import EditInsts from './EditInstruction'
import {RouteURL} from './../../constant';

function AddEditInstruction() {

    // const[data, setData] =useState({index:null, data:null})
    const[edtiInsts, setEditInsts]=useState({status:false, index:null, data:null});
    const[isValue, setValue]=useState({
        value:''
    })

    function myName(name){
        setValue((prev)=>{
          return  {...prev, value:name}
        })
    }

    let name=null;

    if(isValue.value==1){
        name = "General Instruction";
    }else if(isValue.value==2){
        name = "Section A";
    }else if(isValue.value==3){
        name = "Section B";
    }else if(isValue.value==4){
        name = "MCQ based Instruction";
    }else if(isValue.value==5){
        name = "Case based Instruction";
    }else if(isValue.value==6){
        name = "AR based Instruction";
    }


    
        const[showInst, setShowInst]=useState({
        insForm: false,
        insType: '',
        curIns:'',
        addIns:false,
        insts:[],
        

        
    })
    function showInsData(name, val=''){
        if(name=='insForm'){
            console.log(name)
            setShowInst((prev)=>{
                return{...prev, insForm:true}
            })
        } else if(name=="setType"){
            console.log(val);
            setShowInst((prev)=>{
                return{...prev, insType:val}
            })
        } else if(name=='insDiv'){
            setShowInst((prev)=>{
                return{...prev, addIns:true}
            })
        } else if(name=='activeBtn1'){
            setShowInst((prev)=>{
                // let sdfdsf = {status:true, value:val}
                return{...prev,curIns:val}
            })
        } 
        
        
    }

   
    function addInstruction(){
        setShowInst((prev)=>{
            let instList = [...prev.insts];
            let length = instList.length;
            let lastId = 1;
            if(length!=0){
                lastId = instList[length-1].id;

            } 
            instList.push({id:++lastId, data:prev.curIns})
            return{...prev,insts:instList, curIns:''}
        })
    }

    function editItem(index, item) {
       
        setEditInsts({status:true, index:index, data:item.data})
    }

    function saveEditQues() {
        setShowInst((prev)=>{
            let updateInsts=[...prev.insts]
            let index=edtiInsts.index
            if(index!=null){
                updateInsts[index].data = edtiInsts.data;
            }
            
            return {...prev, insts:updateInsts,}
        })
        setEditInsts({status:false, index:null, data:null});  

    }
    function removeItem(index){
        
        setShowInst((prev)=>{

            let instList = [...prev.insts];
            instList.splice(index, 1);
            return {...prev, insts:instList}
        })

    }

    return (
        <div>
            <Header/>
                <div className={`${ManagQueCss.addQueBar}`}>
                        <div className={`${ManagQueCss.buttonDiv}`}>  
                            <button className={` ${ManagQueCss.addbtn} btn btn-primary addMore`} onClick={()=>{showInsData('insForm')}}>Manage Instruction</button>
                            <Link to={RouteURL.addQuestion}><button className={` ${ManagQueCss.addbtn} btn btn-primary addMore`}>Manage Question</button></Link>
                        </div>
                        <div className={`${ManagQueCss.backbutton}`}>
                        <Link to="/admin"><div className={`${ManagQueCss.backLinkDash}`}> <HomeIcon/> Back </div></Link>
                        </div>
                </div>
                {showInst.insForm &&
                    <Container>
                        <div className={`${ManagQueCss.instForm}`}>
                            <div className={`${ManagQueCss.selectTypeIns}`}>
                                <form method='get' className={`${ManagQueCss.selectSubject}`}>
                                        <label>Instruction Type:</label>
                                        <select required className={`${ManagQueCss.selectSubject}`} onChange={(event)=>{showInsData('setType', event.target.value); myName(event.target.value)}}>
                                            <option value="0" >Select Type of Instruction</option>
                                            <option value="1" >General Instruction </option>
                                            <option value="2" >Section A </option>
                                            <option value="3" >Section B </option>
                                            <option value="4" >MCQ based Instruction </option>
                                            <option value="5" >Case based Instruction </option>
                                            <option value="6" >AR based Instruction </option>
                                        </select>
                                </form>
                                <div className={`${ManagQueCss.addInstBtn}`}>
                                    <button className='btn btn-primary' disabled={showInst.insType==''? true:false} onClick={()=>{showInsData('insDiv')}}>Add Instruction</button>
                                </div>
                            </div>

                            {showInst.addIns &&
                            <>
                                <div className={`${ManagQueCss.addInstructionDiv}`}>
                                    <div className={`${ManagQueCss.addIns}`}>
                                        <label>Instruction:</label>
                                        <div className={`${ManagQueCss.textAreaBox}`}>
                                            <textarea className={`${ManagQueCss.insInputBox}`} value={showInst.curIns} onChange={(event)=>{showInsData('activeBtn1', event.target.value)}}></textarea>
                                        </div>
                                        <div className={`${ManagQueCss.addMoreBtn}`}>
                                            <button className='btn btn-primary mx-2' title={"Add Instruction"} disabled={showInst.curIns==''?true:false} onClick={addInstruction}><AddCircleOutlineIcon/></button>
                                        </div>
                                    </div>

                                    {showInst.insts.length>0 &&
                                        <div className={`${ManagQueCss.instructionListDiv}`}>
                                            <table className={`${ManagQueCss.instructionList}`}> 
                                                <tr className={`${ManagQueCss.typeInstruction}`}>
                                                    <th>{name}</th>
                                                    <th style={{width: '130px'}}></th>
                                                </tr>
                                                {showInst.insts.map((item, index)=>{
                                                    
                                                    return(
                                                        <tr className={`${ManagQueCss.eachInstruction}`} key={item.id}>
                                                            <td>{index+1}. {item.data}</td>
                                                            <td><button className='btn btn-outline-success btn-xs mx-2'  title={"Edit Instruction"}  value={item.data} onClick={()=>{editItem(index, item)}}><EditIcon className={`${ManagQueCss.iconStyle}`} />
                                                            </button> <button className='btn btn-outline-secondary' title={"Delete Instruction"} onClick={()=>{removeItem(index)}}><DeleteIcon className={`${ManagQueCss.iconStyle}`}/></button></td>
                                                        </tr>
                                                    )

                                                })}

                                            </table>
                                        </div>
                                    }
                                    
                                    

                                    <div className={`${ManagQueCss.savBtn}`}>
                                        <button className='btn btn-success'>Save</button>
                                    </div>
                                </div>
                            </>
                                }

                        </div>
                        
                        

                    </Container>
                }
                {edtiInsts.status &&
                    <div>
                    <div className={`${ManagQueCss.outGray}`} onClick={()=>{setEditInsts(false)}}>
                        <div className={`${ManagQueCss.mainContainer}`}  onClick={(event)=>{event.stopPropagation();}}>
                            <div className={`${ManagQueCss.mainHead}`}>
                                <textarea className={`${ManagQueCss.editInstText}`} value={edtiInsts.data} 
                                onChange={(event)=>{setEditInsts((prev)=>{
                                    return {...prev, data:event.target.value}
                                })}}
                                />
                            </div>
                            
                            <div className={`${ManagQueCss.popupSavBtn}`}>
                                <button className='btn btn-success' onClick={()=>{saveEditQues()}}>Save Change</button>
                            </div>
                        </div>
        
                    </div>
                </div>
                }

        </div>
    )
}

export default AddEditInstruction
