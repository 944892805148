import {useEffect, useContext } from 'react';
import StuDashboard from './components/studentDashboard/studentDashboard';
import AdminDashboard from './components/adminDashboard/adminDashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import AdminLogin from './components/login/AdminLogin';
import ManageQuestion from './components/adminDashboard/ManageQue';
import GeneratePracticeSet from './components/adminDashboard/GeneratePracticeSet';

import CodeLogin from './components/login/CodeLogin';
import WelcomePage from './components/welcome/Welcomeuser';
import GenerateExam from './components/adminDashboard/GenerateExam';
import TopicWisePractice from './components/studentDashboard/TopicWisePractice';
import PracticeSets from './components/studentDashboard/PracticeSet';
import QuickNotes from './components/studentDashboard/studentQuickNotesPage';
import AttemptedScreen from './components/studentDashboard/AttemptedMcq';
import SelfGeneAssessment from './components/studentDashboard/SelfGeneAssessment';
import StudentReport from './components/studentDashboard/report/Report';
import Register from './components/register/Register';
import UserProfile from './components/studentDashboard/UserProfile';
import AddEdit from './components/adminDashboard/AddEditInstruction';
import AddQue from './components/adminDashboard/ManageQue';
import ManageStu from './components/adminDashboard/ManageStu';
import SuperAdmin from './components/superAdmin/SuperAdmin';
import SuperAdminReport from './components/superAdmin/Report';
import FineNoteFound from './FineNoteFound';
import ManageStudent_Admin from './components/adminDashboard/Manage_student';

import userContext from './Store';
import React from 'react';
import {RouteURL} from './constant';
function App() {
    const {userData} = useContext(userContext);
   
   
    useEffect((item)=>{
        // checkLogin()
    }, [])


    return (
        <div className="App">
             <Routes> 
                {/* Common routing - start */}
                    <Route path={RouteURL.studentLoginPage}  element={<CodeLogin/>}></Route>
                    <Route path={RouteURL.signupPage}  element={<Register/>}></Route>
                    <Route path={RouteURL.masterLoginPage}  element={<AdminLogin/>}></Route>
                {/* Common routing - end */}


                {/* Super Admin Dashboard routing - start */}
                    { userData.userTypeID===1 &&
                        <>
                            <Route path={RouteURL.superAdmin} element={<SuperAdmin/>}></Route>
                            <Route path={RouteURL.superAdminReport} element={<SuperAdminReport/>}></Route>

                        </>
                    }
                {/* Super Admin Dashboard routing - end */}


                {/* School Admin Dashboard routing - start */}
                    { userData.userTypeID===2 &&
                        <>
                            <Route path={RouteURL.adminDashboard}  element={<AdminDashboard/>}></Route>
                            <Route path={RouteURL.manageQuestion}  element={<ManageQuestion/>}></Route>
                            <Route path={RouteURL.addEditInst}  element={<AddEdit/>}></Route>
                            <Route path={RouteURL.addQuestion}  element={<AddQue/>}></Route>
                            <Route path={RouteURL.manageStu}  element={<ManageStu/>}></Route>
                            <Route path={RouteURL.adminGenerateExam}  element={<GenerateExam/>}></Route> 
                            <Route path={RouteURL.ManageStudent_Admin}  element={<ManageStudent_Admin/>}></Route> 

                        </>
                    }
                {/* School Admin Dashboard routing - end */}
                

                {/* Student Dashboard routing - start */}
                    { userData.userTypeID===3 &&
                        <>
                            <Route path={RouteURL.studentWelcomePage}  element={<WelcomePage/>}></Route>
                            <Route path={RouteURL.userProfile}  element={<UserProfile/>}></Route>
                            <Route path={RouteURL.studentDashboard}  element={<StuDashboard/>}></Route>
                            <Route path={RouteURL.studentQuickNotesPage}  element={<QuickNotes/>}></Route>
                            <Route path={RouteURL.studentTopicWisePracticePage}  element={<TopicWisePractice/>}></Route>
                            <Route path={RouteURL.studentPracticeSetPage}  element={<PracticeSets/>}></Route>
                            <Route path={RouteURL.studentSelfGenerteAssessmentPage}  element={<SelfGeneAssessment/>}></Route>
                            <Route path={RouteURL.studentAttemptScreenPage}  element={<AttemptedScreen/>}></Route>
                            <Route path={RouteURL.studentReport}  element={<StudentReport/>}></Route> 
                        </>
                    }
                {/* Student Dashboard routing - end */}


                {/* DTP Dashboard routing - start */}
                    { userData.userTypeID===4 &&
                        <>
                            <Route path={RouteURL.dtpDashboardPage} element={<ManageQuestion/>}></Route>
                            <Route path={RouteURL.dtpGeneratePracticeSet} element={<GeneratePracticeSet/>}></Route>
                        </>
                    }
                {/* DTP Dashboard routing - end */}



                {/* 404 page - start */}
                    <Route path='*' element={<FineNoteFound/>}></Route>
                {/* 404 page - end */}
            </Routes>
           <footer>© 2022 Swa-Adhyayan All Right Reserved.</footer>

        </div>
        
    );
}

export default App;
