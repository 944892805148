import StuDashBoardCss from './stuDashboard.module.css';
import ReorderIcon from '@mui/icons-material/Reorder';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { AiFillDashboard } from "react-icons/ai";
import QuickNotes from '../../assets/quick_notes.png';
import TopicWise from '../../assets/topic_wise_practice.png';
import PracticeSet from '../../assets/practice_set.png';
import selfAssessment from '../../assets/self_asmt.png';
import finalExam from '../../assets/final_exam.png';
import report from '../../assets/report.png';
import { useRef } from 'react';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";

function StudentDash() {

  const leftMenu = useRef();
  
  const [isShow, setShow]=useState(false);

   
  const pathName = useLocation().pathname;
  console.log(pathName);

  
  
  return (
    <div className={`${StuDashBoardCss.backDiv}`}>
      <div ref={leftMenu} className={`${StuDashBoardCss.leftNavigation}`}>
       {/*  */}
       <Link  to="/student">
          <div  className={`${StuDashBoardCss.leftButton}`}>
          <DashboardSharpIcon className={`${StuDashBoardCss.dashboardButton}`}/>
          <div className={`${StuDashBoardCss.leftText}`}>DASHBOARD</div>
        </div>
        </Link>
         {/*  */}
       

        {/*  */}
        <Link className={pathName=='/student/quickNotes'?StuDashBoardCss.ActiveButton:null} to="/student/quickNotes">
          <div  className={`${StuDashBoardCss.leftButton}`}>
          <div className={`${StuDashBoardCss.iconLeft}`}>
            <img src={QuickNotes}/>
          </div>
          <div className={`${StuDashBoardCss.leftText}`}>Quick Notes</div>
        </div>
        </Link>
         {/*  */}

        {/*  */}
        <Link className={pathName=='/student/TopicWisePractice'?StuDashBoardCss.ActiveButton:null} to="/student/TopicWisePractice">
        <div className={`${StuDashBoardCss.leftButton}`}>
          <div className={`${StuDashBoardCss.iconLeft}`}>
            <img src={TopicWise}/>
          </div>
          <div className={`${StuDashBoardCss.leftText}`}>Topic/Chapter Wise Practice</div>
         
        </div> </Link>
         {/*  */}

         {/*  */}
         <Link className={pathName=='/student/practice-sets'?StuDashBoardCss.ActiveButton:null} to="/student/practice-sets">

        <div className={`${StuDashBoardCss.leftButton}`}>
          <div className={`${StuDashBoardCss.iconLeft}`}>
            <img src={PracticeSet}/>
          </div>
          <div className={`${StuDashBoardCss.leftText}`}>Practice Sets</div>
        </div>
        </Link>
         {/*  */}

          {/*  */}
          <Link className={pathName=='/student/Self-Generated-Assessment'?StuDashBoardCss.ActiveButton:null} to="/student/Self-Generated-Assessment">
        <div className={`${StuDashBoardCss.leftButton}`}>
          <div className={`${StuDashBoardCss.iconLeft}`}>
            <img src={selfAssessment}/>
          </div>
          <div className={`${StuDashBoardCss.leftText}`}>Self-Generated Assessment</div>
        </div>
        </Link>
         {/*  */}

          {/*  */}
        <div className={`${StuDashBoardCss.leftButton}`} style={{cursor: 'not-allowed'}}>
          <div className={`${StuDashBoardCss.iconLeft}`}>
            <img src={finalExam}/>
          </div>
          <div className={`${StuDashBoardCss.leftText}`}>Final Exam</div>
        </div>
         {/*  */}

          {/*  */}
        <Link className={pathName=='/student/Report'?StuDashBoardCss.ActiveButton:null} to="/student/Report">
          <div className={`${StuDashBoardCss.leftButton}`}>
            <div className={`${StuDashBoardCss.iconLeft}`}>
              <img src={report}/>
            </div>
            <div className={`${StuDashBoardCss.leftText}`}>Report</div>
          </div>
        </Link>
         {/*  */}

      </div>
    </div>
  );
}

export default StudentDash;
