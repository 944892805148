import StuDashBoardCss from "./stuDashboard.module.css";
import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "react-bootstrap/Button";
import GoogleIcon from '@mui/icons-material/Google';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function App() {
  const [showAddSubject, setAddSubject] = useState(false);

  return (
    <div className={`${StuDashBoardCss.buttonBg}`}>
        <nav className={`${StuDashBoardCss.social}`}>
          <ul>
              <li><a onClick={() => {setAddSubject(true);}}>Add More Subjects <i class="fa"> <ShoppingCartIcon /> </i></a></li>
              <li><a href="https://www.google.co.in/" target="_blank">Google Search <i class="fa"> <GoogleIcon /> </i></a></li>
          </ul>
      </nav>
      <div
        
        className={`${StuDashBoardCss.button1}`}
        title="Add Subject Section - B"
      >
       
      </div>

      {showAddSubject && (
        <>
          <div className={`${StuDashBoardCss.addMoreSubjectBg}`}>
            <div className={`${StuDashBoardCss.subjectBody}`}>
              <div className={`${StuDashBoardCss.headingBk}`}>
                <Row>
                  <Col>
                    <div className={`${StuDashBoardCss.SectionHeading}`}>
                      Section - B
                    </div>
                  </Col>
                  <Col>
                    <CancelIcon
                      onClick={() => {
                        setAddSubject(false);
                      }}
                      className={`${StuDashBoardCss.crossbutton}`}
                    />
                  </Col>
                </Row>
              </div>
              <div className={`${StuDashBoardCss.SubjectBodyArea}`}>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Mathematics
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Physics
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Chemistry
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Biology
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Accountancy
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Business Studies
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Economics
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Geography
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Political Science
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  History
                </label>
                <label className={`${StuDashBoardCss.containerSe}`}>
                  <input type="checkbox" />
                  <span className={`${StuDashBoardCss.checkmark}`}></span>
                  Physical Education
                </label>
                <Button className="mt-5" size="md" variant="primary">
                  Save
                </Button>{" "}
                &nbsp;
                <Button className="mt-5" size="md" variant="success">
                  Pay
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
