import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import managQueCss from './ManageQue.module.css';
import Button from 'react-bootstrap/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import { Container, Row, Col, Modal, Alert } from 'react-bootstrap'

import EditQue from './EditQue'
import GenerateFinalExam from './GenerateFinalExam';
import GeneratePracticeSet from './GeneratePracticeSet';
import Header from '../adminDashboard/Header';
import AddpassageQue from './AddPassageQue'
import EditQuestionPopup from './EditQuestionPopup';
import EditPassagePopupPage from './EditPassagePopupPage';

import HomeIcon from '@mui/icons-material/Home';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import { apiBaseURL, apiURL, apiKey, ckFinderURL } from '../../constant';
import userContext from '../../Store';

import Swal from 'sweetalert2';

const ckEditorConfiguration = {
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'superscript', 'subscript', '|', 'alignment', 'insertTable', 'bulletedList', 'numberedList', '|', 'uploadImage', 'specialCharacters', 'MathType', 'ChemType'],
    ckfinder: {
        uploadUrl: ckFinderURL
    }
};

const quesProcessingDataConf = {
    mode: '',
    section: '',
    classID: 12,
    topic: '',
    subTopic: '',
    subject: '',
    chapter: '',
    isFormOpened: false,
    isWithPassage: false,
    quesID: '',
    quesParagraphID: '',
    quesParagraph: '',
    quesHeading : '',
    quesOptionA : '',
    quesOptionB : '',
    quesOptionC : '',
    quesOptionD : '',
    quesRightOption : '0',
    quesExplanation : ''
};
const modalConf = {status:false,messageClass : "info",message:""};

function ManageQue() {

    const {userData} = useContext(userContext);
    console.log( userData.userRole.includes('add') );

    const loginToken = localStorage.getItem('token');

    const [modalShow, setModalShow] = useState(modalConf);
    const [ckInit,setCkInit] = useState(false);
    const [isLoaderActive,setIsLoaderActive] = useState(true);
    const [isPopupLoaderActive,setIsPopupLoaderActive] = useState(true);
    const [topicList, setTopicList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectChaptersList, setSubjectChaptersList] = useState([]);
    const [questionBankTypeList, setQuestionBankTypeList] = useState([]);
    const [subTopicsList, setSubTopicsList] = useState([]);
    const [quesProcessingData, setQuesProcessingData] = useState(quesProcessingDataConf);
    const [questionsList, setQuestionsList]=useState([]);
    const [passageList, setPassageList]=useState([]);
    const [passagePopup, setPassagePopup]=useState(false);
    const [editPassagePopupState, setEditPassagePopupState]=useState(false);
    const [editQuestionPopup, setEditQuestionPopup]=useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [quesSegrigationModalShow,setQuesSegrigationModalShow] = useState( {status:false, paragraphID:'', questionID:'', qbTypeID:''} );

    useEffect( ()=>{
        fetch( apiBaseURL+apiURL.getTopics,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setTopicList(result.data);
                }
            } ).catch( (err) => {
                setIsLoaderActive(false);
            } );

        fetch( apiBaseURL+apiURL.getSubjectList,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setSubjectList(result.data.subjects);
                }
            } ).catch( (err) => {
                setIsLoaderActive(false);
            } );

    },[] );

    const modalCloseHandler = e => {
        setModalShow(false);
    }


    const editQuesSegregationButtonHandler = (popupStatus,questionData) => {
        getQuestionBankTypeList();
        console.log(questionData);
        console.log("sdfsdf");
        let questionTypeID = questionData.questionTypeID==null || questionData.questionTypeID=='' ? '' : questionData.questionTypeID;
        let paragraphID = questionData.paragraphID==null || questionData.paragraphID=='' ? '' : questionData.paragraphID;
        setQuesSegrigationModalShow( (prev) => {
            return {...prev,'status': popupStatus, paragraphID:paragraphID, questionID:questionData.questionID, qbTypeID:questionTypeID}
        } );
    }
    const editQuesSegregationModalCloseHandler = (popupStatus) => {
        setQuesSegrigationModalShow( (prev) => {
            return {...prev,'status': popupStatus, paragraphID:'', questionID:'', qbTypeID:''}
        } );
    }
    const qbTypeChangeHandler = (e) => {
        setQuesSegrigationModalShow( (prev) => {
            return {...prev,'qbTypeID': e.target.value}
        } );
        console.log( quesSegrigationModalShow );
    }
    const getQuestionBankTypeList = () => {
        fetch( apiBaseURL+apiURL.getQuestionBankTypes,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsPopupLoaderActive(false);
                if(result.status=='success' ){
                    setQuestionBankTypeList(result.data.questionBankType);
                }
            } ).catch( (err) => {
                setIsPopupLoaderActive(false);
            } );
    }
    function updateQuestionTypeIDHandler(){
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify( {...quesSegrigationModalShow} )
        };
        setIsPopupLoaderActive(true);
        fetch( apiBaseURL+apiURL.updateQuestionTypeID, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success' ){
                    searchQuestions();
                    editQuesSegregationModalCloseHandler(false);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:"Successfully Updated." }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsPopupLoaderActive(false);
            } );
    }


    const editQuesClosePopupHandler = () => {
        setQuesProcessingData((prev)=>{
            return {
                    ...prev, 
                    quesID:'',
                    quesParagraphID: '',
                    quesHeading : '',
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                    quesRightOption : '0',
                    quesExplanation : ''
                }
        });
        setEditQuestionPopup(false)
    }

    const editPassageClosePopupHandler = () => {
        setQuesProcessingData((prev)=>{
            return {
                    ...prev, 
                    quesID:'',
                    quesParagraphID: '',
                    quesHeading : '',
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                    quesRightOption : '0',
                    quesExplanation : ''
                }
        });
        setEditPassagePopupState(false)
    }

    const editQuesButtonHandler = (popupState,questionData) => {
        setQuesProcessingData((prev)=>{
            return {
                    ...prev, 
                    quesID: questionData.questionID,
                    quesParagraphID: questionData.paragraphID,
                    quesHeading: questionData.questionText,
                    quesOptionA: questionData.optionText1,
                    quesOptionB: questionData.optionText2,
                    quesOptionC: questionData.optionText3,
                    quesOptionD: questionData.optionText4,
                    quesRightOption: questionData.correctAnswerIDs,
                    quesExplanation: questionData.questionExplanation
                }
        });
        setEditQuestionPopup(popupState)
    }
    
    const quesProcessingDataHandler = e => setQuesProcessingData((prev)=>{

        if( e.target.name=='mode' && e.target.value!=prev.mode ){
            prev = quesProcessingDataConf;
        }

        if( e.target.name=='section' && e.target.value!=prev.section ){
            prev = {...prev,
                topic: '',
                subTopic: '',
                subject: '',
                chapter: '',
                isFormOpened: false
            }
        }

        if( e.target.name=='topic' ){
            prev = {...prev, subTopic: '', isFormOpened: false}
        }

        if( e.target.name=='subject' ){
            prev = {...prev, chapter: '', isFormOpened: false}
        }

        if( e.target.name=='subTopic' || e.target.name=='chapter' ){

            if( quesProcessingData.mode=='add' ){
                prev = {...prev, isWithPassage:'', isFormOpened: false}
            }else{
                prev = {...prev, isFormOpened: false}
            }

        }

        if( e.target.name=='isWithPassage' ){

            if( e.target.value=='true' && quesProcessingData.mode=='add' ){
                getPassages(e);
            }
            
            if(!ckInit && quesProcessingData.mode=='add' ){
                setIsLoaderActive(true);
            }

            if( quesProcessingData.mode=='add' ){
                prev = {
                    ...prev, 
                    isFormOpened: true, 
                    quesID:'',
                    quesParagraphID: '', 
                    quesParagraph:'',
                    quesHeading : '', 
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                }
            }else{
                prev = {
                    ...prev, 
                    isFormOpened: false, 
                    quesID:'',
                    quesParagraphID: '', 
                    quesParagraph:'',
                    quesHeading : '', 
                    quesOptionA : '',
                    quesOptionB : '',
                    quesOptionC : '',
                    quesOptionD : '',
                }
            }
        }

        // console.log(quesProcessingData);
        return {...prev, [e.target.name] : e.target.value };
    });

    const ckInitHandler = ( stats ) => {
        if(stats){
            setIsLoaderActive(false);
        }
        setCkInit(stats);
    };

    const ckeditorHandler = ( targetName, targetValue ) => {
        setQuesProcessingData( (prev) => {
            return {...prev, [targetName]:targetValue }
        } );
    };


    function searchQuestions(){
        const xPostData = {
                            "classID":12,
                            "sectionID":quesProcessingData.section,
                            "topicID":quesProcessingData.topic,
                            "subTopicID":quesProcessingData.subTopic,
                            "subjectID":quesProcessingData.subject,
                            "chapterID":quesProcessingData.chapter,
                            "isWithPassage":quesProcessingData.isWithPassage
                        };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(xPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.searchQuestions, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status==='success' ){
                    // console.log( result.data.questions );
                    let qList = result.data.questions;
                    setQuestionsList( result.data.questions );
                    setQuesProcessingData( (prev) => {
                        return {...prev, isFormOpened:true }
                    } );

                    if( qList.length <1 ){
                        setModalShow( (prev)=>{
                            return {...prev, status:true,messageClass:'danger',message:"No Question Found" }
                        } );
                    }

                }else{
                    setQuestionsList([]);
                    setQuesProcessingData( (prev) => {
                        return {...prev, isFormOpened:false }
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setQuestionsList([]);
                setQuesProcessingData( (prev) => {
                    return {...prev, isFormOpened:false }
                } );
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    function getPassages(){
        const chpPostData = {
                            "classID":12,
                            "sectionID":quesProcessingData.section,
                            "topicID":quesProcessingData.topic,
                            "subTopicID":quesProcessingData.subTopic,
                            "subjectID":quesProcessingData.subject,
                            "chapterID":quesProcessingData.chapter
                        };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getPassagesList, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setPassageList( result.data.passages );
                    // console.log( passageList )
                }else{
                    setPassageList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setPassageList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }

    function getSubjectChapters(subjectID){
        const chpPostData = { "classID":12,"subjectID":subjectID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getChapterList, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubjectChaptersList(result.data.chapters);
                }else{
                    setSubjectChaptersList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err) => {
                setSubjectChaptersList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }



    function getSubTopicList(topicID){
        const topicPostData = { "topicID":topicID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(topicPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSubTopics, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubTopicsList(result.data.subTopics);
                }else{
                    setSubTopicsList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err) => {
                setSubTopicsList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    const addNewPassageQuesHandler = (popupState,passageID) => {
        setQuesProcessingData((prev)=>{
            return {...prev, quesParagraphID: passageID }
        });
        setPassagePopup(popupState)
    }

    const editPassageHandler = (popupState,passageID,paragraphText) => {
        setQuesProcessingData((prev)=>{
            return {...prev, quesParagraphID: passageID, quesParagraph:paragraphText }
        });
        setEditPassagePopupState(popupState)
    }


    const addNewQuesHandler = (e) => {
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({...quesProcessingData,'userID':userData.userRefID})
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.createQuestion, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    // alert( result.message );

                    if( quesProcessingData.quesParagraphID!='' ){
                        getPassages(e);
                    }

                    setQuesProcessingData( (prev)=>{
                        return {...prev, 
                            quesHeading : '',
                            quesOptionA : '',
                            quesOptionB : '',
                            quesOptionC : '',
                            quesOptionD : '',
                            quesRightOption : '0',
                            quesExplanation : ''
                        }
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( 
                (err)=>{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                    } );
                }
            )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }

    const deleteQuesButtonHandler = (questionID) => {

        Swal.fire({
            title: 'Are you sure ?',
            html: 'Do you really want to delete this question ? <br> Note: This action will permanently delete this question.',
            icon: 'question',
            confirmButtonText: 'Yes, Delete it.',
            confirmButtonColor: '#d33',
            focusCancel: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const conf = {
                    method:'post',
                    headers:{'key':apiKey,'loginToken':loginToken},
                    body:JSON.stringify({'questionID':questionID,'userID':userData.userRefID})
                };
                setIsLoaderActive(true);
                return fetch( apiBaseURL+apiURL.deleteQuestion, conf )
                        .then( response => response.json() )
                        .then( ( result ) => {
                            if( result.status!=='success' ) {
                                throw new Error(result.message)
                            }
                            searchQuestions();
                            return result.message;
                        } )
                        .catch( (error) => {
                            Swal.showValidationMessage("failed");
                        } )
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then( (r) => {

            if( r.isConfirmed ){
                Swal.fire(
                    'Success', 'Question Successfully Deleted.', 'success'
                );
            }

        } );
    }


    const deletePassageButtonHandler = (passageID) => {

        Swal.fire({
            title: 'Are you sure ?',
            html: 'Do you really want to delete this passage ? <br> Note: This action will permanently delete this passage along with its questions.',
            icon: 'question',
            confirmButtonText: 'Yes, Delete it.',
            confirmButtonColor: '#d33',
            focusCancel: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const conf = {
                    method:'post',
                    headers:{'key':apiKey,'loginToken':loginToken},
                    body:JSON.stringify({'passageID':passageID,'userID':userData.userRefID})
                };
                setIsLoaderActive(true);
                return fetch( apiBaseURL+apiURL.deletePassage, conf )
                        .then( response => response.json() )
                        .then( ( result ) => {
                            if( result.status!=='success' ) {
                                throw new Error(result.message)
                            }
                            getPassages();
                            return result.message;
                        } )
                        .catch( (error) => {
                            Swal.showValidationMessage("failed");
                        } )
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then( (r) => {

            if( r.isConfirmed ){
                Swal.fire(
                    'Success', 'Question Successfully Deleted.', 'success'
                );
            }

        } );
    }


    const updateQuesHandler = (e) => {

        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({...quesProcessingData,'userID':userData.userRefID})
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.updateQuestion, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){

                    searchQuestions();
                    editQuesClosePopupHandler();

                    // if( quesProcessingData.quesParagraphID!='' ){
                    //     getPassages(e);
                    // }

                    // setQuesProcessingData( (prev)=>{
                    //     return {...prev, 
                    //         quesHeading : '',
                    //         quesOptionA : '',
                    //         quesOptionB : '',
                    //         quesOptionC : '',
                    //         quesOptionD : '',
                    //         quesRightOption : '0',
                    //         quesExplanation : ''
                    //     }
                    // } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch(
                (err)=>{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                    } );
                }
            )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }


    const savePassage = (e)=>{
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({
                'classID': quesProcessingData.classID,
                'sectionID': quesProcessingData.section,
                'topicID': quesProcessingData.topic,
                'subTopicID': quesProcessingData.subTopic,
                'subjectID': quesProcessingData.subject,
                'chapterID': quesProcessingData.chapter,
                'quesParagraph':quesProcessingData.quesParagraph,
                'userID':userData.userRefID
            })
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.createPassage, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setQuesProcessingData( (prev)=>{
                        return {...prev, quesParagraph : ''}
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                    getPassages(e);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }


    const updatePassageHandler = (e)=>{
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({
                'quesParagraph':quesProcessingData.quesParagraph,
                'quesParagraphID':quesProcessingData.quesParagraphID,
                'userID':userData.userRefID
            })
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.updatePassage, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setQuesProcessingData( (prev)=>{
                        return {...prev, quesParagraph : '',quesParagraphID:''}
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                    setEditPassagePopupState(false);
                    getPassages(e);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }

 

    return (
        <div>
            <Header/>
            
            <div className={managQueCss.maincontainer}>
                <div className={managQueCss.addQueBar}>
                    <div className={managQueCss.buttonDiv}>  
                        
                        { ( userData.userRole.includes('add') || userData.userRole.includes('all') ) && 
                            <button 
                                className={`${managQueCss.addbtn} `+ ( quesProcessingData.mode=='add' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                onClick={ 
                                    (e)=>{ e.target.name='mode'; e.target.value='add'; quesProcessingDataHandler(e); }
                                }
                            >
                                <AddCircleOutlineIcon/> 
                                Add Question
                            </button>
                        }

                        { ( userData.userRole.includes('view') || userData.userRole.includes('all') ) && 
                            <button 
                                className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='view' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                onClick={ 
                                    (e)=>{ e.target.name='mode'; e.target.value='view'; quesProcessingDataHandler(e); }
                                }
                            >
                                <ArticleOutlinedIcon/> 
                                My Questions
                            </button>
                        }

                        {/* { ( userData.userRole.includes('generate') || userData.userRole.includes('all') ) && 
                            <>
                                <button 
                                    className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='practice' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                    onClick={ 
                                        (e)=>{ e.target.name='mode'; e.target.value='practice'; quesProcessingDataHandler(e); }
                                    }
                                >
                                    <HistoryEduIcon/> 
                                    Generate Practice Sets
                                </button>
                                <button 
                                    className={`${managQueCss.editQue} `+ ( quesProcessingData.mode=='final' ? `btn btn-success` : `btn btn-primary` ) +` addMore`} 
                                    onClick={ 
                                        (e)=>{ e.target.name='mode'; e.target.value='final'; quesProcessingDataHandler(e); }
                                    }
                                >
                                    <ReceiptLongIcon/> 
                                    Generate Final Exam
                                </button>
                            </>
                        } */}

                    </div>
                    <div className={ `${managQueCss.backbutton}` }>
                      {/* <Link to={ RouteURL.dtpGeneratePracticeSet }><div className={managQueCss.backLinkDash}> Generate Prcatice Set </div></Link> */}
                      
                        {userData.userTypeID==2 &&
                            <Link to="/admin"><div className={managQueCss.backLinkDash}> <HomeIcon/> Back </div></Link>
                        }

                    </div>
                </div>
                
                {quesProcessingData.mode=='view' && 
                    <EditQue
                        userData={userData}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesSegregationButtonHandler={editQuesSegregationButtonHandler}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }


                {quesProcessingData.mode=='practice' &&
                    <GeneratePracticeSet
                        userData={userData}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }


                {quesProcessingData.mode=='final' &&
                    <GenerateFinalExam
                        userData={userData}
                        quesProcessingData={quesProcessingData}
                        questionsList={questionsList}
                        quesProcessingDataHandler={quesProcessingDataHandler}
                        getSubjectChapters={getSubjectChapters}
                        getSubTopicList={getSubTopicList}
                        searchQuestions={searchQuestions}
                        setCurrentPage={setCurrentPage}
                        editQuesButtonHandler={editQuesButtonHandler}
                        deleteQuesButtonHandler={deleteQuesButtonHandler}
                        currentPage={currentPage}
                        isLoaderActive={isLoaderActive}
                        topicList={topicList}
                        subTopicsList={subTopicsList}
                        subjectList={subjectList}
                        subjectChaptersList={subjectChaptersList}
                    />
                }
                
               

                {quesProcessingData.mode=='add' && 
                
                    <>
                        <div className={`${managQueCss.sectionDiv}`}>
                            <label className={`${managQueCss.RowLabel}`} >
                            <input 
                                name='section' 
                                type="radio" 
                                defaultValue={`1`}
                                onChange={ quesProcessingDataHandler }
                            /> Section - A
                            </label>
                            <label className={`${managQueCss.RowLabel}`}>
                            <input 
                                name='section' 
                                type="radio" 
                                defaultValue={`2`}
                                onChange={ quesProcessingDataHandler }
                            /> Section - B
                            </label>
                        </div>
                        <div className={`${managQueCss.selectTopic}`}>
                        {quesProcessingData.section=='1'&&
                            <form method='get' className={`${managQueCss.selectSubject}`}>
                            <Row className='justify-content-start'>
                                <Col className='col-md-3 col-sm-12 col-12'>
                                    <label>Topic</label>
                                    <select 
                                        className={`${managQueCss.selectSubject}`}
                                        required
                                        name='topic'
                                        id='topic'
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                    >
                                    <option className={`d-none`}>Select Topic</option>
                                        {topicList
                                            .map( topic => (
                                                <option 
                                                    value={topic.questionCatID}
                                                    key={"topic"+topic.questionCatID}
                                                >
                                                    {topic.questionCatDesc}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                                <Col className='col-md-3 col-sm-12 col-12'>
                                    <label>Sub-Topic</label>
                                    <select 
                                        className={`${managQueCss.selectSubject}`}
                                        name='subTopic'
                                        id='subTopic'
                                        required
                                        onChange={ quesProcessingDataHandler }
                                    >
                                        <option className={`d-none`}>Select Sub-Topic</option>
                                        {
                                            subTopicsList.map( sTopic => (
                                                <option
                                                    value={sTopic.subTopicID}
                                                    key={'sTopic'+sTopic.subTopicID}
                                                >
                                                    {sTopic.subTopicDesc}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                            </Row>
                        </form>
                            
                        }
                        {quesProcessingData.section=='2'&&
                             <form method='get' className={`${managQueCss.selectSubject}`}>
                             <Row className='justify-content-start'>
                             <Col className='col-md-3 col-sm-12 col-12'>
                                <label>Subject</label>
                                <select 
                                    required 
                                    className={`${managQueCss.selectSubject}`}
                                    name='subject'
                                    id='subject'
                                    onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                >
                                    <option className={`d-none`}>Select Subject</option>
                                    {
                                        subjectList.map( subject => (
                                            <option
                                                value={subject.subjectID}
                                                key={subject.subjectAbbreviation}
                                            >
                                                {subject.subjectName}
                                            </option>
                                        ) )
                                    }
                                 </select>
                             </Col>
                             <Col className='col-md-3 col-sm-12 col-12'>
                                <label>Chapter</label>
                                <select 
                                    required 
                                    className={`${managQueCss.selectSubject}`}
                                    name='chapter'
                                    id='chapter'
                                    onChange={ quesProcessingDataHandler }
                                >
                                    <option className={`d-none`}>Select Chapter</option>
                                    {
                                        subjectChaptersList.map( chapter => (
                                            <option
                                                value={chapter.chapterID}
                                                key={'chp'+chapter.chapterID}
                                            >
                                                {chapter.chapterName}
                                            </option>
                                        ) )
                                    }
                                 </select>
                             </Col>
         
                             {/* { quesProcessingData.chapter!='' && <Col className={`col-md-3 col-sm-12 col-12, ${managQueCss.nextBtn}`} >
                                 <button className={`${managQueCss.buttonNext} btn btn-primary mt-3`} type='button' onClick={ (e)=>{ e.target.name='isFormOpened'; e.target.value=true; quesProcessingDataHandler(e) } }>Next</button>
                             </Col>} */}
         
                             </Row>
                             </form>
                        }
                        </div> 
                        
                        <div className={`${managQueCss.addQueFormContainer}`}>

                            { isLoaderActive && <div className={managQueCss.loaderClass}>
                                <div className="spinner-border text-primary" role="status"></div>
                            </div> }



                            { (quesProcessingData.subTopic!='' || quesProcessingData.chapter!='') &&
                                <div className={`${managQueCss.passageTypeBtn}`}>
                                    <Button 
                                        variant={ quesProcessingData.isWithPassage=='true' ? "success addMore2" : "outline-success addMore2" } 
                                        onClick={ 
                                            (e) => { e.target.name='isWithPassage'; e.target.value=true; quesProcessingDataHandler(e); }
                                        }
                                    >
                                        Passage
                                    </Button>
                                    <Button 
                                        variant={ quesProcessingData.isWithPassage=='false' ? "success" : "outline-success" } 
                                        onClick={ 
                                            (e) => { e.target.name='isWithPassage'; e.target.value=false; quesProcessingDataHandler(e); }
                                        }
                                    > 
                                        With Out Passage
                                    </Button>
                                </div>
                            }




                            {/* passage based section - start */}
                            { quesProcessingData.isFormOpened && quesProcessingData.isWithPassage=='true' &&
                                <div>

                                    <div className={`${managQueCss.queTextBox}`}>
                                        <div className={`${managQueCss.lableText}`}><p>New Passage Text</p></div>
                                        <div className={`${managQueCss.ckEditorBox}`}>
                                            <CKEditor 
                                                data={quesProcessingData.quesParagraph}
                                                editor={ClassicEditor}
                                                config={ckEditorConfiguration}
                                                onReady={(editor) => {
                                                    // console.log('init')
                                                }}
                                                onChange={ (e, editor) => {
                                                    ckeditorHandler( 'quesParagraph', editor.getData() );
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.popupSavBtn}`}>
                                        <button 
                                            className='btn btn-success my-4' 
                                            onClick={()=>{savePassage()}}
                                        >
                                            Save Passage
                                        </button>
                                    </div>

                                    <div className={`${managQueCss.passageS}`}>
                                        <table className={`${managQueCss.passageTable}`}>
                                            <thead>
                                                <tr>
                                                    <th className={`${managQueCss.passageId}`}>Passage ID</th>
                                                    {/* <th className={`${managQueCss.srNo}`}>Sr. No.</th> */}
                                                    <th>Passage</th>
                                                    <th className={`${managQueCss.numQue}`}>Number of Question</th>
                                                    <th className={`${managQueCss.addQBtn}`}>Add</th>
                                                    { ( userData.userRole.includes('edit') || userData.userRole.includes('all') ) &&
                                                        <th className={`${managQueCss.addQBtn}`}>Edit</th>
                                                    }
                                                    { ( userData.userRole.includes('delete') || userData.userRole.includes('all') ) &&
                                                        <th className={`${managQueCss.addQBtn}`}>Delete</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {passageList.map((item, index)=>{
                                                    return(
                                                        <tr key={"passage"+index}>
                                                            <td>{index+1}</td>
                                                            {/* <td >1.</td> */}
                                                            <td dangerouslySetInnerHTML={{__html:item.paragraphText}}></td>
                                                            <td className={`${managQueCss.numQue}`}>{item.questionCount}</td>
                                                            <td>
                                                                <button 
                                                                    className='btn btn-primary' 
                                                                    onClick={
                                                                        ()=>{
                                                                            addNewPassageQuesHandler(true,item.paragraphID)
                                                                        }
                                                                    }
                                                                >
                                                                    Add Ques.
                                                                </button>
                                                            </td>
                                                            { ( userData.userRole.includes('edit') || userData.userRole.includes('all') ) &&
                                                                <td>
                                                                    <button
                                                                        className='btn btn-primary' 
                                                                        onClick={
                                                                            ()=>{
                                                                                editPassageHandler(true,item.paragraphID,item.paragraphText)
                                                                            }
                                                                        }
                                                                    >
                                                                        Edit Passage
                                                                    </button>
                                                                </td>
                                                            }
                                                            { ( userData.userRole.includes('delete') || userData.userRole.includes('all') ) &&
                                                                <td>
                                                                    <button 
                                                                        className='btn btn-secondary' 
                                                                        onClick={
                                                                            ()=>{
                                                                                deletePassageButtonHandler(item.paragraphID)
                                                                            }
                                                                        }
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )

                                                })}
                                            </tbody>


                                        
                                        </table>
                                    </div>

                                    
                                </div>
                            }
                            {/* passage based section - end */}




                            {/* non-passage based section - start */}
                            { quesProcessingData.isFormOpened && quesProcessingData.isWithPassage!='true' &&
                                <div className={`${managQueCss.addQueForm}`}>                                                
                                                            
                                    
                                    <div className={`${managQueCss.queTextBox}`}>
                                        <div className={`${managQueCss.lableText}`}><p>Question Heading</p></div>
                                        <div className={`${managQueCss.ckEditorBox}`}>
                                            
                                            <CKEditor
                                                data={quesProcessingData.quesHeading}
                                                editor={ClassicEditor}
                                                config={ckEditorConfiguration}
                                                onReady={(editor) => {
                                                    ckInitHandler(true);
                                                    // console.log('init')
                                                }}
                                                onChange={ (e, editor) => {
                                                    ckeditorHandler( 'quesHeading', editor.getData() );
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.optionContainer}`}>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(A)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionA}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionA', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(B)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionB}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionB', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(C)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionC}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionC', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className={`${managQueCss.optionTextBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>(D)</p></div>
                                            <div className={`${managQueCss.ckEditorBox}`}>
                                                <CKEditor
                                                    data={quesProcessingData.quesOptionD}
                                                    editor={ClassicEditor}
                                                    config={ckEditorConfiguration}
                                                    onReady={(editor) => {
                                                        // console.log('init')
                                                    }}
                                                    onChange={ (e, editor) => {
                                                        ckeditorHandler( 'quesOptionD', editor.getData() );
                                                    } }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.queTextBox}`}>

                                        <div className={`${managQueCss.explnBox}`}>
                                            <div className={`${managQueCss.lableText}`}><p>Explanation</p></div>
                                            <div className={`${managQueCss.CorrectAns}`}>
                                                <select 
                                                    className={`${managQueCss.optBtn}`}
                                                    value={ quesProcessingData.quesRightOption }
                                                    onChange={ (e)=> { e.target.name='quesRightOption'; quesProcessingDataHandler(e); } }
                                                >
                                                    <option value="0" className={`${managQueCss.selectOpt}`}>Correct Answer</option>
                                                    <option value="1">A</option>
                                                    <option value="2">B</option>
                                                    <option value="3">C</option>
                                                    <option value="4">D</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className={`${managQueCss.ckEditorBox}`}>
                                            <CKEditor
                                                data={quesProcessingData.quesExplanation}
                                                editor={ClassicEditor}
                                                config={ckEditorConfiguration}
                                                onReady={(editor) => {
                                                    // console.log('init')
                                                }}
                                                onChange={ (e, editor) => {
                                                    ckeditorHandler( 'quesExplanation', editor.getData() );
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className={`${managQueCss.submitBtn}`}>
                                        <button 
                                            className={`${managQueCss.saveQue}, btn btn-primary`}
                                            onClick={ addNewQuesHandler }
                                        >
                                            Save Question
                                        </button>
                                    </div>
                                </div>
                            }
                            {/* non-passage based section - end */}
                        </div>
                    </>

                
                }

            </div>


            {passagePopup &&
                <AddpassageQue  quesProcessingData={quesProcessingData} addNewQuesHandler={addNewQuesHandler} quesProcessingDataHandler={quesProcessingDataHandler} ckeditorHandler={ckeditorHandler} setPassagePopup={setPassagePopup}/>
            }

            {editQuestionPopup &&
                <EditQuestionPopup  quesProcessingData={quesProcessingData} updateQuesHandler={updateQuesHandler} quesProcessingDataHandler={quesProcessingDataHandler} ckeditorHandler={ckeditorHandler} editQuesClosePopupHandler={editQuesClosePopupHandler}/>
            }

            {editPassagePopupState &&
                <EditPassagePopupPage isLoaderActive={isLoaderActive} quesProcessingData={quesProcessingData} updatePassageHandler={updatePassageHandler} ckeditorHandler={ckeditorHandler} editPassageClosePopupHandler={editPassageClosePopupHandler}/>
            }
            

            <Modal 
                className='modelMsg'
                show={quesSegrigationModalShow.status}
                onHide={ ()=>{ editQuesSegregationModalCloseHandler(false) } }
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ques. Bank Segrigation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { isPopupLoaderActive && <div className={managQueCss.loaderClass}>
                        <div className="spinner-border text-primary" role="status"></div>
                    </div> }

                    <div className='mb-3'>
                        <label htmlFor=''></label>
                        <select 
                            required 
                            className={`form-control form-control-lg`}
                            name='questionBankType'
                            id='questionBankType'
                            value={quesSegrigationModalShow.qbTypeID}
                            onChange={ qbTypeChangeHandler }
                        >
                            <option className={`d-none`}>Select Question Bank Type</option>
                            {
                                questionBankTypeList.map( qbType => (
                                    <option
                                        value={qbType.questionTypeID}
                                        key={'qbt'+qbType.questionTypeID}
                                    >
                                        {qbType.questionTypeDesc}
                                    </option>
                                ) )
                            }
                        </select>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={ ()=>{ editQuesSegregationModalCloseHandler(false) } }
                    >Close</Button>
                    <Button 
                        variant="primary"
                        onClick={updateQuestionTypeIDHandler}
                    >Save changes</Button>
                </Modal.Footer>
            </Modal>




            <Modal 
                className='modelMsg'
                show={modalShow.status}
                onHide={modalCloseHandler}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className='p-0'>
                    <Alert className='mb-0' variant={modalShow.messageClass} onClick={modalCloseHandler} dismissible>
                        <p className='p-0 m-0'>{modalShow.message}</p>
                    </Alert>
                </Modal.Body>
            </Modal>
            
            
        </div>
    )
}

export default ManageQue;
