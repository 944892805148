import React,{useEffect,useState} from 'react'
import RegisterCss from './Register.module.css'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import {apiBaseURL,apiURL,apiKey} from './../../constant';

export default function Register() {
    const [show, setShow] = useState(false);
    const [isLoaderActive, setIsLoaderActive] = useState(true);
    const [isSignupDone, setIsSignupDone] = useState(false);
    const [isError, setIsError] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [signupFormData, setSignupFormData] = useState( { 
        values:{ name: "",fatherName:"",address:"",stateID:"",pincode:"",contactNo:"",email:"",examSectionA:true,examSectionB:false,amountExamSectionA:1000,amountExamSectionB:0,totalAmount:1000 },
        subjectSelection:{}
    } );
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)
    // const handleCloseSetUncheck = () => setSignupFormData({
    //     values : { ...signupFormData.values, 'examSectionB' : false } 
    // });

    useEffect( ()=>{
        const fetchOptions = {
            method: 'GET',
            headers:{ 'key': apiKey }
        };

        fetch(apiBaseURL+apiURL.getStateList,fetchOptions )
            .then(response => response.json())
            .then( 
                (result)=>{
                    if(result.status=='success'){
                        setIsLoaderActive(false);
                        setStateList( result.data );
                    }else{
                        setIsError(true);
                        console.log(apiURL.getStateList+" API Error : "+result.message);
                    }
                }
            )
            .catch(
                (error)=>{
                    console.log(error);
                }
            );

        fetch(apiBaseURL+apiURL.getSubjectList,fetchOptions )
            .then(response => response.json())
            .then( 
                (result)=>{
                    if(result.status=='success'){
                        setSubjectList( result.data.subjects );
                    }else{
                        console.log(apiURL.getSubjectList+" API Error : "+result.message);
                    }
                }
            )
            .catch(
                (error)=>{
                    console.log(error);
                }
            );

    },[] );



    const signupHandleOnChange = e => setSignupFormData( (prev)=>{
        return {...prev, values:{...prev.values,[e.target.name] : e.target.value} }
    });
    const signupHandleOnCheck = e => setSignupFormData( (prev)=>{
        if( e.target.name=='examSectionB' && e.target.checked==false ){
            return {...prev, 
                values:{...prev.values,[e.target.name] : e.target.checked, amountExamSectionB : 0 ,totalAmount: prev.values.amountExamSectionA },
                subjectSelection:{}
            }
        }else{
            return {...prev, values:{...prev.values,[e.target.name] : e.target.checked} }
        }
    });
    const handleCloseSetUncheck = e => setSignupFormData( (prev)=>{
        return {...prev, 
            values:{...prev.values, examSectionB : false, amountExamSectionB : 0 ,totalAmount: prev.values.amountExamSectionA }
        }
    });
    const signupHandleOnSubjectCheck = e => setSignupFormData((prev)=>{

        let amountExamSectionA = prev.values.amountExamSectionA;
        let amountExamSectionB = prev.values.amountExamSectionB;
        let subjectPrice = e.target.getAttribute('data-subjectprice');
        let subjectID = e.target.getAttribute('data-subjectid');

        if(e.target.checked){
            amountExamSectionB = parseInt(amountExamSectionB) + parseInt(subjectPrice);
        }else{
            amountExamSectionB = parseInt(amountExamSectionB) - parseInt(subjectPrice);
        }

        return {...prev, 
            values:{...prev.values,amountExamSectionB : amountExamSectionB, totalAmount: (amountExamSectionA + amountExamSectionB) },
            subjectSelection:{...prev.subjectSelection, [subjectID] : e.target.checked}
        }

    });

    function signup(e){
        e.preventDefault();
        // console.log(signupFormData);

        const postData = new FormData;
        postData.append('signupFormData',signupFormData)

        fetch(apiBaseURL+apiURL.signup, {
            method: 'POST',
            headers:{ 'key': apiKey },
            body: postData
        } )
            .then(response => response.json())
            .then( (result)=>{
                setIsLoaderActive(false);

                // console.log(result);

                if( result.status=='success' ){
                    alert("Registration Successful");
                    setIsSignupDone(true);
                }else{
                    alert("Registration Failed. Please retry");
                }
            } )
            .catch( (err)=>{
                alert("Registration Failed. Please retry");
            } );

    };
    
    
    
    return (
        <div>
            <div className={`${RegisterCss.container}`}>
                <div className={`${RegisterCss.mainDiv}`}>
                    <div className={`${RegisterCss.regImgBox}`}></div>
                    
                    <div className={`${RegisterCss.regformBox}`}>

                        { isLoaderActive && <div className={RegisterCss.isLoader}>
                            <div className="spinner-border text-primary" role="status"></div>
                        </div> }

                        { isSignupDone && <div className={RegisterCss.signupSuccess}>
                            <h1>Registration Successful</h1>
                            <p>Thank you for registring with us. Please check your email inbox to get your login credentials.</p>
                        </div>
                        }

                        { !isLoaderActive && !isSignupDone && <>
                            <div className={`${RegisterCss.head}`}>
                                <h1>Registration Form</h1>
                            </div>
                            <form onSubmit={signup}>

                                <label className={`${RegisterCss.lableHead}`}>Name</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`} 
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={signupFormData.values.name}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter your full name'
                                        autoComplete='off'
                                        required 
                                    />
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>Father's Name</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`}  
                                        type="text"
                                        name='fatherName' 
                                        id="fatherName"
                                        value={signupFormData.values.fatherName}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter father name'
                                        autoComplete='off'
                                    />
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>Address</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    {/* <input className={`${RegisterCss.inputText}`} autoComplete='off' type="text" required name='User name' placeholder='Enter your address' /> */}
                                    <input 
                                        className={`${RegisterCss.inputText}`}  
                                        type="text"
                                        name='address' 
                                        id="address"
                                        value={signupFormData.values.address}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter Your Address'
                                        autoComplete='off'
                                        required
                                    />
                                </div>

                                <div className={`${RegisterCss.inputRow}`}>
                                    <div className={`${RegisterCss.AddState}`}>
                                        <label className={`${RegisterCss.lableHead}`}>State</label>

                                        <div className={`${RegisterCss.inputBox}`}>
                                            <select 
                                                className={`${RegisterCss.selectcombobox}`} 
                                                name="stateID"
                                                id="stateID"
                                                value={signupFormData.values.stateID}
                                                onChange={signupHandleOnChange}
                                                autoComplete='off'
                                                required    
                                            >
                                                <option value="">Select State</option>
                                                {stateList
                                                    .map( 
                                                        stateDetail => ( <option key={stateDetail.stateID} value={stateDetail.stateID}>{stateDetail.stateNameLang1}</option> ) 
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className={`${RegisterCss.pinCode}`}>
                                            <label className={`${RegisterCss.lableHead}`}>PIN Code</label>
                                            <div className={`${RegisterCss.inputBox}`}>
                                                <input 
                                                    className={`${RegisterCss.inputText}`} 
                                                    type="number" 
                                                    name='pincode' 
                                                    id='pincode'
                                                    min={100000}
                                                    max={999999}
                                                    value={signupFormData.values.pincode} 
                                                    onChange={signupHandleOnChange}
                                                    placeholder='Enter PIN code'
                                                    autoComplete='off' 
                                                    required 
                                                />
                                            </div>
                                    </div>
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>Contact</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`}  
                                        type="number" 
                                        name='contactNo' 
                                        id='contactNo' 
                                        value={signupFormData.values.contactNo}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter your contact no.'
                                        autoComplete='off'
                                        required 
                                    />
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>E-mail id</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`} 
                                        type="email" 
                                        name='email' 
                                        id='email'
                                        value={signupFormData.values.email}
                                        onChange={signupHandleOnChange} 
                                        placeholder='Enter your E-mail id'
                                        autoComplete='off' 
                                        required 
                                    />
                                </div>

                                <div className={`${RegisterCss.inputRow}`}>
                                    <div className={`${RegisterCss.addsub}`}>
                                        <label className={`${RegisterCss.lableHead}`}>Subject(s) Taken</label>
                                            <div className={`${RegisterCss.rowSection}`}>

                                            <label htmlFor='examSectionA' className={`${RegisterCss.containerSe}`}>Section  - A
                                                <input
                                                    type="checkbox"
                                                    name='examSectionA'
                                                    id='examSectionA'
                                                    defaultValue={true}
                                                    // value={true}
                                                    onChange={ (e)=>{ e.target.checked=true;signupHandleOnCheck(e);} }
                                                    checked={signupFormData.values.examSectionA}
                                                />
                                            <span className={`${RegisterCss.checkmark}`}></span>
                                            </label>

                                            {/* <label onClick={handleShow} className={`${RegisterCss.containerSe}`}>Section  - B */}
                                            <label htmlFor='examSectionB' className={`${RegisterCss.containerSe}`}>Section  - B
                                                <input 
                                                    type="checkbox"
                                                    name='examSectionB'
                                                    id='examSectionB'
                                                    defaultValue={false}
                                                    onChange={ (e)=>{ e.target.checked==true ? handleShow(e) : handleClose(e);signupHandleOnCheck(e);} }
                                                    checked={signupFormData.values.examSectionB}
                                                />
                                            <span className={`${RegisterCss.checkmark}`}></span>
                                            </label>
                                            </div>
                                    </div>
                                        <Modal show={show} onHide={ (e)=>{handleClose(e);handleCloseSetUncheck(e) } } backdrop="static" keyboard={false}>
                                            <Modal.Header className={`${RegisterCss.headerSltsubject}`} closeButton>
                                                <Modal.Title>Select Subject</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className={`${RegisterCss.SubjectList}`}>
                                                
                                                {subjectList
                                                    .map( 
                                                        subjectDetail => ( 
                                                            <label className={`${RegisterCss.containerSe}`}>
                                                                <input
                                                                    type="checkbox" 
                                                                    name={"sectionBsub"+subjectDetail.subjectID} 
                                                                    id={"sectionBsub"+subjectDetail.subjectID}
                                                                    data-subjectid={subjectDetail.subjectID}
                                                                    data-subjectprice={subjectDetail.subjectPrice}
                                                                    key={subjectDetail.subjectAbbreviation}
                                                                    defaultValue={false}
                                                                    onChange={ (e)=>{signupHandleOnSubjectCheck(e);} }
                                                                />
                                                                <span className={`${RegisterCss.checkmark}`}></span>{subjectDetail.subjectName} 
                                                            </label>
                                                                // <option 
                                                                //     key={stateDetail.stateID} 
                                                                //     value={stateDetail.stateID}>
                                                                //         {stateDetail.stateNameLang1}
                                                                // </option>
                                                        ) 
                                                    )
                                                }
                                            
                                            </Modal.Body>

                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Ok
                                                </Button>
                                            </Modal.Footer>
                                        
                                        </Modal>

                                    <div >
                                        <label className={`${RegisterCss.lableHead}`}>Amount</label>
                                        <div className={`${RegisterCss.subjRow}`}>
                                            <div className={`${RegisterCss.rsSymbol}`}>
                                                <CurrencyRupeeIcon className={`${RegisterCss.rupIcon}`}/>
                                            </div>
                                            <div className={`${RegisterCss.inputBox}`}>
                                                <input 
                                                    className={`${RegisterCss.inputText}`} 
                                                    type="text" 
                                                    name='totalAmount'
                                                    id='totalAmount'
                                                    key='totalAmount'
                                                    value={signupFormData.values.totalAmount}
                                                    placeholder='amount'
                                                    autoComplete='off' 
                                                    readOnly 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={`${RegisterCss.btnRow}`}>
                                        <div className={`${RegisterCss.btnSetting}`}>
                                            <button type="submit" className={`${RegisterCss.logButton}`}>Pay & Register</button>
                                        </div>
                                        <div className={`${RegisterCss.btnSetting}`}>
                                            <button type="reset" className={`${RegisterCss.logButton}`}>Reset</button>
                                        </div>
                                    </div>

                            </form>
                        </> }
                        
                    </div>

                </div>
            </div>
            <div className={`${RegisterCss.area}`} >
                <ul className={`${RegisterCss.circles}`}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
            </div >
        </div>
        
    )
}
