import {useContext} from 'react'
import StuDashBoardCss from './stuDashboard.module.css';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import swaLogo from '../../assets/logo.png';
import { Link } from  "react-router-dom";
import userContext from '../../Store';

function Header() {
  const {userData, logout} = useContext(userContext);


  return (
    <div className={`${StuDashBoardCss.backDiv}`}>
       {/* Header Section */}
      <div className={`${StuDashBoardCss.HeaderTop}`}>
          <div className={`${StuDashBoardCss.logoCol}`}>
             <div className={`${StuDashBoardCss.logoDiv}`}>
                <img className={`${StuDashBoardCss.logoimgs}`} src={swaLogo}/>
             </div>
          </div>
          <div className={`${StuDashBoardCss.UserCol}`}>
              <div className={`${StuDashBoardCss.rightcolm}`}>
                <Dropdown>
                  Welcome : {userData.firstName}
                  <Dropdown.Toggle className={`${StuDashBoardCss.candidateButton}`}>
                  <AccountCircleIcon className={`${StuDashBoardCss.user}`}/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="light">
                  <Dropdown.Item><PersonIcon/> <Link to="/student/profile"> My Profile </Link></Dropdown.Item>
                  <Dropdown.Item onClick={logout}><ExitToAppTwoToneIcon/>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                  </Dropdown>
              </div>
          </div>
          <div className={`${StuDashBoardCss.clear}`}></div>
      </div>

    
     {/* Right Area */}
    </div>
  );
}

export default Header;
