import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import PracticeSet from '../../assets/practice_set.png';
import { Container, Row, Col } from 'react-bootstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import {apiBaseURL,apiURL,apiKey} from './../../constant';
import Alert from '@mui/material/Alert';

function StudentDash() {

  const[showTopicList, setTopicList]=useState([]);
  const[showSubjectList, setSubjectList]=useState([]);
  const[showChapters, setChapters]=useState([]);
  const[showSubTopic, setSubTopic]=useState([]);

  
useEffect(()=>{
  getSubject();
  getTopic();
},[])

  // Section state
  const[showSectionA, setSectionA] =useState(true);
  const[showSectionB, setSectionB] =useState(false);
  // Section state

  // topic/chapter wise state
  const[showTopiChapter, setTopiChapter]=useState(false);
  const[showSectionWise, setSectionWise]=useState(false);
  const[showDropIconTop, setDropIconTop]=useState(false);
  const[showDropIconSec, setDropIconSec]=useState(false);
  const[showTopicSecA, setTopicSecA]=useState(false)
  const[showSmsSecATopic, setSmsSecATopic]=useState(false);
  const[showSectionTopicB, setSectionTopicB]=useState(false);
  const[showSmsSectionBSubject, setSmsSectionBSubject]=useState(false);
  // topic/chapter wise state

  // section wise
  const[showSecWise_A, setSecWise_A]=useState(false);
  const[showSecWise_B, setSecWise_B]=useState(false);

  // section wise

  // get subjects function
   function getSubject(){
    fetch(apiBaseURL+apiURL.getSubject,{
      method : 'GET',
      headers : {'Key' : apiKey}
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status=="success"){
          setSubjectList(data.data.subjects)
        }else{alert(data.message)}
      })
    })
    .catch((err)=>{
      alert("err");
    })
   }
  // get subjects function


// get topic functions start
 function getTopic(){
    fetch(apiBaseURL+apiURL.getTopics,{
      method: 'GET',
      headers: {'Key' : apiKey}
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status=="success"){
          
          setTopicList(data.data)
        }else{alert(data.message)}
      })
    })
    .catch((err)=>{
      alert(err);
    })
 }
// get topic functions start

// get SubTopic functions start
 function getSubTopics(questionCatID){
   fetch(apiBaseURL+apiURL.getSubTopics,{
     method: 'POST',
     headers: {'Key' : apiKey},
     body: JSON.stringify({"topicID" : questionCatID})
   })
   .then((res)=>{
     res.json()
     .then((data)=>{
       if(data.status=="success"){
        setSubTopic(data.data.subTopics)
       }else{alert(data.message)}
     })
   })
   .catch((data)=>{
     alert(data.message);
   })
 }
// get SubTopic functions end


// get chapter functions start
 function getChapters(subjectId){
    fetch(apiBaseURL+apiURL.getChapterList,{
      method: 'POST',
      headers: {'Key' : apiKey},
      body: JSON.stringify({"classID":12, "subjectID":subjectId})
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status=="success"){
          setChapters(data.data.chapters)
        }else{alert(data.message)}
      })
    })
    .catch((err)=>{
      alert(err);
    })
 }
// get chapter functions end



  
  return (

   <div className={`${StuDashBoardCss.Backbody}`}>
      <Header/>
      <LeftMenu/>
     
      <div className={`${StuDashBoardCss.backdiv}`}>
             <div className={`${StuDashBoardCss.headingPractice}`}>Self-Generated Assessment</div>
             <Container fluid>
             <Row>
            <Col className="col-md-6 col-12 col-sm-12">
              <div onClick={()=>{setTopiChapter(true); {setSecWise_A(false)}; {setSecWise_B(false)}; {setSectionWise(false)} {setDropIconTop(true); {setDropIconSec(false)} }}} className={`${StuDashBoardCss.learnBtn}`}>
                <div className={`${StuDashBoardCss.logoCircle}`}>
                  <img src={PracticeSet} />
                </div>
                <div className={`${StuDashBoardCss.headingDiv}`} style={{backgroundColor:showDropIconTop&&"#a37af4"}}>
                  SECTION WISE 
                </div>
                <div className={`${StuDashBoardCss.timeLimetButton}`}>without time limit</div>
                {showDropIconTop &&
                  <div> <ArrowDropDownIcon className={`${StuDashBoardCss.dropArrow}`}/> </div>
                }

              </div>
            </Col>
            <Col className="col-md-6 col-12 col-sm-12">
              <div onClick={()=>{setSectionWise(true); {setSectionTopicB(false)} {setTopicSecA(false)}; {setTopiChapter(false)} {setDropIconTop(false); {setDropIconSec(true); } }}} className={`${StuDashBoardCss.learnBtn}`}>
                <div className={`${StuDashBoardCss.logoCircle}`}>
                  <img src={PracticeSet} />
                </div>
                <div className={`${StuDashBoardCss.headingDiv}`} style={{background:showDropIconSec&&"#a37af4"}}>
                SECTION WISE
                </div>
                <div className={`${StuDashBoardCss.timeLimetButton}`}>with time limit</div>
                {showDropIconSec &&
                <div><ArrowDropDownIcon className={`${StuDashBoardCss.dropArrow}`}/></div>
                }
              </div>
            </Col>
          </Row>



          {/* showTopic/Chapter wise start*/}
           {showTopiChapter &&
           <>
           
             <div className={`${StuDashBoardCss.showDIV}`}>
                <div className={`${StuDashBoardCss.SectionBk}`}>
                <div onClick={()=>{setTopicSecA(true); {setSectionTopicB(false)}}} className={`${StuDashBoardCss.buttonSection}`} style={{background:showTopicSecA&&"#bdbaba"}}>
                  Section - A
                </div>
                <div onClick={()=>{setSectionTopicB(true); {setTopicSecA(false)}}} className={`${StuDashBoardCss.buttonSection}`} style={{background:showSectionTopicB&&"#bdbaba"}}>
                  Section - B
                </div>
              </div>
             </div>
             </>
           }

            {/* Section - A Topic wise start */}
              {showTopicSecA &&
              <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active"> SECTION WISE WITHOUT TIME LIMIT</li>
                <li className="breadcrumb-item " aria-current="page">Section - A </li>
              </ol>
            </nav>
            <div className={`${StuDashBoardCss.sectionWiseContainer}`}>
             <div className={`${StuDashBoardCss.showSms}`}>
                             <Alert severity="success"> Assessment Successfully Generated </Alert>
              </div>
           </div>
               
              {showSmsSecATopic &&
              <div className={`${StuDashBoardCss.showSms}`}>
                <Alert severity="success"> Assessment Successfully Generated </Alert>
              </div>
              }
               </>
              }
            {/* Section - A Topic wise start */}



            {/* Section - B topic start*/}
            {showSectionTopicB && 
            <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">SECTION WISE WITHOUT TIME LIMIT</li>
                <li className="breadcrumb-item " aria-current="page">Section - B</li>
              </ol>
            </nav>
            
            <form method='get' className={`${StuDashBoardCss.TopicWisePractice} ${StuDashBoardCss.addClassForbg}`}>
               
               <Row className='justify-content-start'>
                <Col className='col-md-8 col-sm-8 col-12'>
                  <label>Subject</label>
                   <select required className={`${StuDashBoardCss.selectSubject}`}>
                    <option className={`d-none`}>Select</option>
                    {showSubjectList.map((item)=>{
                      return(
                        <option>{item.subjectName}</option>
                      )
                    })}
                   </select>
                </Col>
                            
                <Col className='col-md-4 col-sm-4 col-12'>
                  <button onClick={()=>{setSmsSectionBSubject(true)}} className={`${StuDashBoardCss.buttonNext}`} type='button'>Generate</button>
                </Col>
                
               </Row>
               </form>
               {showSmsSectionBSubject &&
              <div className={`${StuDashBoardCss.showSms}`}>
                <Alert severity="success">Assessment Successfully Generated </Alert>
              </div>
              }
               </>
               }
            {/* Section - B topic end */}

          
           {/* showTopic/Chapter wise end */}

          {/* sectionWise wise start*/}
          {showSectionWise &&
            <div className={`${StuDashBoardCss.showDIV}`}>
            <div className={`${StuDashBoardCss.SectionBk}`}>
            <div onClick={(()=>{setSecWise_A(true); {setSecWise_B(false)}})} className={`${StuDashBoardCss.buttonSection}`} style={{background:showSecWise_A&& "#bdbaba"}}>
              Section - A
            </div>
            <div onClick={(()=>{setSecWise_B(true); {setSecWise_A(false)}})} className={`${StuDashBoardCss.buttonSection}`} style={{background:showSecWise_B&& "#bdbaba"}}>
              Section - B
            </div>
          </div>
         </div>
          }

          {/* Section - A start */}
          {showSecWise_A && 
           <div className={`${StuDashBoardCss.sectionWiseContainer}`}>
             <div className={`${StuDashBoardCss.showSms}`}>
             <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">SECTION WISE WITH TIME LIMIT</li>
                <li className="breadcrumb-item " aria-current="page">Section - A </li>
              </ol>
            </nav>
                <Alert severity="success"> Assessment Successfully Generated </Alert>
              </div>
           </div>
          }
          {/* Section - A end */}

          {/* Section - Bstart */}
          {showSecWise_B && 
           <div className={`${StuDashBoardCss.sectionWiseContainer}`}>
             <div className={`${StuDashBoardCss.showSms}`}>
             <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">SECTION WISE WITH TIME LIMIT</li>
                <li className="breadcrumb-item " aria-current="page">Section - B </li>
              </ol>
            </nav>
                <Alert severity="success"> Assessment Successfully Generated </Alert>
              </div>
           </div>
          }
          {/* Section -B end */}


          {/* sectionWise wise end*/}


             </Container>
        </div>
        
      {/* {sltSectionA  && 
      } */}
       
   
      </div>
  
  );
}

export default StudentDash;
