import { Col, Container, Row } from 'react-bootstrap';
import StuDashBoardCss from './stuDashboard.module.css';
import QuickNotes from '../../assets/quick_notes.png';
import TopicWise from '../../assets/topic_wise_practice.png';
import PracticeSet from '../../assets/practice_set.png';
import selfAssessment from '../../assets/self_asmt.png';
import finalExam from '../../assets/final_exam.png';
import report from '../../assets/report.png';
import RightSideFixedButton from './BySubjectWithButton';
import tagLine from '../../assets/tag.png';

import {Link,Routes,Route } from 'react-router-dom';


import { useState } from 'react';


function StudentDash() {
  // const[isShow, setShow]=useState(false);
  const[notesBox, setNotesBox]=useState(false);
  const[dashboardBtn, setDashboardBtn]=useState("");
  const [show, setShow] = useState(false);

  

  return (

    <div>
    <RightSideFixedButton/> 
  <><div className={`${StuDashBoardCss.backDivButton}`}>
      <Container fluid>
        <Row className='justify-content-around'>
        <Col className='col-md-2 col-sm-4 col-12'>
          <Link to="/student/quickNotes" >
            <div  className={`${StuDashBoardCss.cricleBg}`}>
            <div className={`${StuDashBoardCss.boxWithIcon}`}>
              <img src={QuickNotes}/>
            </div>
              <p className={`${StuDashBoardCss.buttonBottomTxt}`}>Quick Notes</p>
            </div>
            </Link>
          </Col>
          <Col className='col-md-2 col-sm-4 col-12'>
            <Link to="/student/TopicWisePractice" ><div className={`${StuDashBoardCss.cricleBg}`}>
            <div className={`${StuDashBoardCss.boxWithIcon}`}>
              <img src={TopicWise}/>
            </div>
              <p className={`${StuDashBoardCss.buttonBottomTxt}`}>Topic/Chapter Wise Practice</p>
            </div></Link>
          </Col>
          <Col className='col-md-2 col-sm-4 col-12'>
            <Link to="/student/practice-sets"><div className={`${StuDashBoardCss.cricleBg}`}>
            <div className={`${StuDashBoardCss.boxWithIcon}`}>
              <img src={PracticeSet}/>
            </div>
              <p className={`${StuDashBoardCss.buttonBottomTxt}`}>Practice Sets</p>
            </div></Link>
          </Col>
          <Col className='col-md-2 col-sm-4 col-12'>
          <Link to="/student/Self-Generated-Assessment">
            <div className={`${StuDashBoardCss.cricleBg}`}>
            <div className={`${StuDashBoardCss.boxWithIcon}`}>
              <img src={selfAssessment}/>
            </div>
              <p className={`${StuDashBoardCss.buttonBottomTxt}`}>Self-Generated Assessment</p>
            </div>
            </Link>
          </Col>
          <Col  className='col-md-2 col-sm-4 col-12'>
            <div className={`${StuDashBoardCss.cricleBg}`}>
            <div className={`${StuDashBoardCss.boxWithIcon}`}>
              <img src={finalExam}/>
            </div>
              <p className={`${StuDashBoardCss.buttonBottomTxt}`}>Final Exam</p>
            </div>
          </Col>
          {/* <Col onClick={()=>{setDashboardBtn("report")}} className='col-md-2 col-sm-4 col-12'></Col> */}
          <Col  className='col-md-2 col-sm-4 col-12'>
          <Link to="/student/Report">
          <div className={`${StuDashBoardCss.cricleBg}`}>
            <div className={`${StuDashBoardCss.boxWithIcon}`}>
              <img src={report}/>
            </div>
              <p className={`${StuDashBoardCss.buttonBottomTxt}`}>Report</p>
            </div>
            </Link>
          </Col>
        </Row>
      </Container>
      
    </div>
    
    
    {/* tag line */}
    <div className={`${StuDashBoardCss.tagLineImg}`}>
      <img src={tagLine}/>
    </div>
    {/* tag line */}

    
    {/* <Assessment/> */}
    </>
    
  </div> 
  
  );
}

export default StudentDash;
