import StuDashBoardCss from './stuDashboard.module.css';
import {Link,Routes,Route } from 'react-router-dom';
import {useContext} from 'react'
import Header from './header';
import PersonIcon from '@mui/icons-material/Person';
import userContext from '../../Store';
function UserProfile() {
  const {userData} = useContext(userContext);

  

  return (
    <div>
      <Header/>
       <div className={`${StuDashBoardCss.UserProfilecard}`}>
         <div className={`${StuDashBoardCss.UserProfileHeader}`}>
           <div className={`${StuDashBoardCss.userImgs}`}>
             <PersonIcon className={`${StuDashBoardCss.Usericons}`}/>
           </div>

           <div className={`${StuDashBoardCss.userName}`}>Name : {userData.firstName} {userData.lastName}</div>
           <hr/>
           <div className={`${StuDashBoardCss.rowDetailes}`}>
              <div className={`${StuDashBoardCss.FirstColm}`}>Father's Name : {userData.guardianName}</div>
              <div className={`${StuDashBoardCss.FirstColm}`}>Address : {userData.userAddress}</div>
           </div>
           <div className={`${StuDashBoardCss.rowDetailes}`}>
              <div className={`${StuDashBoardCss.FirstColm}`}>State : {userData.stateName}</div>
              <div className={`${StuDashBoardCss.FirstColm}`}>PIN Code : {userData.zipCode}</div>
           </div>
           <div className={`${StuDashBoardCss.rowDetailes}`}>
              <div className={`${StuDashBoardCss.FirstColm}`}>Contact : {userData.userContact}</div>
              <div className={`${StuDashBoardCss.FirstColm}`}>E-mail id : {userData.userEmail}</div>
           </div>
         </div>
       </div>
    </div> 
  );
}

export default UserProfile;
