import React, {useEffect, useState} from 'react';
import StuDashBoardCss from './stuDashboard.module.css';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import PracticeSetIcon from '../../assets/practice.png';


let questionsData = [
  {
    question:"1 In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:",
    quesPart:["Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.", "Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube."],
    Options:[
      "Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration",
      "The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion",
      "Assertion is true but the Reason is false",
      "The statement of the Assertion is false but the Reason is true."
    ]
  },
  {
    question:"2 In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:",
    quesPart:["Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.", "Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube."],
    Options:[
      "Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration",
      "The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion",
      "Assertion is true but the Reason is false",
      "The statement of the Assertion is false but the Reason is true."
    ]
  },
  {
    question:"3 In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:",
    quesPart:["Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.", "Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube."],
    Options:[
      "Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration",
      "The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion",
      "Assertion is true but the Reason is false",
      "The statement of the Assertion is false but the Reason is true."
    ]
  },
  {
    question:"4 In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:",
    quesPart:["Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.", "Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube."],
    Options:[
      "Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration",
      "The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion",
      "Assertion is true but the Reason is false",
      "The statement of the Assertion is false but the Reason is true."
    ]
  },
  
]

function AttemptedMcq() {

  const [questions, setQuestions] = useState([]);
  const [currentQues, setCurrent] = useState({
    quesInd:0,
    questionData:{},
  })

  useEffect(()=>{
    setQuestions(questionsData);
    setCurrent({
      quesInd:0,
      questionData:questionsData[0]
    })

  }, [])


  // next prev functions start
  function prevQues(){
    let index = currentQues.quesInd-1;
    setCurrent({
      quesInd:index,
      questionData:questions[index]
    })
  } 
  function nextQues(){
    let index = currentQues.quesInd+1;
    setCurrent({
      quesInd:index,
      questionData:questions[index]
    })
  }
 // next prev functions end



  if(questions.length==0){
    // console.log(questions)
    return(
      <div>no questions</div>
    )
  }
  // if(questions.length==4){
  //   return(
  //     <div>Data not Found</div>
  //   )
  // }

  return (
   <>
       <div className={`${StuDashBoardCss.MainMcqBk}`}>
          <div className={`${StuDashBoardCss.mainWrappr}`}>
            <div className={`${StuDashBoardCss.HeaderPracticeAss}`}>
           
                  <div className={`${StuDashBoardCss.logoPracticeIcon}`}>
                    <img src={PracticeSetIcon}/>
                  </div>
                  <div className={`${StuDashBoardCss.PracticeText}`}>
                    PRACTICE SET-1
                  </div>
               
            </div>

        <div className={`${StuDashBoardCss.rowGrid}`}>
              <div className={`${StuDashBoardCss.colFixed}`}>
                <div className={`${StuDashBoardCss.QuestionNo}`}>Question - {currentQues.quesInd+1} </div>
              </div>

               <div className={`${StuDashBoardCss.questionDiv}`}>
                <b>
                    {currentQues.questionData.question}
                  </b>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                    {currentQues.questionData.quesPart.map((item)=> <div>{item}</div>
                  )
                }
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                <Row>
                  {currentQues.questionData.Options.map((item, index)=>{
                    return(
                      <Col className='col-md-6 col-sm-12 col-12' key={index}>
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input type="radio" name="options" /> A.
                            </div>
                            <div>
                                {item}
                            </div>

                           </div> 
                        </label>
                    </Col>
                    )
                  })}
                  
                 
                </Row>
                </div>


               
             
               
        </div>

        <div className={`${StuDashBoardCss.QuestionNavigation}`}>
          <div className={`${StuDashBoardCss.innerContainer}`}>
            <div className={`${StuDashBoardCss.navigationInner}`}>
                <div className={`${StuDashBoardCss.ButtonNext_pre}`} onClick={prevQues}>
                  Prev
                </div>

                <div className={`${StuDashBoardCss.QuestionDiv}`}>
                  {questions.map((item, index)=>{
                    return(
                      <div className={`${StuDashBoardCss.qNoCricle}`}>
                        <div className={`${StuDashBoardCss.innerCricle}`}>{index+1}</div>
                      </div>
                    )
                  })}
                       
                   </div>

                <div className={`${StuDashBoardCss.ButtonNext}`} onClick={nextQues}>
                  Next
                </div>
            </div>
        </div>
        <button className={`${StuDashBoardCss.PracticeSubmit}`}>Submit</button>
        </div>
        </div>

      </div>
   </>
  
  );
}

export default AttemptedMcq;
