import React, { useState, useEffect, useContext } from 'react';
import managQueCss from './ManageQue.module.css';
import GeneratePracticeSetCss from './GeneratePracticeSet.module.css';
import Alert from '@mui/material/Alert';
import Button from 'react-bootstrap/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Table from 'react-bootstrap/Table'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import EditQue from './EditQue'
import Pagination from 'react-bootstrap/Pagination'
import WarningIcon from '@mui/icons-material/Warning';
import Header from '../adminDashboard/Header';
import AddpassageQue from './AddPassageQue'
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { apiBaseURL, apiURL, apiKey, RouteURL, ckFinderURL } from '../../constant';
import { EditNotifications } from '@mui/icons-material';
import userContext from '../../Store';

const quesProcessingDataConf = {
    mode: 'add',
    section: '',
    classID: 12,
    topic: '',
    subTopic: '',
    subject: '',
    chapter: '',
    isFormOpened: false,
    isWithPassage: false,
    quesParagraphID: '',
    quesParagraph: '',
    quesHeading : '',
    quesOptionA : '',
    quesOptionB : '',
    quesOptionC : '',
    quesOptionD : '',
    quesRightOption : '0',
    quesExplanation : ''
};
const modalConf = {status:false,messageClass : "info",message:""};

function GeneratePracticeSet() {

    const {userData} = useContext(userContext);

    const loginToken = localStorage.getItem('token');

    const [modalShow, setModalShow] = useState(modalConf);
    const [ckInit,setCkInit] = useState(false);
    const [isLoaderActive,setIsLoaderActive] = useState(true);
    const [topicList, setTopicList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectChaptersList, setSubjectChaptersList] = useState([]);
    const [subTopicsList, setSubTopicsList] = useState([]);
    const [quesProcessingData, setQuesProcessingData] = useState(quesProcessingDataConf);
    const [passageList, setPassageList]=useState([]);
    const [passagePopup, setPassagePopup]=useState(false);
    // const [temp, setTemp] = useState([])

    // function savePassage(){
    //     setPassageList((prev)=>{
    //         let oldData = [...prev];
    //         oldData.push({id:null, data:quesProcessingData.quesParagraph})
    //         return oldData;
    //     })
    //     setQuesProcessingData((prev)=>{
    //         return {...prev, quesParagraph:''}
    //     })
    // }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const modalCloseHandler = e => {
        setModalShow(false);
    }

    
    


    useEffect( ()=>{
        fetch( apiBaseURL+apiURL.getTopics,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setTopicList(result.data);
                }
            } ).catch( (err) => {
                setIsLoaderActive(false);
            } );

        fetch( apiBaseURL+apiURL.getSubjectList,{
            method:'GET',
            headers: { 'key':apiKey }
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setSubjectList(result.data.subjects);
                }
            } ).catch( (err) => {
                setIsLoaderActive(false);
            } );

    },[] );


    const quesProcessingDataHandler = e => setQuesProcessingData((prev)=>{

        if( e.target.name=='mode' && e.target.value!=prev.mode ){
            prev = quesProcessingDataConf;
        }

        if( e.target.name=='section' && e.target.value!=prev.section ){
            prev = {...prev,
                topic: '',
                subTopic: '',
                subject: '',
                chapter: '',
                isFormOpened: false
            }
        }

        if( e.target.name=='topic' ){
            prev = {...prev, subTopic: '', isFormOpened: false}
        }

        if( e.target.name=='subject' ){
            prev = {...prev, chapter: '', isFormOpened: false}
        }

        if( e.target.name=='subTopic' || e.target.name=='chapter' ){
            prev = {...prev, isWithPassage:'', isFormOpened: false}
        }

        if( e.target.name=='isWithPassage' ){
            
            if( e.target.value=='true' ){
                getPassages(e);
            }
            
            if(!ckInit){
                setIsLoaderActive(true);
            }
            prev = {
                ...prev, 
                isFormOpened: true, 
                quesParagraphID: '', 
                quesParagraph:'',
                quesHeading : '', 
                quesOptionA : '',
                quesOptionB : '',
                quesOptionC : '',
                quesOptionD : '',
            }
        }

        return {...prev, [e.target.name] : e.target.value };
    });

    const ckInitHandler = ( stats ) => {
        if(stats){
            setIsLoaderActive(false);
        }
        setCkInit(stats);
    };

    const ckeditorHandler = ( targetName, targetValue ) => {
        setQuesProcessingData( (prev) => {
            return {...prev, [targetName]:targetValue }
        } );
    };


    function getPassages(){
        const chpPostData = {
                            "classID":12,
                            "sectionID":quesProcessingData.section,
                            "topicID":quesProcessingData.topic,
                            "subTopicID":quesProcessingData.subTopic,
                            "subjectID":quesProcessingData.subject,
                            "chapterID":quesProcessingData.chapter
                        };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getPassagesList, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setPassageList( result.data.passages );
                    // console.log( passageList )
                }else{
                    setPassageList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setPassageList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }

    function getSubjectChapters(subjectID){
        const chpPostData = { "classID":12,"subjectID":subjectID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(chpPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getChapterList, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubjectChaptersList(result.data.chapters);
                }else{
                    setSubjectChaptersList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err) => {
                setSubjectChaptersList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }



    function getSubTopicList(topicID){
        const topicPostData = { "topicID":topicID };
        const conf = {
            method:'post',
            headers:{'key':apiKey},
            body:JSON.stringify(topicPostData)
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.getSubTopics, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    setSubTopicsList(result.data.subTopics);
                }else{
                    setSubTopicsList([]);
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err) => {
                setSubTopicsList([]);
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x)=>{
                setIsLoaderActive(false);
            } );
    }


    const addNewPassageQuesHandler = (popupState,passageID) => {
        setQuesProcessingData((prev)=>{
            return {...prev, quesParagraphID: passageID }
        });
        setPassagePopup(popupState)
    }


    const addNewQuesHandler = (e) => {
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({...quesProcessingData,'userID':userData.userRefID})
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.createQuestion, conf )
            .then( response => response.json() )
            .then( (result) => {
                if( result.status=='success' ){
                    // alert( result.message );

                    if( quesProcessingData.quesParagraphID!='' ){
                        getPassages(e);
                    }

                    setQuesProcessingData( (prev)=>{
                        return {...prev, 
                            quesHeading : '',
                            quesOptionA : '',
                            quesOptionB : '',
                            quesOptionC : '',
                            quesOptionD : '',
                            quesRightOption : '0',
                            quesExplanation : ''
                        }
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( 
                (err)=>{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                    } );
                }
            )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }


    const savePassage = (e)=>{
        const conf = {
            method:'post',
            headers:{'key':apiKey,'loginToken':loginToken},
            body:JSON.stringify({
                'classID': quesProcessingData.classID,
                'sectionID': quesProcessingData.section,
                'topicID': quesProcessingData.topic,
                'subTopicID': quesProcessingData.subTopic,
                'subjectID': quesProcessingData.subject,
                'chapterID': quesProcessingData.chapter,
                'quesParagraph':quesProcessingData.quesParagraph,
                'userID':userData.userRefID
            })
        };
        setIsLoaderActive(true);
        fetch( apiBaseURL+apiURL.createPassage, conf )
            .then( response => response.json() )
            .then( (result)=>{
                if( result.status=='success' ){
                    setQuesProcessingData( (prev)=>{
                        return {...prev, quesParagraph : ''}
                    } );
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'success',message:result.message }
                    } );
                    getPassages(e);
                }else{
                    setModalShow( (prev)=>{
                        return {...prev, status:true,messageClass:'danger',message:result.message }
                    } );
                }
            } )
            .catch( (err)=>{
                setModalShow( (prev)=>{
                    return {...prev, status:true,messageClass:'danger',message:"Something went wrong. Please try again." }
                } );
            } )
            .finally( (x) => {
                setIsLoaderActive(false);
            } );
    }

 

    return (
        <div>
            {/* <Header/> */}
            
            <div className={managQueCss.maincontainer}>
                <div className={managQueCss.addQueBar} style={{display: 'none'}}>
                    <div className={managQueCss.buttonDiv}>  
                        <button 
                            className={`${managQueCss.addbtn} btn btn-success addMore`} 
                            onClick={ 
                                (e)=>{ e.target.name='mode'; e.target.value='add'; quesProcessingDataHandler(e); }
                            }
                            >Add Question</button>
                        <button 
                            className={`${managQueCss.editQue} d-none btn btn-success addMore`} 
                            onClick={ 
                                (e)=>{ e.target.name='mode'; e.target.value='view'; quesProcessingDataHandler(e); }
                            }
                        >Edit Question</button>
                    </div>
                    <div className={ `${managQueCss.backbutton}` }>
                      <Link to={ RouteURL.dtpGeneratePracticeSet }><div className={managQueCss.backLinkDash}> Generate Prcatice Set </div></Link>
                      {/* <Link to="/admin"><div className={managQueCss.backLinkDash}> <HomeIcon/> Back </div></Link> */}
                    </div>
                </div>
                {quesProcessingData.mode=='view' && 
                    <>
                        <EditQue/>
                    </>
                }
                
               

                {quesProcessingData.mode=='add' && 
                
                    <>
                        <div className={`${managQueCss.sectionDiv}`}>
                            <label className={`${managQueCss.RowLabel}`} >
                            <input 
                                name='section' 
                                type="radio" 
                                defaultValue={`1`}
                                onChange={ quesProcessingDataHandler }
                            /> Section - A
                            </label>
                            <label className={`${managQueCss.RowLabel}`}>
                            <input 
                                name='section' 
                                type="radio" 
                                defaultValue={`2`}
                                onChange={ quesProcessingDataHandler }
                            /> Section - B
                            </label>
                        </div>
                        <div className={`${managQueCss.selectTopic}`}>
                        {quesProcessingData.section=='1'&&
                        <>
                        {/* Section A Practice Set start=> */}
                        <div className={`${GeneratePracticeSetCss.tablesBGS}`}>
                        <Row>
                            <Col className='col-md-5 col-sm-8 col-8'>
                            <label className='form-label'>Select Set</label>
                            <select className='form-control'>
                                <option style={{display: 'none'}}>Select</option>
                                <option> Set - 1 </option>
                                <option> Set - 2 </option>
                                <option> Set - 3 </option>
                                <option> Set - 4 </option>
                                <option> Set - 5 </option>
                                <option> Set - 6 </option>
                                <option> Set - 7 </option>
                                <option> Set - 8 </option>
                                <option> Set - 9 </option>
                                <option> Set - 10 </option>
                            </select>
                            </Col>
                            <Col className='col-md-7 col-sm-4 col-4'>
                                <div className={`${GeneratePracticeSetCss.PracticesSetHeading}`}>Practice Sets</div>
                            </Col>
                        </Row>
                        <Row>
                        <div className="table-responsive">
                        <div className={`${GeneratePracticeSetCss.tableQuestions}`}>
                        <Table striped bordered hover>
                            <thead className={`${GeneratePracticeSetCss.headers_thead}`}>
                                <tr>
                                    <th style={{width: '70px'}}>Sr. No.</th>
                                    <th style={{width: '126px'}}>
                                    <label className={`${GeneratePracticeSetCss.containerChect}`}>
                                        <input type="checkbox" name=''/> Select All
                                        <span className={`${GeneratePracticeSetCss.checkmark}`}></span>
                                    </label>
                                     </th>
                                    <th>Questions</th>
                                    <th style={{width: '70px'}}>Set No.</th>

                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(Array(10), (num, index)=>{

                                    return( 
                                        <tr>
                                        <td>{index+1}</td>
                                        <td>
                                        <label className={`${GeneratePracticeSetCss.containerChect}`}>
                                            <input type="checkbox" name=''/>
                                            <span className={`${GeneratePracticeSetCss.checkmark}`}></span>
                                        </label>
                                        </td>
                                        <td>
                                        If sum of two numbers is 1215 and their HCF is 81, then the possible number of pairs 
                                        of such numbers are 
                                        </td>
                                        <td>{index+1}</td>
                                        </tr>
                                    );
                                })
                                }

                                </tbody>
                        </Table>

                        <div className={`${GeneratePracticeSetCss.PaesNabbar}`}>
                            <div>
                                <button className='btn btn-success' onClick={handleShow}>Generate</button>
                            </div>
                            <div className={`${GeneratePracticeSetCss.firstColm}`}>
                            <Pagination>
                            <Pagination.First />
                           
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Item>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                            <Pagination.Item>{7}</Pagination.Item>
                            <Pagination.Item>{8}</Pagination.Item>
                            <Pagination.Item>{9}</Pagination.Item>
                            <Pagination.Item>{10}</Pagination.Item>
                            <Pagination.Last />
                            </Pagination>
                        </div>  
                        </div>
                        </div>
                        </div>
                        </Row>
                        </div>
                        {/* Section A Practice Set end/ */}
                        <form method='get' className={`${managQueCss.selectSubject} d-none` }>
                            <Row className='justify-content-start'>
                                <Col className='col-md-3 col-sm-12 col-12'>
                                    <label>Topic</label>
                                    <select 
                                        className={`${managQueCss.selectSubject}`}
                                        required
                                        name='topic'
                                        id='topic'
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                    >
                                    <option className={`d-none`}>Select Topic</option>
                                        {topicList
                                            .map( topic => (
                                                <option 
                                                    value={topic.questionCatID}
                                                    key={"topic"+topic.questionCatID}
                                                >
                                                    {topic.questionCatDesc}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                                <Col className='col-md-3 col-sm-12 col-12'>
                                    <label>Sub-Topic</label>
                                    <select 
                                        className={`${managQueCss.selectSubject}`}
                                        name='subTopic'
                                        id='subTopic'
                                        required
                                        onChange={ quesProcessingDataHandler }
                                        >
                                        <option className={`d-none`}>Select Sub-Topic</option>
                                        {
                                            subTopicsList.map( sTopic => (
                                                <option
                                                    value={sTopic.subTopicID}
                                                    key={'sTopic'+sTopic.subTopicID}
                                                >
                                                    {sTopic.subTopicDesc}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </Col>
                            </Row>
                        </form>
                        </> 
                        }
                        {quesProcessingData.section=='2'&&
                             <form method='get' className={`${managQueCss.selectSubject} `}>
                             <Row className='justify-content-start'>
                             <Col className='col-md-3 col-sm-12 col-12'>
                                 <label>Subject</label>
                                 <select 
                                    required 
                                    className={`${managQueCss.selectSubject}`}
                                    name='subject'
                                    id='subject'
                                    onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                >
                                    <option className={`d-none`}>Select Subject</option>
                                    {
                                        subjectList.map( subject => (
                                            <option
                                                value={subject.subjectID}
                                                key={subject.subjectAbbreviation}
                                            >
                                                {subject.subjectName}
                                            </option>
                                        ) )
                                    }
                                 </select>
                             </Col>
                             <Col className='col-md-3 col-sm-12 col-12'>
                                 <label>Chapter</label>
                                 <select 
                                    required 
                                    className={`${managQueCss.selectSubject}`}
                                    name='chapter'
                                    id='chapter'
                                    onChange={ quesProcessingDataHandler }
                                >
                                    <option className={`d-none`}>Select Chapter</option>
                                    {
                                        subjectChaptersList.map( chapter => (
                                            <option
                                                value={chapter.chapterID}
                                                key={'chp'+chapter.chapterID}
                                            >
                                                {chapter.chapterName}
                                            </option>
                                        ) )
                                    }
                                 </select>
                             </Col>
         
                             {/* { quesProcessingData.chapter!='' && <Col className={`col-md-3 col-sm-12 col-12, ${managQueCss.nextBtn}`} >
                                 <button className={`${managQueCss.buttonNext} btn btn-primary mt-3`} type='button' onClick={ (e)=>{ e.target.name='isFormOpened'; e.target.value=true; quesProcessingDataHandler(e) } }>Next</button>
                             </Col>} */}
         
                             </Row>
                             </form>
                        }
                        </div> 
                    </>

                
                }
                


                <div className={`${managQueCss.addQueFormContainer}`}>

                    { isLoaderActive && <div className={managQueCss.loaderClass}>
                        <div className="spinner-border text-primary" role="status"></div>
                    </div> }



                    { (quesProcessingData.subTopic!='' || quesProcessingData.chapter!='') &&
                        // <div className={`${managQueCss.passageTypeBtn}`}>
                        //     <Button 
                        //         variant={ quesProcessingData.isWithPassage=='true' ? "success addMore2" : "outline-success addMore2" } 
                        //         onClick={ 
                        //             (e) => { e.target.name='isWithPassage'; e.target.value=true; quesProcessingDataHandler(e); }
                        //         }
                        //     >
                        //         Passage
                        //     </Button>
                        //     <Button 
                        //         variant={ quesProcessingData.isWithPassage=='false' ? "success" : "outline-success" } 
                        //         onClick={ 
                        //             (e) => { e.target.name='isWithPassage'; e.target.value=false; quesProcessingDataHandler(e); }
                        //         }
                        //     > 
                        //         With Out Passage
                        //     </Button>
                        // </div>

                        <>



                        </>

                    }

                    

                </div>
            </div>


            {passagePopup &&
                <AddpassageQue  quesProcessingData={quesProcessingData} addNewQuesHandler={addNewQuesHandler} quesProcessingDataHandler={quesProcessingDataHandler} ckeditorHandler={ckeditorHandler} setPassagePopup={setPassagePopup}/>
            }
            <Modal 
                className='modelMsg'
                show={modalShow.status}
                onHide={modalCloseHandler}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className='p-0'>
                    <Alert className='mb-0' variant={modalShow.messageClass} onClick={modalCloseHandler} dismissible>
                        <p className='p-0 m-0'>{modalShow.message}</p>
                    </Alert>
                </Modal.Body>
            </Modal>


            {/* Modal Generate Practice Set start*/}
            <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                    
                    </Modal.Header>
                    <Modal.Body>
                        
                    <div className={`${GeneratePracticeSetCss.messageBox}`}> <WarningIcon/> Are you sure? </div>
                    <div className={`${GeneratePracticeSetCss.bodyPart}`}>
                    <Button variant="success">Yes !</Button>{' '}
                    <Button variant="danger" onClick={handleClose}> No !</Button>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                </Modal>
           {/* Modal Generate Practice Set end*/} 
            
        </div>
    )
}

export default GeneratePracticeSet;
