import StuDashBoardCss from './stuDashboard.module.css';
import PracticeSetIcon from '../../assets/set_sub.png';

import { Container, Row, Col } from 'react-bootstrap';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Link } from  'react-router-dom';

import {RouteURL} from './../../constant';

function PracticeSet() {
  

  return (

 
     
     
      <div className={`${StuDashBoardCss.backdiv2}`}>
       
       
        <Container fluid>
        <Row className='justify-content-around'>
          <Col>
              <div onClick={()=> window.open('/student/attempt', '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")} className={`${StuDashBoardCss.Setsback}`}>
              {/* <div onClick={()=> window.open({RouteURL.studentAttemptScreenPage}, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")} className={`${StuDashBoardCss.Setsback}`}> */}
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 1</div>
              </div>
              
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 2</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 3</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 4</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 5</div>
              </div>
          </Col>
          </Row>
          <Row className='justify-content-around'>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 6</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 7</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 8</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 9</div>
              </div>
          </Col>
          <Col>
              <div className={`${StuDashBoardCss.Setsback}`}>
                <div className={`${StuDashBoardCss.iconPrac}`}><img src={PracticeSetIcon}/></div>
                <div className={`${StuDashBoardCss.PracSetHeading}`}>Practice Set - 10</div>
              </div>
          </Col>
          
        </Row>

        </Container>
      </div>
   
  
  );
}

export default PracticeSet;
