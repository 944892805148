import StuDashBoardCss from "../stuDashboard.module.css";
import LeftMenu from "../LeftMenu";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

import Header from "../header";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useState } from "react";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PreviewIcon from "@mui/icons-material/Preview";
import selfAssessment from "../../../assets/self_asmt.png";
import FinalExam from "../../../assets/final_exam.png";
import PracticeSet from "../../../assets/practice_set.png";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
function StudentDash() {
  // Report
  const [shoSectionSelf, setSectionSelf] = useState(false);
  const [showSectionSelfA, setSectionSelfA] = useState(false);
  const [showViewDetailespA, setViewDetailespA] = useState(false);
  // Report

  // Final Report
  const [showSectionFinal, setSectionFinal] = useState(false);
  // Final Report

  // Practice Set
  const[showPracticeSetReport, setPracticeSetReport] = useState(false);
  // Practice Set


  // Review Detailes
  const [showReviewAnswer, setReviewAnswer] = useState(false);
  // Review Detailes

   return (
    <div className={`${StuDashBoardCss.Backbody}`}>
      <Header />
      <LeftMenu />

      <div className={`${StuDashBoardCss.backdiv}`}>
        <div className={`${StuDashBoardCss.RowCOlm}`}>
          <div className={`${StuDashBoardCss.headingPractice}`}>Report</div>
        </div>

        <Container fluid>
          <Row>
          <Col className="col-md-4 col-sm-12 col-12">
              <div onClick={(()=>{setPracticeSetReport(true); {setSectionSelf(false); {setSectionFinal(false); {setSectionSelfA(false)}} }})} className={`${StuDashBoardCss.learnBtn}`}>
                <div className={`${StuDashBoardCss.logoCircle}`}>
                  <img src={PracticeSet} />
                </div>
                <div className={`${StuDashBoardCss.headingDiv}`} style={{background:showPracticeSetReport&& "#a37af4"}}>
                  PRACTICE SETS
                </div>
              </div>
            </Col>

            <Col className="col-md-4 col-sm-12 col-12">
              <div
                onClick={() => {
                  setSectionSelf(true);
                  {
                    setSectionFinal(false); {setPracticeSetReport(false) }
                  }
                }}
                className={`${StuDashBoardCss.learnBtn}`}
              >
                <div className={`${StuDashBoardCss.logoCircle}`}>
                  <img src={selfAssessment} />
                </div>
                <div className={`${StuDashBoardCss.headingDiv}`} style={{background:shoSectionSelf&& "#a37af4"}}>
                  SELF GENERATED ASSESSMENT
                </div>
              </div>
            </Col>

            

            <Col className="col-md-4 col-sm-12 col-12">
              <div
                onClick={() => {
                  setSectionFinal(true);
                  {
                    setSectionSelf(false); {setSectionSelfA(false); {setPracticeSetReport(false)}}
                  }
                }}
                className={`${StuDashBoardCss.learnBtn}`}
              >
                <div className={`${StuDashBoardCss.logoCircle}`}>
                  <img src={FinalExam} />
                </div>
                <div className={`${StuDashBoardCss.headingDiv}`} style={{background:showSectionFinal&& "#a37af4"}}>
                  FINAL EXAM
                </div>
              </div>
            </Col>
          </Row>
                <hr/>
          <Row>

            {/* Practice set Report Start */}
            {showPracticeSetReport && <p>Practice sets report coming soon</p>}
            {/* Practice set report end */}

            {/* final Exam Report start */}
            {showSectionFinal && <p> Final exam report coming soon</p>}
            {/* final Exam Report end */}

            

            {shoSectionSelf && (
              <div className={`${StuDashBoardCss.SectionBk}`}>
                <div
                  onClick={() => {
                    setSectionSelfA(true);
                  }}
                  className={`${StuDashBoardCss.buttonSection}`} style={{background:showSectionSelfA&& "#bdbaba"}}
                >
                  Section - A
                </div>
                <div className={`${StuDashBoardCss.buttonSection}`}>
                  Section - B
                </div>
              </div>
            )}

            {/* Assessment List */}
            {/* Section A */}
            {showSectionSelfA && (
              <div className={`${StuDashBoardCss.AssessmentList}`}>
                <div className={`${StuDashBoardCss.mainHeading}`}>
                  Assessment List | <b>Section - A</b>
                </div>

                <Table striped bordered hover responsive>
                  <thead>
                    <tr className={`${StuDashBoardCss.headerTr}`}>
                      <th>S No.</th>
                      <th>Registration No</th>
                      <th>Assessment Name</th>
                      <th>Marks</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>01251426</td>
                      <td>Mathematics - self-1</td>
                      <td>80/100</td>
                      <td>
                        <span className={`${StuDashBoardCss.Attempted}`}>
                          <AssignmentTurnedInIcon
                            className={`${StuDashBoardCss.AttemptedIcon}`}
                          />
                          Attempted
                        </span>
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            setViewDetailespA(true);
                          }}
                          className={`${StuDashBoardCss.viewDetailesButton}`}
                        >
                          <PreviewIcon style={{ color: "#999" }} /> View
                          Detailes
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>01251426</td>
                      <td>Mathematics - self-1</td>
                      <td>80/100</td>
                      <td>
                        <span className={`${StuDashBoardCss.Attempted}`}>
                          <AssignmentTurnedInIcon
                            className={`${StuDashBoardCss.AttemptedIcon}`}
                          />
                          Attempted
                        </span>
                      </td>
                      <td>
                        <div
                          className={`${StuDashBoardCss.viewDetailesButton}`}
                        >
                          <PreviewIcon style={{ color: "#999" }} /> View
                          Detailes
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>01251426</td>
                      <td>Mathematics - self-1</td>
                      <td>80/100</td>
                      <td>
                        <span className={`${StuDashBoardCss.Attempted}`}>
                          <AssignmentTurnedInIcon
                            className={`${StuDashBoardCss.AttemptedIcon}`}
                          />
                          Attempted
                        </span>
                      </td>
                      <td>
                        <div
                          className={`${StuDashBoardCss.viewDetailesButton}`}
                        >
                          <PreviewIcon style={{ color: "#999" }} /> View
                          Detailes
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>01251426</td>
                      <td>Mathematics - self-1</td>
                      <td>80/100</td>
                      <td>
                        <span className={`${StuDashBoardCss.Attempted}`}>
                          <AssignmentTurnedInIcon
                            className={`${StuDashBoardCss.AttemptedIcon}`}
                          />
                          Attempted
                        </span>
                      </td>
                      <td>
                        <div
                          className={`${StuDashBoardCss.viewDetailesButton}`}
                        >
                          <PreviewIcon style={{ color: "#999" }} /> View
                          Detailes
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>01251426</td>
                      <td>Mathematics - self-1</td>
                      <td>80/100</td>
                      <td>
                        <span className={`${StuDashBoardCss.Attempted}`}>
                          <AssignmentTurnedInIcon
                            className={`${StuDashBoardCss.AttemptedIcon}`}
                          />
                          Attempted
                        </span>
                      </td>
                      <td>
                        <div
                          className={`${StuDashBoardCss.viewDetailesButton}`}
                        >
                          <PreviewIcon style={{ color: "#999" }} /> View
                          Detailes
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>01251426</td>
                      <td>Mathematics - self-1</td>
                      <td>80/100</td>
                      <td>
                        <span className={`${StuDashBoardCss.Attempted}`}>
                          <AssignmentTurnedInIcon
                            className={`${StuDashBoardCss.AttemptedIcon}`}
                          />
                          Attempted
                        </span>
                      </td>
                      <td>
                        <div
                          className={`${StuDashBoardCss.viewDetailesButton}`}
                        >
                          <PreviewIcon style={{ color: "#999" }} /> View
                          Detailes
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

            {/* Section A */}

            {/* Assessment List */}
          </Row>

          {/* View Detailes */}

          {/* View Detailes */}
          {showViewDetailespA && (
            <div className={`${StuDashBoardCss.viewDetailesPop}`}>
              <div className={`${StuDashBoardCss.innerdivReport}`}>
                <div className={StuDashBoardCss.headerTp}>
                  <div className={StuDashBoardCss.headingCol}>
                    View Detailes
                  </div>
                  <div className={StuDashBoardCss.crossIcon}>
                    <CancelIcon
                      className={`${StuDashBoardCss.crossiconDiv}`}
                      onClick={() => {
                        setViewDetailespA(false);
                      }}
                    />
                  </div>
                </div>

                <div className={`${StuDashBoardCss.tableBackDeta}`}>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr className={`${StuDashBoardCss.headerTr}`}>
                        <th>Q. No.</th>
                       
                        <th>Answer</th>
                        <th>Your Answer</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <CheckBoxIcon
                              className={`${StuDashBoardCss.checkMark}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <DisabledByDefaultIcon
                              className={`${StuDashBoardCss.wrongSign}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <CheckBoxIcon
                              className={`${StuDashBoardCss.checkMark}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <DisabledByDefaultIcon
                              className={`${StuDashBoardCss.wrongSign}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <CheckBoxIcon
                              className={`${StuDashBoardCss.checkMark}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <DisabledByDefaultIcon
                              className={`${StuDashBoardCss.wrongSign}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <CheckBoxIcon
                              className={`${StuDashBoardCss.checkMark}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>A</td>
                        <td>A</td>
                        <td>
                          <span>
                            <DisabledByDefaultIcon
                              className={`${StuDashBoardCss.wrongSign}`}
                            />
                          </span>
                        </td>
                        <td>
                          <div
                            onClick={() => {
                              setReviewAnswer(true);
                            }}
                            className={`${StuDashBoardCss.viewDetailesButton}`}
                          >
                            <PreviewIcon style={{ color: "#999" }} />
                            Review Answer
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          )}

          {showReviewAnswer && (
            <div className={`${StuDashBoardCss.reviewAnswerBk}`}>
              <div  className={`${StuDashBoardCss.innerdivReport}`}>
                <div className={StuDashBoardCss.headerTp}>
                  <div className={StuDashBoardCss.headingCol}>
                    Review Answer/Explanation
                  </div>
                  <div className={StuDashBoardCss.crossIcon}>
                    <CancelIcon
                      className={`${StuDashBoardCss.crossiconDiv}`}
                      onClick={() => {
                        setReviewAnswer(false);
                      }}
                    />
                  </div>
                </div>

                {/* Display Answer and expl */}
                <div style={{minHeight: 'auto'}} className={`${StuDashBoardCss.rowGrid}`}>
              <div className={`${StuDashBoardCss.colFixed}`}>
                <div className={`${StuDashBoardCss.QuestionNo}`}>Question - 1 </div>
              </div>
               <div className={`${StuDashBoardCss.questionDiv}`}>
                <b>
                In the following question, the Assertion and Reason have put forward. Read the statement carefully and choose the correct alternative from the following:
                  </b>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                <div>Assertion: Self-pollen cannot effect fertilisation in self-incompatible pistil.</div>
                <div>Reason : Self-incompatibility prevent the germination of plllen grains of retard growth of pollen tube.</div>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                <div className={`${StuDashBoardCss.SpaceVar}`}></div>
                <Row>
                  <Col className='col-md-6 col-sm-12 col-12'>
                      
                        <label>
                          <div className={`${StuDashBoardCss.optionsRow}`}>
                            <div className={`${StuDashBoardCss.divOne}`}>
                              <input type="radio" name="options" /> A.
                            </div>
                            <div>
                            Both the Asseration and the Reason are correct and the Reason is the correct explanation of the Asseration
                            </div>

                           </div> 
                        </label>
                                    
                  </Col>
                  <Col className='col-md-6 col-sm-12 col-12'>
                      
                      <label>
                        <div className={`${StuDashBoardCss.optionsRow}`}>
                          <div className={`${StuDashBoardCss.divOne}`}>
                            <input type="radio" name="options" /> B.
                          </div>
                          <div>
                          The Assertion and the Reason are correct but the Reason is not the correct explanation the Assertion
                          </div>

                         </div> 
                      </label>
                                  
                </Col>
                <Col className='col-md-6 col-sm-12 col-12'>
                      
                      <label>
                        <div className={`${StuDashBoardCss.optionsRow}`}>
                          <div className={`${StuDashBoardCss.divOne}`}>
                            <input type="radio" name="options" /> C.
                          </div>
                          <div>
                          Assertion is true but the Reason is false
                          </div>

                         </div> 
                      </label>
                                  
                </Col> 
                <Col className='col-md-6 col-sm-12 col-12'>
                      
                      <label>
                        <div className={`${StuDashBoardCss.optionsRow}`}>
                          <div className={`${StuDashBoardCss.divOne}`}>
                            <input type="radio" name="options" /> D.
                          </div>
                          <div>
                          The statement of the Assertion is false but the Reason is true.
                          </div>

                         </div> 
                      </label>
                                  
                </Col>
                </Row>
                </div>
        </div>
        <hr/>
        <div className={`${StuDashBoardCss.explanationBox}`}>
                      <div className={`${StuDashBoardCss.headingExpl}`}>Explanation</div>
                      <p className={`${StuDashBoardCss.expContent}`}>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

        </div>
            
                {/* Display Answer and expl */}

                
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
}

export default StudentDash;
