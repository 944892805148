import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React,{useContext} from 'react';
import Urlnotfound from './assets/urlNotfound.png';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Loader from './Loader';
import userContext from './Store';

function App() {

    const {login, error, loader} = useContext(userContext);

    return (
        <>
            {loader!='true' && 
                <div className="pagenotFound">
                    <div>
                        <img src={Urlnotfound}/>
                    </div>
                    <br/>
                    <Link to="/"> <button className='btn btn-primary btn-lg'><HomeIcon/> Go Back To Home</button> </Link>
                </div>
            }

            {loader && <Loader/>}
        </>
    );
}

export default App;
