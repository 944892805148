import React from 'react'
import AdminDashBoardCss from './adminDashboard.module.css';
import Header from '../adminDashboard/Header';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AlternateEmailRounded } from '@mui/icons-material';






function ManageStu(){

    const[ addStuds, setAddStuds]= useState(false);
    const[editStudents, setEditStudents]=useState({status:false, index:null, data:null} )
    
    const[currentVal, setCurrentVal]=useState({
        studName:'',
        guarName:'',
        contNo:'',
        eMail:'',
    })

    function studentDetail(name, value){

        if(name=='studentName'){
            setCurrentVal((prev)=>{
                return{...prev, studName:value}
            })
        } else if(name=='guarName'){
            setCurrentVal((prev)=>{
                return{...prev, guarName:value}
            })
        } else if(name=='contNo'){

            if(isNaN(value)){
                console.log("sdfdsf");
                return;
            }
            if(value.length>=11){
                return;
            }
            setCurrentVal((prev)=>{
                return{...prev, contNo:value}
            })
 
        } else if(name=='eMail'){
            setCurrentVal((prev)=>{
            return{...prev, eMail:value}
        })
    }
    }

    const[showStuData, setShowStuData]=useState([])
    function showStuDetail(){
        
        if(currentVal.studName==''){
            alert('please fill the student name')
            return;
        } else if(currentVal.guarName==''){
            alert('please fill the gaurdion name')
            return;
        } else if(currentVal.eMail==''){
            alert('please fill e-mail address')
            return;
        }

        setShowStuData((prev)=>{
            let data = [...prev];
            data.push(currentVal)
            console.log(data)
            return data
        });
        
        setCurrentVal({
            studName:'',
            guarName:'',
            contNo:'',
            eMail:'',
        });
    }
    function editStud(index, item){
        
        setEditStudents({status:true, index:index, data:item.data})
    }

    function removeStud(index){
        setShowStuData((prev)=>{
            
            let studList = [...prev];
            studList.splice(index, 1);
            return studList
        });
    }

    return(
        <>
            <Header/>
        <div className={`${AdminDashBoardCss.bg}`}>

            <Row className={`${AdminDashBoardCss.btnNavBar}`}>
                <Col className={`col-md-6 col-sm-12 col-12 ${AdminDashBoardCss.addStudent}`}>
                    <button className={` ${AdminDashBoardCss.addbtn} btn btn-primary addMore`} onClick={()=>{setAddStuds(true)}}>Add Student</button>
                    <button className={` ${AdminDashBoardCss.exportBtn} btn btn-primary addMore`}>Export</button>
                    <input type="text" className={`${AdminDashBoardCss.searchBar}`}  placeholder='search'/> <SearchIcon className={`${AdminDashBoardCss.searchIcon}`}/>
                </Col>
                <Col className={`col-md-6 col-sm-12 col-12 ${AdminDashBoardCss.backbutton}`}>
                    <Link to="/admin"><div className={`${AdminDashBoardCss.backLinkDash}`}> <HomeIcon/> Back </div></Link>
                </Col>
            </Row>
            {addStuds &&
            <div className = {`${AdminDashBoardCss.addStudentSec}`}>
                <Container >
                    <div className={`${AdminDashBoardCss.addStudForm}`}>
                            <div className={`${AdminDashBoardCss.inputRow}`}>
                                <label htmlFor="">Student Name:</label>
                            </div>
                            <div className={`${AdminDashBoardCss.inputBox}`}>
                                <input type="text" placeholder='Enter your full Name' value={currentVal.studName} onChange={(event)=>{studentDetail('studentName', event.target.value)}}/>
                            </div>

                            <div className={`${AdminDashBoardCss.inputRow}`}>
                                <label htmlFor="">Guardian Name:</label>
                            </div>
                            <div className={`${AdminDashBoardCss.inputBox}`}>
                                <input type="text" placeholder='Enter your guardian Name' value={currentVal.guarName} onChange={(event)=>{studentDetail('guarName', event.target.value)}}/>
                            </div>

                            <div className={`${AdminDashBoardCss.inputRow}`}>
                                <label htmlFor="">Contect No.:</label>
                            </div>
                            <div className={`${AdminDashBoardCss.inputBox}`}>
                                <input type="text" placeholder='Enter your contect no' value={currentVal.contNo} onChange={(event)=>{studentDetail('contNo', event.target.value)}}/>
                            </div>

                            <div className={`${AdminDashBoardCss.inputRow}`}>
                                <label htmlFor="">E-mail</label>
                            </div>
                            <div className={`${AdminDashBoardCss.inputBox}`}>
                                <input type="text" placeholder='Enter your e-mail address' value={currentVal.eMail} onChange={(event)=>{studentDetail('eMail', event.target.value)}} />
                            </div>

                            <div className={`${AdminDashBoardCss.inputRow}`}>
                                <label htmlFor="">Class:</label>
                            </div>

                            <div className={`${AdminDashBoardCss.selectTypeIns}`}>
                                    <form method='get' className={`${AdminDashBoardCss.selectSubject}`}>
                                            <select required className={`${AdminDashBoardCss.selectSubject}`}>
                                                <option value="0" >Select Class</option>
                                                <option value="1" >1 </option>
                                                <option value="2" >2 </option>
                                                <option value="3" >3 </option>
                                                <option value="4" >4 </option>
                                                <option value="5" >5 </option>
                                                <option value="6" >6 </option>
                                            </select>
                                    </form>
                            </div>
                            
                    </div>
                            <div className={`${AdminDashBoardCss.AcademicYear}`}>
                                <div className={`${AdminDashBoardCss.inputRow}`}>
                                    <label htmlFor="">Academic Year: </label>
                                </div>
                                <div className={`${AdminDashBoardCss.academicYear}`}> 2022-2023</div>
                            </div>
                    <div className={`${AdminDashBoardCss.saveBtn}`}>
                        <button className='btn btn-primary' desable={true} onClick={showStuDetail}><AddCircleOutlineIcon/></button>
                    </div>
                </Container>
                {showStuData.length>0 &&        
                <Container>
                    
                    <div className={`${AdminDashBoardCss.studentList}`}>
                        <table className={`${AdminDashBoardCss.addStuList}`}>
                            <tr>
                                <th className={`${AdminDashBoardCss.setWidth}`}>Sr. No.</th>
                                <th>Student Name</th>
                                <th>Guardian Name</th>
                                <th>Contect No.</th>
                                <th>E-mail</th>
                                <th>Academic Year</th>
                                <th className={`${AdminDashBoardCss.setWidth}`}></th>
                                <th className={`${AdminDashBoardCss.setWidth}`}></th>
                            </tr>
                            {showStuData.map((item, index)=>{
                                return(
                                    <tr key={item.id}>
                                    <td>{index+1}</td>
                                    <td>{item.studName}</td>
                                    <td>{item.guarName}</td>
                                    <td>{item.contNo}</td>
                                    <td>{item.eMail}</td>
                                    <td style={{width:"5"}}>2022-2023</td>
                                    <td style={{width:"4"}}>
                                        <button className='btn btn-outline-primary' value={item.data} onClick={()=>{editStud(index, item)}}><EditIcon className={`${AdminDashBoardCss.stuListBtn}`}/></button>
                                    </td>
                                    <td style={{width:"5"}}>
                                        <button className='btn btn-outline-primary' onClick={()=>{removeStud(index)}}><DeleteIcon className={`${AdminDashBoardCss.stuListBtn}`}/></button>
                                    </td>
                                </tr>
                                )
                            })
                            }
                        


                        </table>
                    </div>
                </Container>
                }
                </div>
            }
            {editStudents.status &&
                    <div>
                    <div className={`${AdminDashBoardCss.outGray}`} onClick={()=>{setEditStudents(false)}}>
                        <div className={`${AdminDashBoardCss.mainContainer}`}  onClick={(event)=>{event.stopPropagation();}}>
                            <div className={`${AdminDashBoardCss.mainHead}`}>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                                <textarea className={`${AdminDashBoardCss.editInstText}`} value={editStudents.data} 
                                onChange={(event)=>{setEditStudents((prev)=>{
                                    return {...prev, data:event.target.value}
                                })}}
                                />
                            </div>
                            
                            <div className={`${AdminDashBoardCss.popupSavBtn}`}>
                                <button className='btn btn-success'>Save Change</button>
                            </div>
                        </div>
        
                    </div>
                </div>
                }
        </div>
        </>
    )
} 
export default ManageStu;

