import React,{ useEffect,useState,useContext } from 'react';
import CountUp from 'react-countup';
import { Col, Container, Row } from 'react-bootstrap';
import AdminDashBoardCss from './adminDashboard.module.css';
import ManageStudent from '../../assets/managestu.png';
import Generate from '../../assets/genera.png';
import totalStu from '../../assets/total_student.png';
import total_asmt from '../../assets/total_asmt.png';
import report from '../../assets/report.png';
import currentUser from '../../assets/cuttent_user.png';
import mnageQuestion from '../../assets/manage_question.png';
import {Link} from 'react-router-dom'
import {RouteURL,apiBaseURL,apiKey,apiURL} from './../../constant';
import userContext from './../../Store';



function StudentDash() {

    const {userData} = useContext(userContext);

    const [dashboardStats, setDashboardStats ] = useState({});
    const [isLoaderActive,setIsLoaderActive] = useState(true);
    const [pageLoadSatus,setPageLoadSatus] = useState(false);

    useEffect( ()=>{

        fetch( apiBaseURL+apiURL.schoolAdminDashboardStats,{
            method:'POST',
            headers: { 'key':apiKey },
            body:JSON.stringify({
                'schoolCode':userData.schoolCode
            })
        } )
            .then( response => response.json() )
            .then( (result)=>{
                setIsLoaderActive(false);
                if(result.status=='success' ){
                    setDashboardStats(result.data);
                }
                setPageLoadSatus(true);
            } ).catch( (err) => {
                setIsLoaderActive(false);
            } );
        

    },[] );
    
    return(
        <div>
            { pageLoadSatus=='false' && isLoaderActive && 
                <div className={`text-center`}>
                    <div className="spinner-border text-primary" role="status"></div>
                </div>
            }

            {pageLoadSatus &&
                <>
                    <div className={`${AdminDashBoardCss.backDivButton}`}>
                        <Container fluid>
                            <div className={`${AdminDashBoardCss.schoolName}`}>
                                <span className={`${AdminDashBoardCss.schoolNameBox}`}>
                                    {dashboardStats.schoolName}
                                </span>
                            </div>
                            <Row className='justify-content-around'>
                                <Col className='col-md-3 col-sm-4 col-12'>
                                    <Link to={RouteURL.manageStu}>
                                        <div className={`${AdminDashBoardCss.cricleBg}`}>
                                            <div className={`${AdminDashBoardCss.boxWithIcon}`}>
                                                <img src={ManageStudent}/>
                                            </div>
                                            <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Manage Student</p>
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-md-3 col-sm-4 col-12'>
                                    <div className={`${AdminDashBoardCss.cricleBg}`}>
                                        <Link to={RouteURL.adminGenerateExam}>
                                            <div className={`${AdminDashBoardCss.boxWithIcon}`}>
                                                <img src={Generate}/>
                                            </div>
                                            <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Generate Exam</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col className='col-md-3 col-sm-4 col-12'>
                                    <div className={`${AdminDashBoardCss.cricleBg}`}>
                                        <div className={`${AdminDashBoardCss.boxWithIcon}`}>
                                            <img src={report}/>
                                        </div>
                                        <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Report</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className={`${AdminDashBoardCss.secondSection}`}>
                        <Container fluid>
                            <Row className='justify-content-around'>
                                <Col className='col-md-4 col-sm-6 col-12'>
                                    <div className={`${AdminDashBoardCss.accountBg}`}>
                                        <div><img className={`${AdminDashBoardCss.imgIcon}`} src={totalStu}/></div>
                                        <div className={`${AdminDashBoardCss.TextAreaRight}`}>
                                            <div className={`${AdminDashBoardCss.textHeading}`}>Total Registered Student</div>
                                            <div className={`${AdminDashBoardCss.button1Account}`}>
                                                <CountUp end={dashboardStats.registeredStudents} duration={1} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                                    
                                <Col className='col-md-4 col-sm-6 col-12'>
                                    <div className={`${AdminDashBoardCss.accountBg}`}>
                                        <div><img className={`${AdminDashBoardCss.imgIcon}`} src={total_asmt}/></div>
                                        <div className={`${AdminDashBoardCss.TextAreaRight}`}>
                                            <div className={`${AdminDashBoardCss.textHeading}`}>Total Assessment</div>
                                            <div className={`${AdminDashBoardCss.button2Account}`}>
                                                <CountUp end={dashboardStats.totalAssessment} duration={1} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                                        
                                <Col className='col-md-4 col-sm-6 col-12'>
                                    <div className={`${AdminDashBoardCss.accountBg}`}>
                                        <div><img className={`${AdminDashBoardCss.imgIcon}`} src={currentUser}/></div>
                                        <div className={`${AdminDashBoardCss.TextAreaRight}`}>
                                            <div className={`${AdminDashBoardCss.textHeading}`}>Total Con Current User</div>
                                            <div className={`${AdminDashBoardCss.button3Account}`}>
                                                <CountUp end={dashboardStats.totalAdminAccounts} duration={1} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            }
        </div>
    );
    

    // return (
    //     <div>
            

    //         {pageLoadSatus &&
    //             <>
    //                 <div className={`${AdminDashBoardCss.backDivButton}`}>
    //                     <Container fluid>
    //                         <Row className='justify-content-around'>
    //                             <Col className='col-md-3 col-sm-4 col-12'>
    //                                 <Link to={RouteURL.addEditInst}>
    //                                     <div className={`${AdminDashBoardCss.cricleBg}`}>
    //                                         <div className={`${AdminDashBoardCss.boxWithIcon}`}>
    //                                             <img src={mnageQuestion}/>
    //                                         </div>
    //                                         <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Manage Questions</p>
    //                                     </div>
    //                                 </Link>
    //                             </Col>
    //                             <Col className='col-md-3 col-sm-4 col-12'>
    //                                 <Link to={RouteURL.manageStu}>
    //                                     <div className={`${AdminDashBoardCss.cricleBg}`}>
    //                                         <div className={`${AdminDashBoardCss.boxWithIcon}`}>
    //                                             <img src={ManageStudent}/>
    //                                         </div>
    //                                         <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Manage Student</p>
    //                                     </div>
    //                                 </Link>
    //                             </Col>
    //                             <Col className='col-md-3 col-sm-4 col-12'>
    //                                 <div className={`${AdminDashBoardCss.cricleBg}`}>
    //                                     <Link to={RouteURL.adminGenerateExam}>
    //                                         <div className={`${AdminDashBoardCss.boxWithIcon}`}>
    //                                             <img src={Generate}/>
    //                                         </div>
    //                                         <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Generate Exam</p>
    //                                     </Link>
    //                                 </div>
    //                             </Col>
    //                             <Col className='col-md-3 col-sm-4 col-12'>
    //                                 <div className={`${AdminDashBoardCss.cricleBg}`}>
    //                                     <div className={`${AdminDashBoardCss.boxWithIcon}`}>
    //                                         <img src={report}/>
    //                                     </div>
    //                                     <p className={`${AdminDashBoardCss.buttonBottomTxt}`}>Report</p>
    //                                 </div>
    //                             </Col>
    //                         </Row>
    //                     </Container>
    //                 </div>
                    
    //                 <div className={`${AdminDashBoardCss.secondSection}`}>
    //                     <Container fluid>
    //                         <Row className='justify-content-around'>
    //                             <Col className='col-md-4 col-sm-6 col-12'>
    //                                 <div className={`${AdminDashBoardCss.accountBg}`}>
    //                                     <div><img className={`${AdminDashBoardCss.imgIcon}`} src={totalStu}/></div>
    //                                     <div className={`${AdminDashBoardCss.TextAreaRight}`}>
    //                                         <div className={`${AdminDashBoardCss.textHeading}`}>Total Registered Student</div>
    //                                         <div className={`${AdminDashBoardCss.button1Account}`}>
    //                                             <CountUp end={dashboardStats.registeredStudents} duration={1} />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </Col>
                            
    //                             <Col className='col-md-4 col-sm-6 col-12'>
    //                                 <div className={`${AdminDashBoardCss.accountBg}`}>
    //                                     <div><img className={`${AdminDashBoardCss.imgIcon}`} src={total_asmt}/></div>
    //                                     <div className={`${AdminDashBoardCss.TextAreaRight}`}>
    //                                         <div className={`${AdminDashBoardCss.textHeading}`}>Total Assessment</div>
    //                                         <div className={`${AdminDashBoardCss.button2Account}`}>
    //                                             <CountUp end={dashboardStats.totalAssessment} duration={1} />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </Col>
                                
    //                             <Col className='col-md-4 col-sm-6 col-12'>
    //                                 <div className={`${AdminDashBoardCss.accountBg}`}>
    //                                     <div><img className={`${AdminDashBoardCss.imgIcon}`} src={currentUser}/></div>
    //                                     <div className={`${AdminDashBoardCss.TextAreaRight}`}>
    //                                         <div className={`${AdminDashBoardCss.textHeading}`}>Total Con Current User</div>
    //                                         <div className={`${AdminDashBoardCss.button3Account}`}>
    //                                             <CountUp end={dashboardStats.totalAdminAccounts} duration={1} />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </Col>
    //                         </Row>
    //                     </Container>
    //                 </div> 
    //             </>
    //         }
             
        
    //     </div> 
    // );
}

export default StudentDash;
