import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import PracticeSetBtn from './PracticeSetsButton';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Row, Col } from 'react-bootstrap';
import {apiBaseURL,apiURL,apiKey} from './../../constant';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
function PracticeSet() {
const[showSection, setSection]=useState([]);
const[showSubjectList, setSubjectList]=useState([]);
const[showChapter, setChapterList]=useState([]);
const[showErrPopup, setErrPopup] =useState({status:false, msg:null});

function checkBtnData(id){
  setCheckedBtn(id)
  if(id==1){
    setSectionA(true); 
    setSectionB(false)
    setPSetB(false)
    
  } else {
    setSectionB(true); 
    setSectionA(false)

  }
}
  useEffect(()=>{
    getSection();
    getSubjectList();
  },[])

  // radio button
  const[sltSectionA, setSlSectionA]=useState(true);
  const[sltSectionB, setSlSectionB]=useState(true);

  const [checkBtn, setCheckedBtn] = useState("1")
  // radio button

  // Section state
  const[showSectionA, setSectionA] =useState(true);
  const[showSectionB, setSectionB] =useState(false);

  // show set
  const[showPSetB, setPSetB]=useState(false);
  // show set

  // Section state

   // get subject list function
   function getSubjectList(){
    fetch(apiBaseURL+apiURL.getSubject,{
      method: 'GET',
      headers:{'Key' : apiKey}
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status=="success"){
          setSubjectList(data.data.subjects)
        }else{setErrPopup({status:true, msg:data.message})}
      })
    })
    .catch((err)=>{
      console.log(err)
    })
  }
// get subject list function

// get chapters list  function
  function getChapters(subjectID){
    fetch(apiBaseURL+apiURL.getChaptersList,{
      method: 'POST',
      headers: {'Key' : apiKey},
      body: JSON.stringify({"classID":12, "subjectID": subjectID})
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status=="success"){
          setChapterList(data.data.chapters)
        }else{setErrPopup({status:true, msg:data.message})}
      })
      
    })
    .catch((err)=>{
      console.log(err)
    })
  }
// get chapters list  function

  // get section function
  function getSection(){
    fetch(apiBaseURL+apiURL.getSectionList,{
      method: 'GET',
      headers: {'Key' : apiKey}
    })
    .then((res)=>{
      res.json()
      .then((data)=>{
        if(data.status=="success")
        setSection(data.data.sections)
      })
    })
    .catch((err)=>{
      alert(err);
    })
}
// get section function




  return (

   <div className={`${StuDashBoardCss.Backbody}`}>
      <Header/>
      <LeftMenu/>
     
      <div className={`${StuDashBoardCss.backdiv}`}>
        <div className={`${StuDashBoardCss.RowCOlm}`}>
             <div className={`${StuDashBoardCss.headingPractice}`}>Practice Sets</div>
        </div>
        {showSection.map((item)=>{
          return(
            <FormControlLabel checked={checkBtn==item.sectionID} name='section' value="sction" control={<Radio />} label={item.sectionName} onChange={()=>{checkBtnData(item.sectionID)}}/>
          )
        })}
    
        
        <hr/>
        <Container fluid>

       {/* section A start*/}
        {showSectionA &&
         <>
          <PracticeSetBtn/>
         </>
        } 
        
           {/* section A end*/}

        {/* section B start*/}
        {showSectionB &&
           <>
          <form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
          <Row className='justify-content-start'>
           <Col className='col-md-5 col-sm-12 col-12'>
             <label>Subject</label>
              <select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>getChapters(event.target.value)}>
               <option className={`d-none`}>Select Subject</option>
                {showSubjectList.map((item)=>{
                  return(
                    <option value={item.subjectID}>{item.subjectName}</option>
                  )
                })}
              </select>
           </Col>
           <Col className='col-md-5 col-sm-12 col-12'>
             <label>Chapter</label>
              <select required className={`${StuDashBoardCss.selectSubject}`}>
                <option className={`d-none`}>Select Chapter</option>
                {showChapter.map((item)=>{
                  return(
                    <option>{item.chapterName}</option>
                  )
                })}
              </select>
           </Col>
         
           <Col className='col-md-2 col-sm-12 col-12'>
             <button onClick={()=>{setPSetB(true)}}  className={`${StuDashBoardCss.buttonNext}`} type='button'>Next</button>
           </Col>
           
          </Row>
          </form>
          </>
           }
           {/* section B end*/}

           {/* section B set */}
           {showPSetB &&
             <PracticeSetBtn/>
           }
           {/* section B set */}

          

        </Container>
      </div>
       {/* err popup */}
    {showErrPopup.status && 
    <div className={`${StuDashBoardCss.popupBack}`}>
    <div className={`${StuDashBoardCss.errBox}`}>
         <CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
         <Alert severity="error">
         {showErrPopup.msg}
         </Alert>
          
     </div>       
    </div>
    }
    {/* err popup */}
    </div>
  
  );
}

export default PracticeSet;
