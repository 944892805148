import React, {useState,useEffect} from "react";
import EditQueCss from "./EditQue.module.css";
import { Container, Row, Col } from "react-bootstrap";
import PaginationBar from './Pagination';

import {wirisMathML} from '../../constant';
import ScreenMCQ from "../questionScreens/mcq";


function EditQue({userData,quesProcessingData,questionsList,editQuesSegregationButtonHandler,editQuesButtonHandler,deleteQuesButtonHandler,quesProcessingDataHandler,getSubjectChapters,getSubTopicList,searchQuestions,setCurrentPage,currentPage,isLoaderActive,topicList,subTopicsList,subjectList,subjectChaptersList}) {
    const [editData, setEditData]=useState({
        viewSelect:true,
        topicList:'',
        chapterList:'',
        queSection:'',
    })
    function editQueData(name, val=' '){
        if(name=='search'){
            setEditData((prev)=>{
                return {...prev, viewSelect:true}
            })
        } else if(name=='topicList'){
            setEditData((prev)=>{
                return {...prev, topicList:val}
            })

        }
    }

    useEffect(() => {
        const LoadExternalScript = () => {
          const externalScript = document.createElement("script");
          externalScript.onerror = loadError;
          externalScript.id = "external";
          externalScript.async = true;
          externalScript.type = "text/javascript";
          externalScript.setAttribute("crossorigin", "anonymous");
          document.body.appendChild(externalScript);
          externalScript.src = wirisMathML;
        };
        var elm = document.getElementById('external');
        if(elm!=null){
            elm.remove();
        }
        LoadExternalScript();
    }, [currentPage]);

    function loadError(onError) {
        console.error(`Failed ${onError.target.src} didn't load correctly`);
    }


    const currentQuestionData = questionsList.length >=1 ? questionsList[currentPage-1] : false;

    
    return (
        <div>
            <div className={`${EditQueCss.mainDiv}`}>
                <Row className={`${EditQueCss.selectType}`}>
                    <Col className="col-md-2 col-sm-12 col-12">
                        <div className={`${EditQueCss.slcDiv}`}>
                            <select 
                                required 
                                name='section' 
                                className={`${EditQueCss.slcSection}`} 
                                // onChange={(event)=>{editQueData('topicList', event.target.value)}}
                                onChange={ quesProcessingDataHandler }
                            >
                                <option className={`d-none`}>Select Section</option>
                                <option value="1">Section A </option>
                                <option value="2">Section B </option>
                            </select>
                        </div>
                    </Col>
                    
                    {quesProcessingData.section=='1' && 
                        <>
                            <Col className="col-md-2 col-sm-12 col-12">
                                <div className={`${EditQueCss.slcDiv}`}>
                                    {/* <select required className={`${EditQueCss.slcTopic}`}> */}
                                    <select 
                                        className={`${EditQueCss.slcDiv}`}
                                        required
                                        name='topic'
                                        id='topic'
                                        onChange={ (e)=>{ quesProcessingDataHandler(e);getSubTopicList(e.target.value) } }
                                    >
                                        <option className={`d-none`}>Select Topic</option>
                                        {
                                            topicList.map( topic => (
                                                <option 
                                                    value={topic.questionCatID}
                                                    key={"topic"+topic.questionCatID}
                                                >
                                                    {topic.questionCatDesc}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </div>
                            </Col>

                            <Col className="col-md-2 col-sm-12 col-12">
                                <div className={`${EditQueCss.slcDiv}`}>
                                    {/* <select required className={`${EditQueCss.slcTopic}`}> */}
                                    <select 
                                        className={`${EditQueCss.slcTopic}`}
                                        name='subTopic'
                                        id='subTopic'
                                        required
                                        onChange={ quesProcessingDataHandler }
                                    >
                                        <option className={`d-none`}>Select Sub-Topic</option>
                                        {
                                            subTopicsList.map( sTopic => (
                                                <option
                                                    value={sTopic.subTopicID}
                                                    key={'sTopic'+sTopic.subTopicID}
                                                >
                                                    {sTopic.subTopicDesc}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </div>
                            </Col>
                        </>
                    }
                    
                    {quesProcessingData.section=='2' &&
                        <>
                            <Col className="col-md-2 col-sm-12 col-12">
                                <div className={`${EditQueCss.slcDiv}`}>
                                    {/* <select required className={`${EditQueCss.slcSubject}`}> */}
                                    <select 
                                        required 
                                        className={`${EditQueCss.slcSubject}`}
                                        name='subject'
                                        id='subject'
                                        onChange={ (e)=>{quesProcessingDataHandler(e);getSubjectChapters(e.target.value)} }
                                    >
                                        <option className={`d-none`}>Select Subject</option>
                                        {
                                            subjectList.map( subject => (
                                                <option
                                                    value={subject.subjectID}
                                                    key={subject.subjectAbbreviation}
                                                >
                                                    {subject.subjectName}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </div>
                            </Col>
                            
                            <Col className="col-md-2 col-sm-12 col-12">
                                <div className={`${EditQueCss.slcDiv}`}>
                                    {/* <select required className={`${EditQueCss.slcTopic}`}> */}
                                    <select 
                                        required 
                                        className={`${EditQueCss.slcTopic}`}
                                        name='chapter'
                                        id='chapter'
                                        onChange={ quesProcessingDataHandler }
                                    >
                                        <option className={`d-none`}>Select Chapter</option>
                                        {
                                            subjectChaptersList.map( chapter => (
                                                <option
                                                    value={chapter.chapterID}
                                                    key={'chp'+chapter.chapterID}
                                                >
                                                    {chapter.chapterName}
                                                </option>
                                            ) )
                                        }
                                    </select>
                                </div>
                            </Col>
                        </>
                    }
                    
                    <Col className="col-md-2 col-sm-12 col-12">
                        <div className={`${EditQueCss.slcDiv}`}>
                            <select 
                                required 
                                name='isWithPassage'
                                className={`${EditQueCss.slcQueType}`}
                                onChange={quesProcessingDataHandler}
                            >
                                <option className={`d-none`}>Select Question Type</option>
                                <option value={true}>Passage </option>
                                <option value={false}>With Out Passage </option>
                            </select>
                        </div>
                    </Col>
                    <Col className="col-md-2 col-sm-12 col-12">
                        <button className="btn btn-success my-2" onClick={searchQuestions}>Search</button>
                    </Col>
                </Row>

                { isLoaderActive && <div className={EditQueCss.loaderClass}>
                    <div className="spinner-border text-primary" role="status"></div>
                </div> }
                
                {quesProcessingData.isFormOpened && currentQuestionData!=false &&
                    <>
                        <ScreenMCQ userData={userData} editQuesSegregationButtonHandler={editQuesSegregationButtonHandler} editQuesButtonHandler={editQuesButtonHandler} deleteQuesButtonHandler={deleteQuesButtonHandler} questionData={currentQuestionData} totalQuestions={questionsList.length} currentPage={currentPage} EditQueCss={EditQueCss}/>

                        <div className={`${EditQueCss.fotter}`}>
                            <PaginationBar questionsList={questionsList} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                        </div>
                    </>
                }

                {/* {quesProcessingData.isFormOpened && currentQuestionData===false &&
                    <div>
                        <h1>No Question Found</h1>
                    </div>
                } */}
            </div>
        </div>
    );
}

export default EditQue;
