import React from "react";
import WelcomeCss from "./Welcomeuser.module.css";
import Step from "../../assets/step.png";
import Header from "../studentDashboard/header";
import { Link } from "react-router-dom";
import { useContext } from "react";
import userContext from "../../Store";

export default function Welcomeuser() {
  const { userData, logout } = useContext(userContext);
  return (
    <div>
      <Header />
      <div className={`${WelcomeCss.container}`}>
        <div className={`${WelcomeCss.mainDiv}`}>
          <div className={`${WelcomeCss.followStep}`}>
            <img src={Step} />
          </div>
          <div className={`${WelcomeCss.TextBox}`}>
            <h1>Welcome {userData.firstName}.</h1>
            <p>
              We are happy you've decided to entrust us with the privilege of
              impacting you with the necessary knowledge for a new curriculum
              and a new beginning. We extend the best wishes for the studies and
              expect best of results from them.”
            </p>
            <h4>Here are the instructions as How to Use this Portal?</h4>
            <ol>
              <li>
                Brush up the concepts firstly by going through Quick Notes as a
                warm up.
              </li>
              <li>
                Now Topic-wise Practice will help you to learn by attempting the
                questions and getting the report of it.
              </li>
              <li>
                After learning you are ready for practicing questions which
                would be an integrated set comprising all the topics which you
                have practiced in the above step.
              </li>
              <li>
                Time to check your Practice!. Select the questions from the pool
                of questions to see what all you have learnt so far along with
                fixing a time limit for yourself so as to prepare yourself for a
                3 hour paper.
              </li>
              <li>
                Ready for the final go. We believe once you have gone through
                all the above steps sequentially, you can take the final exam
                with full confidence. These papers are exactly according to the
                guidelines as prescribed.
              </li>
              <li>
                Check the report timely to know your progress, weakness and
                improvement.
              </li>
            </ol>
            <h5 className={`${WelcomeCss.happyLearning}`}> BEST OF LUCK!!</h5>
          </div>

          <div className={`${WelcomeCss.btnDiv}`}>
            <Link to="/student">
              <button type="submit" className={`${WelcomeCss.logButton}`}>
                Let's Start
              </button>
            </Link>
          </div>
        </div>
        <div className={`${WelcomeCss.area}`}>
          <ul className={`${WelcomeCss.circles}`}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
}
